import React, { useCallback } from "react";
import { Save as SaveIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

import { getFilter, getLoading } from "../../store/asset-usage-records.selector";
import { usePaginationContext } from "../../../../modules/filter/shared/utils/use-pagination";
import { useAppDispatch } from "../../../../store";
import {
  prepareAssetsToSendAction,
  setFilterAction,
} from "../../store/asset-usage-records.slice";

export const PrepareAssetsToSendButton = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useSelector(getFilter);
  const isLoading = useSelector(getLoading);
  const { handleChangePage } = usePaginationContext();

  const onPrepareButtonClick = useCallback(() => {
    dispatch(prepareAssetsToSendAction())
      .unwrap()
      .then((hasUpdate) => {
        if (!hasUpdate) {
          return;
        }
        handleChangePage(null, 0);

        // hack to reset filter (use the same assetIds but as different object)
        dispatch(
          setFilterAction({
            ...filter,
            assetCategories: filter.assetCategories ? [...filter.assetCategories] : [],
          })
        );
      });
  }, [dispatch, filter, handleChangePage]);

  return (
    <LoadingButton
      size="small"
      color="primary"
      onClick={onPrepareButtonClick}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="contained"
    >
      <span>Списать</span>
    </LoadingButton>
  );
};
