import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../store";
import { fetchAssetUsageRecordsAction } from "../../store/asset-usage-records.slice";
import { getFilter, getIsFilterOpen } from "../../store/asset-usage-records.selector";
import { usePagination } from "../../../../modules/filter/shared/utils/use-pagination";
import { AssetUsageRecordList } from "../asset-usage-record-list/asset-usage-record-list";
import { AssetUsageCompositions } from "../asset-usage-compositions/asset-usage-compositions";
import { AssetUsageRecordsFilter } from "../asset-usage-records-filter/asset-usage-records-filter";
import { PrepareAssetsToSendButton } from "../prepare-assets-to-send-button/prepare-assets-to-send-button";
import { ekoCropFilterAdapter } from "../../../../modules/filter/shared/utils/eko-crop-filter-adapter";
import { AssetUsageRecordsLayout } from "../../components/asset-usage-records-layout/asset-usage-records-layout";
import { AssetUsageRecordListControls } from "../asset-usage-record-list-controls/asset-usage-record-list-controls";
import { PaginationProvider } from "../../../../modules/filter/shared/containers/pagination-provider/pagination-provider";
import { AssetUsageRecordsPopup } from "../asset-usage-records-popup/asset-usage-records-popup";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter as getGlobalFilter } from "../../../../modules/filter/index";

export const AssetUsageRecordsMaster = (): React.JSX.Element => {
  const isFilterOpen = useSelector(getIsFilterOpen);
  const dispatch = useAppDispatch();
  const pagination = usePagination();
  const { limit, offset, order } = pagination;
  const currentFarmId = useSelector(getGlobalFilter(FilterName.FarmId));
  const { fromDate, toDate, statusIds, assetCategories, materiallyAccountableEmployees } =
    useSelector(getFilter);

  // TODO: need to move all filter(+pagination) logic into store and use only actions
  useEffect(() => {
    const filter = ekoCropFilterAdapter(
      {
        limit,
        offset,
        order,
        fromDate,
        toDate,
        farmIds: currentFarmId ? [currentFarmId] : undefined,
        statusIds,
        techOperationGroups: undefined,
        techOperationSubGroups: undefined,
        cropTypeIds: undefined,
        assetCategories,
        farmlandIds: undefined,
        materiallyAccountableEmployees,
      },
      {
        where: {
          and: [
            { fromDate: "gte" },
            { toDate: "lte" },
            { farmIds: "inq" },
            { statusIds: "inq" },
            { techOperationGroups: "inq" },
            { techOperationSubGroups: "inq" },
            { cropTypeIds: "inq" },
            { assetCategories: "inq" },
            { farmlandIds: "inq" },
            { materiallyAccountableEmployees: "inq" },
          ],
        },
        optional: [
          "statusIds",
          "techOperationGroups",
          "techOperationSubGroups",
          "cropTypeIds",
          "assetCategories",
          "farmlandIds",
          "materiallyAccountableEmployees",
        ],
      }
    );

    dispatch(fetchAssetUsageRecordsAction(filter));
  }, [
    dispatch,
    limit,
    offset,
    order,
    fromDate,
    toDate,
    statusIds,
    assetCategories,
    materiallyAccountableEmployees,
    currentFarmId,
  ]);

  return (
    <AssetUsageRecordsLayout
      compositionContent={<AssetUsageCompositions />}
      compositionActions={
        <PaginationProvider {...pagination}>
          <PrepareAssetsToSendButton />
        </PaginationProvider>
      }
      filterContent={isFilterOpen ? <AssetUsageRecordsFilter /> : void 0}
    >
      <PaginationProvider {...pagination}>
        <AssetUsageRecordListControls />
        <AssetUsageRecordList />
      </PaginationProvider>

      <AssetUsageRecordsPopup />
    </AssetUsageRecordsLayout>
  );
};
