import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import Grid2 from "@mui/material/Unstable_Grid2";

import { Fertiliser } from "../../../../modules/fertilisers/shared/models/fertiliser";
import { getList } from "../../../../modules/fertilisers/store";
import { RHFAutocompleteSimple } from "../../../../shared/components/react-hook-form-mui/autocomplete-simple";
import { RHFSelectSimple } from "../../../../shared/components/react-hook-form-mui/select-simple";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { devwarn } from "../../../../shared/utils/log";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOpEditAssetTotal } from "./techop-edit-asset-total";
import { useHideAssetsWriteOff } from "src/hooks/use-hide-asserts-writeoff";
import { IAssetStocks } from "src/modules/asset-stocks/types";
import { TechOperationAssetEntityType } from "../../shared/types/tech-operation-asset-entity";
import { getAssetStocks } from "src/modules/asset-stocks/store/asset-stocks.selector";

export const TechOpEditFertiliser = memo(
  ({
    globalIndex,
    initialFormAsset,
    optionsLabel,
    noOptionsText,
    disabled,
    allStocks,
  }: {
    globalIndex: number;
    initialFormAsset: ITechOperationAssetFormData;
    optionsLabel: string;
    noOptionsText: string;
    disabled?: boolean;
    allStocks?: IAssetStocks[];
  }): React.JSX.Element => {
    const fertilisers: Fertiliser[] = useSelector(getList);
    const { setValue, watch, register } = useFormContext<ITechOperationFormData>();
    const assets = watch("assets");

    const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();
    const [allowAllAssets, setAllowAllAssets] = useState(false);

    const stocksFertilisers = useSelector(
      getAssetStocks(allStocks, TechOperationAssetEntityType.FERTILISER)
    );

    const currentFertilisers = Array.from(stocksFertilisers as Fertiliser[]);
    const currentOptions =
      allowAllAssets || !currentFertilisers.length ? fertilisers : currentFertilisers;

    const fertiliserFound = useMemo(() => {
      const fertiliser = fertilisers.find((x) => x.id === initialFormAsset.entityId);
      return fertiliser;
    }, [fertilisers, initialFormAsset.entityId]);

    const [lostFertiliserName, setLostFertiliserName] = useState<string>();
    useEffect(() => {
      if (initialFormAsset.entityId === undefined) {
        return;
      }
      if (fertiliserFound) {
        return;
      }

      devwarn(
        `BACKEND_SHOULD_SEND_TECHOP_FERTILISER__PRESENT_IN_fertilisers_DICTIONARY
      initialFormAsset.entityId[ ${initialFormAsset.entityId} ]
       was not found among seedsAll[${fertilisers.length}]; initialFormAsset=`,
        initialFormAsset
      );
      setLostFertiliserName(initialFormAsset.name);
      //eslint-disable-next-line
    }, [fertiliserFound, fertilisers, initialFormAsset]);

    const initialAssetUnitsFromDictionary: string[] = useMemo(() => {
      const ret = [initialFormAsset?.unit || ""]; // "" when no Chemical selected
      if (!initialFormAsset.entityId) {
        return ret;
      }
      if (fertiliserFound?.units) {
        return fertiliserFound.units;
      }
      return ret;
    }, [fertiliserFound, initialFormAsset]);

    const [unitsAfterAssetChanged, setUnitsAfterAssetChanged] = useState<string[]>();

    const currentUnit = useMemo(
      () =>
        unitsAfterAssetChanged?.[0] ||
        initialFormAsset?.unit ||
        initialAssetUnitsFromDictionary[0] ||
        "",
      [unitsAfterAssetChanged, initialFormAsset?.unit, initialAssetUnitsFromDictionary]
    );

    // disabled=true after 1) just added (empty ASSET dropdown), or 2) cleared non-empty by X
    const currentFormAsset: ITechOperationAssetFormData | undefined = useMemo(
      () => assets?.[globalIndex],
      [assets, globalIndex]
    );

    const disabled1: boolean = useMemo(() => {
      const ret = currentFormAsset?.entityId;
      return ret === undefined;
      // sometimes currentFormAsset?.entityId = undefined when
      // currentFormAsset didn't change
    }, [currentFormAsset?.entityId]);

    if (!assets?.[globalIndex]) {
      return <></>;
    }

    // static fields
    register(`assets.${globalIndex}.entityType`);
    register(`assets.${globalIndex}.id`);
    register(`assets.${globalIndex}.createdAt`);
    register(`assets.${globalIndex}.name`);

    const applicationRateValue =
      assets?.[globalIndex]?.applicationRate || assets?.[globalIndex]?.quantity || "";

    return (
      <Grid2 container={true} xs={12} py={2} spacing={2}>
        <Grid2 xs={12}>
          <RHFAutocompleteSimple<Fertiliser>
            name={`assets.${globalIndex}.entityId`}
            defaultValue={initialFormAsset.entityId}
            rules={{ required: true }}
            optionGuidToObject={(optionGuid: string) => {
              const ret = fertilisers.find((f) => f.id === optionGuid);
              return ret;
            }}
            onOptionSelected={(newOption: Fertiliser | null) => {
              setValue(`assets.${globalIndex}.name`, newOption?.name || "");
              setValue(`assets.${globalIndex}.entityId`, newOption?.id || "");
              setValue(`assets.${globalIndex}.unit`, newOption?.unit || "");
              // FOR_TESTING delete(newOption?.units);
              if (newOption?.units) {
                setUnitsAfterAssetChanged(newOption.units);
              } else if (newOption?.unit) {
                const backendRemovedUnits = [newOption?.unit];
                setUnitsAfterAssetChanged(backendRemovedUnits);
              } else {
                setUnitsAfterAssetChanged([]);
              }
            }}
            disabled={!fertilisers.length}
            options={currentOptions}
            noOptionsText={noOptionsText}
            textFieldProps={{
              required: true,
              label: `${optionsLabel} (${currentOptions.length})`,
            }}
          />
          {lostFertiliserName && (
            <Box style={{ color: "red" }}>&quot;{lostFertiliserName}&quot; не найден</Box>
          )}
        </Grid2>

        {isAssetUsageRecordsEnabled ? (
          <Grid2 sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => {
                    setAllowAllAssets(evt.target.checked);
                  }}
                  checked={allowAllAssets}
                  color={"primary"}
                />
              }
              label={`Из всех ПХ`}
            />
          </Grid2>
        ) : null}

        <Grid2 xs={4}>
          <TechOpEditAssetTotal idx={globalIndex} />
        </Grid2>

        <Grid2 xs={4}>
          <RHFSelectSimple<string>
            name={`assets.${globalIndex}.unit`}
            defaultValue={currentUnit}
            options={unitsAfterAssetChanged || [assets?.[globalIndex]?.unit || ""]}
            label="Ед. измерения"
            disabled={disabled1 || disabled}
          />
        </Grid2>

        <Grid2 xs={4}>
          <RHFTextField
            name={`assets.${globalIndex}.applicationRate`}
            rules={{
              min: {
                value: 0.001, // TODO: need to get value from props
                message: "Поле не может быть отрицательным или равным нулю.",
              },
            }}
            TextFieldProps={{
              disabled: true, // динамически вычисленное значение, пользователь его больше не вводит
              label: "Факт. расход на 1 га",
              type: "number",
              value: applicationRateValue,
            }}
          />
        </Grid2>
      </Grid2>
    );
  }
);

TechOpEditFertiliser.displayName = "TechOpEditFertiliser";
