import { createContext, PropsWithChildren } from "react";
import { Pagination } from "../../utils/use-pagination";

export const PaginationContext = createContext<Pagination>({
  limit: 0,
  order: [],
  page: 0,
  offset: 0,
  handleChangePage: () => void 0,
  handleChangeOrder: () => void 0,
  handleChangeLimit: () => void 0,
});
export const PaginationProvider = (
  props: PropsWithChildren<Pagination>
): React.JSX.Element => {
  const { children, ...rest } = props;
  return <PaginationContext.Provider value={rest}>{children}</PaginationContext.Provider>;
};
