import { useFieldArray, useFormContext } from "react-hook-form";

import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOperationAssetEntityType } from "../../shared/types/tech-operation-asset-entity";
import { UseControlledFieldsType } from "../../shared/types";
import { TechOperationAsset } from "../../shared/models/tech-operation-asset";
import { useHideAssetsWriteOff } from "src/hooks/use-hide-asserts-writeoff";

export const UseControlledFields = (): [
  UseControlledFieldsType["controlledFields"],
  UseControlledFieldsType["destructAndSetFields"],
] => {
  const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();

  const { control, watch } = useFormContext<ITechOperationFormData>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "assets",
    keyName: "key",
  });

  // TODO: hack below and it's merge with fields doesn't work, remove later
  const watchFieldArray = watch("assets")!; // eslint-disable-line

  const controlledFields = fields
    .map((field, index) => ({
      ...field,
      ...watchFieldArray[index],
    }))
    .map((field, index) => ({ index, field }));

  const destructAndSetFields: UseControlledFieldsType["destructAndSetFields"] = (
    entityType,
    actionType,
    index
  ) => {
    if (actionType === "add") {
      const newEntity = new TechOperationAsset().setEntityType(
        entityType as TechOperationAssetEntityType
      ).asFormData;

      append(newEntity);
    }
    if (actionType === "remove") {
      const filteredFields = fields.filter((field) => field.entityType === entityType);
      if (!isAssetUsageRecordsEnabled || filteredFields.length <= 1) {
        return;
      }
      remove(index);
    }
  };
  return [controlledFields, destructAndSetFields];
};

// TODO: Let this comments be. In future we should avoid arrays in such cases, better use mapped object and rewrite on it
// TODO: And before Submit transfer to array if needed

// Какие вообще способы есть? Есть поля, юзФилд дает полям ключ,
// Берется массив полей с ключами -> индексы просто по очереди даются
// удаление елемента вызывается переписку всех индексов массива
// тут можно пойти двумя путями
// 1) Каждый раз пайпить по пути сопоставления и сортировки по категориям
// 2) делать дырки в массиве и потом профильтровывать их
// Второй вариант крутой, но все типы просто разлетаются
