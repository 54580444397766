import { AnyObject, ILoopbackFilterSchema, WhereSchema } from "../interfaces/loopback";

function parseWhere<MT extends AnyObject>(
  src: MT,
  whereSchema: WhereSchema<MT>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Record<string, any> {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  let where: Record<string, any> = {};

  Object.entries(whereSchema).forEach(([key, value]) => {
    if (["or", "and"].includes(key)) {
      if (!Array.isArray(value)) {
        throw new Error("or/and values must be an array");
      }

      where = {
        ...where,
        ...value.reduce((acc, item) => ({ ...acc, ...parseWhere<MT>(src, item) }), {}),
      };
      return;
    }

    value = Array.isArray(value) ? value[1] : src[key];
    if ([null, undefined, ""].includes(value)) {
      return;
    }

    where[key] = value;
  });

  return where;
}

export type TEkoCropApiFilter<T extends object> = Partial<T> & {
  limit?: number;
  skip?: number;
  order?: string[];
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function ekoCropFilterAdapter<TSrc extends Record<string, any>>(
  src: TSrc,
  schema: ILoopbackFilterSchema<TSrc>
): TEkoCropApiFilter<TSrc> {
  const { where, optional = [] } = schema;

  for (const key in src) {
    const value = src[key];
    const isInvalid = [null, undefined, ""].includes(value);
    const isRequired = !optional.includes(key);
    if (isInvalid && isRequired) {
      return {}; // failed to convert (fields + schema + optional) => filter
    }
  }

  let filter: TEkoCropApiFilter<TSrc> = {};
  if (where) {
    const parsedWhere = parseWhere<TSrc>(src, where);
    filter = {
      ...filter,
      ...parsedWhere,
    };
  }
  if (src["limit"]) filter.limit = src["limit"];
  if (src["offset"]) filter.skip = src["offset"];

  return filter;
}
