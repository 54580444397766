import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React from "react";

import { Order } from "../../shared/enums/order";

type OrderGroup = Array<[string | number | symbol, Order]>;

export interface HeadCell<T = string> {
  id: T;
  label: string;
  numeric?: boolean;
  disableSort?: boolean;
}

interface EnhancedTableProps<T> {
  columns: HeadCell<keyof T>[];
  onSelectAllClick?: (flag: boolean) => void;
  rowCount: number;
  numSelected: number;
  order?: OrderGroup;
  onOrderChange: (orderGroup: OrderGroup) => void;
  additionalCols?: number;
  additionalContent?: React.JSX.Element;
}

// eslint-disable-next-line
export const EnhancedTableHead = <T extends Record<string, any> = Record<string, any>>(
  props: EnhancedTableProps<T>
): React.JSX.Element => {
  const {
    columns,
    onSelectAllClick,
    order,
    numSelected,
    rowCount,
    onOrderChange,
    additionalCols = 0,
    additionalContent,
  } = props;

  const orderMap = new Map<string | number | symbol, Order>(order);

  const createSortHandler = (property: string | number | symbol) => (_) => {
    const immutable = new Map<string | number | symbol, Order>(orderMap);
    const oldOrderValue = immutable.get(property) || "";
    const newOrderValue = {
      "": Order.ASC,
      [Order.ASC]: Order.DESC,
      [Order.DESC]: null,
    }[oldOrderValue];

    if (newOrderValue) {
      immutable.set(property, newOrderValue);
    } else {
      immutable.delete(property);
    }

    onOrderChange(Array.from(immutable.entries()));
  };

  const thBorder = ({ palette }) => ({
    borderRight: `1px solid ${palette.text.disabled}`,
    paddingLeft: "4px",
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={thBorder}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(_, checked) => onSelectAllClick?.(checked)}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.numeric ? "right" : "left"}
            style={{ fontWeight: "bold" }}
            sortDirection={
              !headCell.disableSort && orderMap.has(headCell.id)
                ? orderMap.get(headCell.id)
                : false
            }
            sx={thBorder}
          >
            {headCell.disableSort ? (
              <>{headCell.label}</>
            ) : (
              <TableSortLabel
                active={orderMap.has(headCell.id)}
                direction={orderMap.get(headCell.id)}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {additionalCols !== 0 && (
          <TableCell colSpan={additionalCols || 1} padding={"none"} sx={thBorder}>
            {additionalContent}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
