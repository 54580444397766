import {
  Box, // Link
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentFarmLands_includeDeleted } from "../../../../modules/farm-lands/current-farm-lands.selector";
// import { Link as RouterLink } from "react-router-dom";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import {
  ITechOperationDto,
  isPredecessorDto,
} from "../../shared/dtos/tech-operation.dto";
import { farmLandFormatCombined } from "../../shared/utils/farm-land-format";
import { BoxTooltipMouseover } from "./box-tooltip-mouseover";

export function FieldFormatted({
  techOpDto,
  skipResolution,
}: {
  techOpDto: ITechOperationDto;
  skipResolution?: boolean;
}): JSX.Element {
  const farmLands: IFarmLandDto[] | undefined = useSelector(
    getCurrentFarmLands_includeDeleted
  );

  const isPredecessor = isPredecessorDto(techOpDto);

  const Real_farmLandName_formatted = farmLandFormatCombined(
    techOpDto.Real_farmLandName,
    skipResolution
      ? undefined
      : farmLands?.find((x) => x.id === techOpDto.Real_farmLandId)
  );
  const farmLandName_formatted = farmLandFormatCombined(
    techOpDto.farmLandName,
    skipResolution ? undefined : farmLands?.find((x) => x.id === techOpDto.farmLandId)
  );

  return (
    <>
      {isPredecessor ? (
        <BoxTooltipMouseover
          title={
            <pre>
              {Real_farmLandName_formatted} - поле-предшественник (на нём была произведена
              техоперация)
              <br />
              {farmLandName_formatted} - поле-наследник (для сведения)
              <br />
              <br />
              {techOpDto.Real_farmLandId} предшественник
              <br />
              {techOpDto.farmLandId} наследник
            </pre>
          }
        >
          <Box>
            {/* <Link
              component={RouterLink}
              to={`/fields/${techOpDto.farmLandId}/tech`}
              target="_blank"
              style={{ color: "red" }}
            > */}
            {farmLandName_formatted}
            {/* </Link> */}
          </Box>
          <Box style={{ color: "darkGrey" }}>
            {/* <Link
              component={RouterLink}
              to={`/fields/${techOpDto.Real_farmLandId}/tech`}
              target="_blank"
              style={{ color: "red" }}
            > */}
            ({Real_farmLandName_formatted}){/* </Link> */}
          </Box>
        </BoxTooltipMouseover>
      ) : (
        // <Link component={RouterLink} to={`/fields/${techOpDto.farmLandId}/tech`} target="_blank">
        <>{farmLandName_formatted || techOpDto.farmLandName}</>
        // </Link>
      )}
    </>
  );
}
