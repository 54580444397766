import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import { DetailsLayout } from "../../components/details-layout/details-layout";
import { getDetails } from "../../store/farm-lands.selector";
import { fetchFarmLandByIdAction, setDetailsAction } from "../../store/farm-lands.slice";
import { FarmLandBrief } from "../farm-land-brief/farm-land-brief";
import { PATHS } from "../../../../constant";

const FarmLandInfo = lazy(() => import("../farm-land-info/farm-land-info"));
const FarmLandTech = lazy(() => import("../farm-land-tech/farm-land-tech"));
const FarmLandTechEdit = lazy(() => import("../farm-land-tech/farm-land-tech-edit"));
const FarmLandReports = lazy(
  () =>
    import(
      "../../../observations/containers/farm-land-observations/farm-land-observations"
    )
);

export const FarmLandDetails = (): JSX.Element => {
  const { id: farmLandId } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const details = useSelector(getDetails);

  // update base info
  useEffect(() => {
    if (!farmLandId) {
      return;
    }

    dispatch(fetchFarmLandByIdAction(farmLandId));

    return () => {
      dispatch(setDetailsAction(null));
    };
  }, [farmLandId, dispatch]);

  const navigate = useNavigate();
  if (!farmLandId) {
    navigate(PATHS.FIELDS_PAGE);
    return <>Redirecting to /map...</>;
  }

  // TODO: add replace to all navigate to index rows
  return (
    <DetailsLayout submenuChildren={<FarmLandBrief model={details} />}>
      <Routes>
        <Route index={true} element={<FarmLandInfo />} />
        <Route path={PATHS.FIELD_TECH} element={<FarmLandTech />} />
        <Route
          path={`${PATHS.FIELD_TECH}/:techOpId`}
          element={<FarmLandTechEdit farmLandId={farmLandId} />}
        />
        <Route path={PATHS.FIELD_REPORTS} element={<FarmLandReports />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </DetailsLayout>
  );
};
