import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Checkbox, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Crop, getList as getCropList } from "../../../../modules/crop";
import { useAppDispatch } from "../../../../store";
import {
  getFilteredList as getMapAggregationList,
  getOpened,
  getSelected,
  getSelectedFarmLands,
} from "../../store/map-controls.selector";
import {
  setOpenedAction,
  toggleOpenedAction,
  toggleSelectedAction,
  toggleSelectedFarmLandsAction,
} from "../../store/map-controls.slice";
import {
  setHighlightedFarmLandId,
  setSelectedCropNamesAction,
} from "../../store/map-page.slice";

enum ItemType {
  CropTypeId = "CropTypeId",
  FarmLandId = "FarmLandId",
}

export const CropTreeView = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const aggregations = useSelector(getMapAggregationList);
  const crops = useSelector(getCropList);
  const selectedTypeIds = useSelector(getSelected);
  const selectedFarmLandIds = useSelector(getSelectedFarmLands);
  const openedFarmLandIds = useSelector(getOpened);

  // join both, types and farmLands into one list (to use it in tree view component)
  const selected = useMemo(
    () => selectedTypeIds.concat(selectedFarmLandIds.map((id) => "@" + id)),
    [selectedTypeIds, selectedFarmLandIds]
  );

  const handleSelect = useCallback(
    (event /*, nodeIds: string[]*/) => {
      const dataset = event.currentTarget.parentNode.dataset;
      const isInput = event.target.nodeName === "INPUT";
      const {
        id,
        type,
        //  arcgisId
      } = dataset;

      switch (type) {
        case ItemType.CropTypeId:
          if (isInput) {
            dispatch(toggleSelectedAction(id));
          } else {
            dispatch(toggleOpenedAction(id));
          }
          break;

        case ItemType.FarmLandId:
        default:
          if (isInput) {
            dispatch(toggleSelectedFarmLandsAction(id));
          } else {
            // dispatch(setHighlightedFarmLandId(arcgisId));
            dispatch(setHighlightedFarmLandId(id));
          }
          break;
      }
    },
    // eslint-disable-next-line
    [dispatch, toggleSelectedAction, toggleSelectedFarmLandsAction, setOpenedAction]
  );

  const roundPipe = useCallback((value) => Math.round(value), []);

  // convert selected cropTypeIds -> cropIds -> cropNames -> request map filter
  const cropsMatchIdToName = useMemo(
    () =>
      crops.reduce((acc, item: Crop) => {
        acc.set(item.id, item.name);
        return acc;
      }, new Map<string, string>()),
    [crops]
  );
  useEffect(() => {
    const includedCropIds = aggregations.reduce((acc, item) => {
      // skip if no one type open and no one farm land selected
      if (openedFarmLandIds.length === 0 && !selectedTypeIds.includes(item.cropTypeId)) {
        return acc;
      }

      // skip if open different item
      if (openedFarmLandIds.length && item.cropTypeId !== openedFarmLandIds[0]) {
        return acc;
      }

      return acc.concat(item.farmLands.map((farmLand) => farmLand.cropId));
    }, [] as string[]);

    const cropNames = includedCropIds.map((cropId) => cropsMatchIdToName.get(cropId));
    dispatch(setSelectedCropNamesAction(cropNames));
    // eslint-disable-next-line
  }, [
    selectedTypeIds,
    cropsMatchIdToName,
    dispatch,
    setSelectedCropNamesAction,
    openedFarmLandIds,
    aggregations,
  ]);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={openedFarmLandIds}
      selected={selected}
      onNodeSelect={handleSelect}
    >
      {aggregations.map((crop) => (
        <TreeItem
          data-id={crop.cropTypeId}
          data-type={ItemType.CropTypeId}
          key={crop.cropTypeId}
          nodeId={crop.cropTypeId}
          label={
            <Grid container={true} alignItems={"center"} wrap={"nowrap"}>
              <Grid item={true}>
                <Checkbox
                  checked={selected.includes(crop.cropTypeId)}
                  style={{ color: crop.cropTypeColor }}
                />
              </Grid>
              <Grid
                item={true}
                sx={(theme) => ({
                  flexGrow: 1,
                  maxWidth: 350,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  paddingRight: theme.spacing(2),
                })}
              >
                <strong>{crop.cropTypeName}</strong>
              </Grid>
              <Grid item={true}>
                <strong>{roundPipe(crop.aggregatedArea)} га</strong>
              </Grid>
            </Grid>
          }
        >
          {crop.farmLands.map((field) => {
            const id = `@${field.farmLandId}`;
            return (
              <TreeItem
                data-id={field.farmLandId}
                data-type={ItemType.FarmLandId}
                data-arcgis-id={field.arcgisObjectId}
                key={id}
                nodeId={id}
                label={
                  <Grid container={true} alignItems={"center"} wrap={"nowrap"}>
                    <Grid
                      item={true}
                      sx={(theme) => ({
                        flexGrow: 1,
                        paddingRight: theme.spacing(2),
                      })}
                    >
                      {field.farmLandName}
                    </Grid>
                    <Grid item={true}>{roundPipe(field.farmLandArea)} га</Grid>
                  </Grid>
                }
              />
            );
          })}
        </TreeItem>
      ))}
    </TreeView>
  );
};
