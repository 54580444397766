import React, { memo, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { Box, FormControlLabel, Switch } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { Seed } from "../../../../modules/seeds/shared/models/seed";
import { getList } from "../../../../modules/seeds/store";
import { formatFarmLand } from "../../../fields/shared/dtos/farm-land.dto";
import { RHFAutocompleteSimple } from "../../../../shared/components/react-hook-form-mui/autocomplete-simple";
import { RHFSelectSimple } from "../../../../shared/components/react-hook-form-mui/select-simple";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { deverror, devlog, devwarn } from "../../../../shared/utils/log";
import { useFarmLands } from "../../containers/tech-operations-editing/use-farm-lands";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOpEditAssetTotal } from "./techop-edit-asset-total";
import { useSeedsForCrop } from "./use-seeds-for-crop";
import { useHideAssetsWriteOff } from "src/hooks/use-hide-asserts-writeoff";
import { IAssetStocks } from "src/modules/asset-stocks/types";
import { getAssetStocks } from "src/modules/asset-stocks/store/asset-stocks.selector";
import { TechOperationAssetEntityType } from "../../shared/types/tech-operation-asset-entity";

export const TechOpEditSeed = memo(
  ({
    globalIndex,
    initialFormAsset,
    optionsLabel,
    noOptionsText,
    cropId,
    isExistingTechOperation,
    disabled,
    allStocks,
  }: {
    globalIndex: number;
    initialFormAsset: ITechOperationAssetFormData;
    optionsLabel: string;
    noOptionsText: string;
    isExistingTechOperation: boolean;
    allStocks?: IAssetStocks[];
    cropId?: string;
    disabled?: boolean;
  }): React.JSX.Element => {
    const seedsAll: Seed[] = useSelector(getList);
    const rhfMethods = useFormContext<ITechOperationFormData>();
    const { getValues, setValue, trigger, register, watch } = rhfMethods;
    const watchFields = watch();

    const stocksSeeds = useSelector(
      getAssetStocks(allStocks, TechOperationAssetEntityType.SEED)
    );

    const [allowAllAssets, setAllowAllAssets] = useState(false);
    const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();

    const {
      allowAllSeeds,
      setAllowAllSeedsAndFetch,
      seedsForCrop_noDeleted,
      seedsAll_includeDeleted,
    } = useSeedsForCrop(cropId);

    const currentCropIdByTypeSeeds = useMemo(
      () =>
        seedsForCrop_noDeleted
          ?.reduce((acc, next) => {
            const value = stocksSeeds.filter((val) => val.name === next.name);
            acc.push(value);
            return acc;
          }, [] as any) // eslint-disable-line
          .filter((arr) => arr?.length) // TODO: when refactor start add intersecting type
          .flat(),
      [seedsForCrop_noDeleted?.length, stocksSeeds?.length] // eslint-disable-line
    );

    const currentSeedsLabel = useMemo(() => {
      // TODO: unite logic with currentSeed
      const seedsLabelFirstPart = optionsLabel + " ";
      if (isAssetUsageRecordsEnabled) {
        if (allowAllSeeds && allowAllAssets) {
          return seedsLabelFirstPart + seedsAll?.length;
        }
        if (allowAllSeeds && !allowAllAssets) {
          return seedsLabelFirstPart + (stocksSeeds as Seed[])?.length;
        }
        if (allowAllAssets || !stocksSeeds.length) {
          return seedsLabelFirstPart + (seedsForCrop_noDeleted || stocksSeeds)?.length;
        }
        if (!allowAllSeeds && !allowAllAssets) {
          return seedsLabelFirstPart + currentCropIdByTypeSeeds?.length;
        } else {
          return seedsLabelFirstPart + stocksSeeds?.length;
        }
      } else {
        if (allowAllSeeds) {
          return seedsLabelFirstPart + seedsAll?.length;
        } else {
          return seedsLabelFirstPart + (seedsForCrop_noDeleted || []).length;
        }
      }
    }, [
      allowAllAssets,
      allowAllSeeds,
      optionsLabel,
      seedsAll?.length,
      seedsForCrop_noDeleted,
      stocksSeeds,
      currentCropIdByTypeSeeds,
      isAssetUsageRecordsEnabled,
    ]);

    const currentSeeds: Seed[] | [] = useMemo(() => {
      if (isAssetUsageRecordsEnabled) {
        if (allowAllSeeds && allowAllAssets) {
          return seedsAll;
        }
        if (allowAllSeeds && !allowAllAssets) {
          return (stocksSeeds as Seed[]) || [];
        }
        if (allowAllAssets || !stocksSeeds?.length) {
          return seedsForCrop_noDeleted || [];
        }
        if (!allowAllSeeds && !allowAllAssets) {
          return currentCropIdByTypeSeeds || [];
        } else {
          return stocksSeeds as Seed[];
        }
      } else {
        if (allowAllSeeds) {
          return seedsAll;
        } else {
          return seedsForCrop_noDeleted || [];
        }
      }
    }, [
      allowAllAssets,
      allowAllSeeds,
      seedsAll,
      seedsForCrop_noDeleted,
      stocksSeeds,
      currentCropIdByTypeSeeds,
      isAssetUsageRecordsEnabled,
    ]);

    const applicationRateValue = useMemo(
      () =>
        watchFields?.assets?.[globalIndex]?.applicationRate ||
        watchFields?.assets?.[globalIndex]?.quantity ||
        "",
      [globalIndex, watchFields?.assets?.[globalIndex]?.applicationRate] // eslint-disable-line
    );

    const [deletedSeed_inTechop, setDeletedSeed_inTechop] = useState<Seed | undefined>(
      undefined
    );
    const seeds: Seed[] = useMemo(() => {
      if (!deletedSeed_inTechop) {
        return currentSeeds;
      }

      const expanded = [deletedSeed_inTechop, ...currentSeeds];
      return expanded;
    }, [deletedSeed_inTechop, currentSeeds]);

    const seedFound_amongSeedsForCrop = useMemo(() => {
      if (!seedsForCrop_noDeleted) {
        return undefined;
      }
      const seed = seedsForCrop_noDeleted.find((x) => x.id === initialFormAsset.entityId);
      return seed;
    }, [seedsForCrop_noDeleted, initialFormAsset.entityId]);

    const seedFound_amongAll = useMemo(() => {
      const seed = seedsAll.find((x) => x.id === initialFormAsset.entityId);
      return seed;
    }, [seedsAll, initialFormAsset.entityId]);

    const [lostSeedName, setLostSeedName] = useState<string>();
    // effect for "lost seed" case only
    useEffect(() => {
      if (
        initialFormAsset.entityId === undefined ||
        seedFound_amongSeedsForCrop ||
        seedFound_amongAll
      ) {
        return;
      }

      if (seedsForCrop_noDeleted === undefined && allowAllSeeds === false) {
        setAllowAllSeedsAndFetch(true);
        return; // will fetch and come here again
      }

      devwarn(
        `techop.seedId[ ${initialFormAsset.entityId} ]
         was not found among seedsAll[${seedsAll.length}]; initialFormAsset=`,
        initialFormAsset
      );

      setLostSeedName(initialFormAsset.name);

      if (!seedsAll_includeDeleted) {
        deverror(
          `MUST_BE_LOADED_ASYNCHRONOUSLY_GLOBALLY seedsAll_includeDeleted`,
          seedsAll_includeDeleted
        );
        return;
      }

      // filter: '{"where":{"isDeleted":{"neq":null}}}',
      // WHY: when a techop.asset[x].seedId is not found among seedsForCrop_noDeleted & seedsAll,
      // 1. find this seedId among seedsForCrop_withDeleted
      // 2. and append it to the dropdown with УДАЛЁННЫЕ prefix
      const foundAmongDeleted = seedsAll_includeDeleted.find(
        (x) => x.id === initialFormAsset.entityId
      );
      if (!foundAmongDeleted) {
        deverror(
          `TECHOP.SEED_FOUND_AMONG_DELETED techop.seedId[ ${initialFormAsset.entityId} ]`
        );
        return;
      }

      devlog(`TECHOP.SEED_FOUND_AMONG_DELETED foundAmongDeleted`, foundAmongDeleted);
      const clone = foundAmongDeleted.clone();
      clone.name = `УДАЛЁННЫЕ ${clone.name}`;
      setDeletedSeed_inTechop(clone);
      //eslint-disable-next-line
    }, [
      seedFound_amongSeedsForCrop,
      seedFound_amongAll,
      seedsAll_includeDeleted,
      // seedsForCrop,
      allowAllSeeds,
      // EFFECT_MUST_NOT_DEPEND_ON_CALLBACK_WITH_PARAMETERS setAllowAllSeedsAndFetch,
      seedsAll,
      initialFormAsset,
    ]);

    const { farmLands } = useFarmLands({ requestOnMount: false });

    // must be invoked AFTER useForm<ITechOperationFormData>()
    const watchFieldFarmLand = useMemo(() => {
      if (!farmLands || farmLands.length === 0 || watchFields.farmLandId === "") {
        return;
      }

      const farmLand = farmLands.find((x) => x.id === watchFields.farmLandId);
      return farmLand;
    }, [
      farmLands,
      // watchFields.farmLandId changes when:
      // 1) FARM selector gets loaded
      // 2) "Добавить в №3" => F5
      // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
      watchFields.farmLandId,
    ]);

    // effect for "when added from allSeeds, slide toggle to YES" case only
    // auto-allow for existing techop, when not found among seedsForCrop
    useEffect(() => {
      if (!seedsForCrop_noDeleted) {
        return undefined;
      }

      if (!seedFound_amongSeedsForCrop && seedFound_amongAll && isExistingTechOperation) {
        setAllowAllSeedsAndFetch(true);
      }
      //eslint-disable-next-line
    }, [
      seedsForCrop_noDeleted,
      seedFound_amongSeedsForCrop,
      seedFound_amongAll,
      isExistingTechOperation,
      // EFFECT_MUST_NOT_DEPEND_ON_CALLBACK_WITH_PARAMETERS setAllowAllSeedsAndFetch,
    ]);

    const initialAssetUnitsFromDictionary: string[] = useMemo(() => {
      if (seedFound_amongSeedsForCrop?.units) {
        return seedFound_amongSeedsForCrop.units;
      }
      return [""];
    }, [seedFound_amongSeedsForCrop]);

    const currentUnit = useMemo(
      () => initialFormAsset?.unit || initialAssetUnitsFromDictionary[0] || "",
      [initialFormAsset?.unit, initialAssetUnitsFromDictionary]
    );

    // disabled=true after 1) just added (empty ASSET dropdown), or 2) cleared non-empty by X
    const currentFormAsset: ITechOperationAssetFormData | undefined = useMemo(
      () => watchFields.assets?.[globalIndex],
      [watchFields, globalIndex]
    );
    const disabled1: boolean = useMemo(() => {
      const ret = currentFormAsset?.entityId;
      return ret === undefined;
      // sometimes currentFormAsset?.entityId = undefined when
      // currentFormAsset didn't change
    }, [currentFormAsset?.entityId]);

    const [renderAfterControllerFilled, setRenderAfterControllerFilled] = useState(false);
    useEffect(() => {
      setValue(`assets.${globalIndex}.unit`, currentUnit);
      setRenderAfterControllerFilled(true);
    }, [setValue, globalIndex, currentUnit]);

    const labelSuffix = useMemo(() => {
      if (seedsAll.length === 0) {
        return "";
      }
      return ` (${seedsAll.length})`;
    }, [seedsAll]);

    if (!renderAfterControllerFilled || !watchFields?.assets?.[globalIndex]) {
      // AVOIDING_WARNING_RHFSelectSimple_Material-UI: You have provided an out-of-range value `л` for the select component
      return <></>;
    }

    // static fields
    register(`assets.${globalIndex}.entityType`);
    register(`assets.${globalIndex}.id`);
    register(`assets.${globalIndex}.createdAt`);
    register(`assets.${globalIndex}.name`);

    const handleOptionGuidToObject = (optionGuid: string | null) =>
      seeds.find((x) => x.id === optionGuid);

    const handleOnOptionSelected = (newOption: Seed | null) => {
      setValue(`assets.${globalIndex}.name`, newOption?.name ?? "");
      setValue(`assets.${globalIndex}.entityId`, newOption?.id ?? "");
      setValue(`assets.${globalIndex}.unit`, newOption?.unit || "");
      setValue(`assets.${globalIndex}.units`, newOption?.units || [""]);
    };

    const unitsValue = getValues(`assets.${globalIndex}`)?.units || [
        getValues(`assets.${globalIndex}`)?.unit,
      ] || [""];

    const isSeedsForCrop_Nodeleted =
      seedsForCrop_noDeleted !== undefined && seedsForCrop_noDeleted.length === 0;

    return (
      <Grid2 container={true} xs={12} py={2} spacing={2}>
        <Grid2 xs={6}>
          <RHFAutocompleteSimple<Seed>
            name={`assets.${globalIndex}.entityId`}
            rules={{ required: true }}
            defaultValue={""}
            optionGuidToObject={handleOptionGuidToObject}
            onOptionSelected={handleOnOptionSelected}
            options={currentSeeds}
            noOptionsText={noOptionsText}
            textFieldProps={{
              required: true,
              // required: "Выберите семена из выпадающего списка",
              label: currentSeedsLabel,
            }}
          />
          {lostSeedName && (
            <Box style={{ color: "red" }}>&quot;{lostSeedName}&quot; не найден</Box>
          )}
          {isSeedsForCrop_Nodeleted && (
            <Box component="span" style={{ color: "green" }}>
              Для поля &quot;{formatFarmLand(watchFieldFarmLand)}&quot; доступны любые
              семена
            </Box>
          )}
        </Grid2>

        <Grid2
          xs={6}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={(evt) => {
                  setAllowAllSeedsAndFetch(evt.target.checked);
                  // tigger() DOES_NOT_MAKE_EMPTY_DROPDOWN_RED: seeds added FROM_ALL are not found in SEEDS_FOR_CROP
                  trigger(`assets.${globalIndex}.entityId`);
                }}
                checked={allowAllSeeds}
                // disabled={forceAllSeeds}
                color={"primary"}
              />
            }
            label={`Из всех культур ${labelSuffix}`}
          />
        </Grid2>

        {isAssetUsageRecordsEnabled ? (
          <Grid2 sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => {
                    setAllowAllAssets(evt.target.checked);
                    // tigger() DOES_NOT_MAKE_EMPTY_DROPDOWN_RED: seeds added FROM_ALL are not found in SEEDS_FOR_CROP
                    trigger(`assets.${globalIndex}.entityId`);
                  }}
                  checked={allowAllAssets}
                  color={"primary"}
                />
              }
              label={`Из всех ПХ`}
            />
          </Grid2>
        ) : null}

        <Grid2 xs={3}>
          <TechOpEditAssetTotal idx={globalIndex} />
        </Grid2>

        <Grid2 xs={3}>
          <RHFSelectSimple<string>
            name={`assets.${globalIndex}.unit`}
            defaultValue={""}
            options={unitsValue}
            label="Ед. измерения"
            disabled={disabled1 || disabled}
          />
        </Grid2>

        <Grid2 xs={3}>
          <RHFTextField
            name={`assets.${globalIndex}.applicationRate`}
            rules={{
              min: {
                value: 0.001, // TODO: need to get value from props
                message: "Поле не может быть отрицательным или равным нулю.",
              },
            }}
            TextFieldProps={{
              disabled: true, // динамически вычисленное значение, пользователь его больше не вводит
              label: "Факт. расход на 1 га",
              type: "number",
              value: applicationRateValue,
            }}
          />
        </Grid2>

        <Grid2 xs={3}>
          <RHFTextField
            name={`assets.${globalIndex}.seedUnitWeight`}
            defaultValue={initialFormAsset.seedUnitWeight?.toString() || ""}
            rules={{
              min: 0, // TODO: need to get value from props
            }}
            TextFieldProps={{
              disabled: disabled,
              type: "number",
              label: "Масса 1000 семян (в граммах)",
            }}
          />
        </Grid2>
      </Grid2>
    );
  }
);

TechOpEditSeed.displayName = "TechOpEditSeed";
