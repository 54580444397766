import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Feature, Geometry } from "geojson";
import { GeoJSON, LatLngBounds, LatLngExpression } from "leaflet";

import { ArcGisError, isArcGisError } from "../../../shared/services/error";
import { RootState } from "../../../store-types";
import { TArcgisMapCropPropsDto } from "../shared/dtos/arcgis-map-crop-props.dto";
import { IFarmDto } from "../shared/dtos/farm.dto";
import { IFarmNameTuple } from "../shared/interfaces/farm-name-tuple";
import { IMapPageState } from "../shared/interfaces/map-page-state";
import { FarmNameTuple } from "../shared/models/farm-name-tuple";
import { appUsersService } from "../shared/services/app-user.service";
import { arcgisCropsService } from "../shared/services/arcgis-crops.service";
// import { cropTypesService } from "../shared/services/crop-types.service";
import { CropMap, geoJsonService } from "../shared/services/geojson.service";
import { deverror, devwarn } from "../../../shared/utils/log";

export const MAP_PAGE_MODULE_NAME = "mapPage";
const initialMapPageState: IMapPageState = {
  geoJson: new GeoJSON<TArcgisMapCropPropsDto>(),
  geoJsonAlreadyFetchedForFarmSeason: "INITIAL_STATE:NO_GEOJSON_REQUESTED_YET",
  farmLandData: [],
  farmFeatures: undefined, // NOT_YET_LOADED => undefined
  isLoadingGeoJson: false,
  isLoading: false,
  center: [55, 46],
  zoom: 6,
  bounds: new LatLngBounds([
    [54, 84],
    [53, 83],
  ]),
  selectedCropNames: [],
  highlightedFarmLandId: null,
  isDrawerOpen: true,

  // TODO: remove unused
  farmLandCropsGeoFeatures: [],
  farmNameTuples: [],
  selectedFarmName: "",
  defaultFarmName: "",
  cropTypes: [],
};

export const mapPageSlice = createSlice({
  name: MAP_PAGE_MODULE_NAME,
  initialState: initialMapPageState,
  reducers: {
    setHighlightedFarmLandId(state, action: PayloadAction<string | null>): void {
      state.highlightedFarmLandId = action.payload;
    },
    setCenterAction(state, action: PayloadAction<LatLngExpression>): void {
      state.center = action.payload;
    },
    setZoomAction(state, action: PayloadAction<number>): void {
      state.zoom = action.payload;
    },
    setBoundsAction(state, action: PayloadAction<LatLngBounds>): void {
      const bounds = action.payload;
      if (bounds.isValid()) {
        state.bounds = bounds;
      }
    },
    setGeoJsonAction(
      state,
      action: PayloadAction<GeoJSON<TArcgisMapCropPropsDto>>
    ): void {
      state.geoJson = action.payload;
    },
    setGeoJsonAlreadyFetchedForFarmSeasonAction(
      state,
      action: PayloadAction<string>
    ): void {
      state.geoJsonAlreadyFetchedForFarmSeason = action.payload;
    },
    setFarmLandCropsGeoFeaturesAction(state, action): void {
      state.farmLandCropsGeoFeatures = action.payload;
    },
    setSelectedCropNamesAction(state, action): void {
      state.selectedCropNames = action.payload;
    },
    setSelectedFarmNameAction(state, action): void {
      state.selectedFarmName = action.payload;
    },
    setFarmNameTuplesAction(state, action): void {
      state.farmNameTuples = action.payload;
    },
    setDefaultFarmNameAction(state, action): void {
      state.defaultFarmName = action.payload;
    },
    setCropTypesAction(state, action): void {
      state.cropTypes = action.payload;
    },
    setFarmLandDataAction(state, action: PayloadAction<TArcgisMapCropPropsDto[]>): void {
      state.farmLandData = action.payload;
    },
    setFarmFeaturesAction(
      state,
      action: PayloadAction<Feature<Geometry, TArcgisMapCropPropsDto>[] | undefined>
    ): void {
      state.farmFeatures = action.payload;
    },
    toggleDrawerOpenAction(state, _: PayloadAction): void {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGeoJsonAction.fulfilled, (state) => {
        state.isLoadingGeoJson = false;
      })
      .addCase(fetchGeoJsonAction.pending, (state) => {
        state.isLoadingGeoJson = true;
      })
      .addCase(fetchGeoJsonAction.rejected, (state) => {
        state.isLoadingGeoJson = false;
      });
  },
});

export const mapReducer = mapPageSlice.reducer;
export const {
  setHighlightedFarmLandId,
  setFarmLandCropsGeoFeaturesAction,
  setSelectedCropNamesAction,
  setSelectedFarmNameAction,
  setFarmNameTuplesAction,
  setDefaultFarmNameAction,
  setCropTypesAction,
  setGeoJsonAction,
  setGeoJsonAlreadyFetchedForFarmSeasonAction,
  setZoomAction,
  setCenterAction,
  setBoundsAction,
  setFarmFeaturesAction,
  setFarmLandDataAction,
  toggleDrawerOpenAction,
} = mapPageSlice.actions;

export const fetchGeoJsonAction = createAsyncThunk<
  void,
  {
    farmId: string;
    seasonId: string;
    // REMOVE_AFTER_SWITCH_TO_GeoJsonService.getMapData()
    // farmName: string;
    // seasonYear: string;
  }
>(
  `${MAP_PAGE_MODULE_NAME}/fetchGeoJsonAction`,
  async ({ farmId, seasonId }, { dispatch, getState }) => {
    const rootState = getState() as RootState;
    const mapState: IMapPageState = rootState[MAP_PAGE_MODULE_NAME];

    const stringComparedByValue = `farmId:[${farmId}]:seasonId[${seasonId}]`;
    if (mapState.geoJsonAlreadyFetchedForFarmSeason === stringComparedByValue) {
      // this is too late here;
      // extraReducers()....addCase(fetchGeoJsonAction.pending
      //   had already changed
      //     state.isLoadingGeoJson = true;
      return; // resolves infinite backend request to /api/FarmLands/getFarmLandGeometriesBySeasonAndFarm
    }
    dispatch(setGeoJsonAlreadyFetchedForFarmSeasonAction("LOADING_NOW"));

    if (arcgisCropsService.getToken() === undefined) {
      const msg =
        "MOST_LIKELY_FarmLandCropsGeoService_WILL_RETURN_498_WITHOUT_ARCGIS_TOKEN";
      devwarn(`${fetchGeoJsonAction.name} ${msg}`);
    }

    // REMOVE_AFTER_SWITCH_TO_GeoJsonService.getMapData()
    // const featureCollection = await arcgisCropsService.getMapData({
    //   farmName,
    //   seasonId,
    // });

    const featureCollection: CropMap = await geoJsonService.getMapData({
      farmId: farmId,
      seasonId: seasonId,
    });

    if (isArcGisError(featureCollection)) {
      const error = featureCollection as ArcGisError;
      const rootCause = "WAIT_UNTIL_FarmLandCropsGeoService_ABSORBS_ARCGIS_TOKEN";
      deverror(`${fetchGeoJsonAction.name} ${rootCause}`, error);
      dispatch(setGeoJsonAlreadyFetchedForFarmSeasonAction(rootCause));
      return;
    }

    //const data = featureCollection.features.map((item) => item.properties)
    const data: TArcgisMapCropPropsDto[] = firstEncountered(featureCollection.features);

    const highlightedId = mapState.highlightedFarmLandId;

    // eslint-disable-next-line
    const highlightedFeature = featureCollection.features.find(
      (feature) => feature.properties.GDB_ARCHIVE_OID.toString() === highlightedId
    );

    const geoJson = new GeoJSON(featureCollection);
    dispatch(setGeoJsonAction(geoJson));
    dispatch(setGeoJsonAlreadyFetchedForFarmSeasonAction(stringComparedByValue));

    const bounds: LatLngBounds = geoJson.getBounds();
    dispatch(setBoundsAction(bounds));

    try {
      dispatch(setCenterAction(bounds.getCenter()));
    } catch (error) {
      console.warn(`EMPTY_GEOMETRIES: cant find center of the map`);
    }

    dispatch(setFarmLandDataAction(data));

    const features: Feature<Geometry, TArcgisMapCropPropsDto>[] =
      featureCollection.features;
    dispatch(setFarmFeaturesAction(features));
  }
);

export const fetchAvailableFarmsAction = createAsyncThunk(
  `${MAP_PAGE_MODULE_NAME}/fetchAvailableFarmsAction`,
  async (_, { dispatch }) => {
    const farmDtos = await appUsersService.availableFarmsList();
    const farmNameTuples = farmDtos
      .map(
        (farmDto: IFarmDto) =>
          new FarmNameTuple({
            id: farmDto.id,
            nameArcgis: farmDto.farmMap.nameArcgis,
            nameEkocrop: farmDto.farmMap.nameEkocrop,
          })
      )
      .sort((a: IFarmNameTuple, b: IFarmNameTuple) =>
        a.nameEkocrop > b.nameEkocrop ? 1 : a.nameEkocrop < b.nameEkocrop ? -1 : 0
      );

    dispatch(setFarmNameTuplesAction(farmNameTuples));
  }
);

// export const fetchDefaultFarmNameAction = createAsyncThunk(
//   `${MAP_PAGE_MODULE_NAME}/fetchDefaultFarmNameAction`,
//   async (_, { dispatch }) => {
//     const defaultFarmName = await appUsersService.getDefaultFarmName();
//     dispatch(setDefaultFarmNameAction(defaultFarmName));
//   }
// );

// export const fetchCropTypesAction = createAsyncThunk(
//   `${MAP_PAGE_MODULE_NAME}/fetchCropTypesAction`,
//   async (_, { dispatch }) => {
//     const cropTypes = await cropTypesService.cropTypeColorsList();
//     dispatch(setCropTypesAction(cropTypes));
//   }
// );

function firstEncountered(
  features: Feature<Geometry, TArcgisMapCropPropsDto>[]
): TArcgisMapCropPropsDto[] {
  // protection against an ArcGIS bug (we spent ~8 hours to find,
  // now console.error will save us another 8 hours next time)
  const mapCropByArcgisId = new Map<string | number, TArcgisMapCropPropsDto>();
  for (const x of features) {
    const featureId = x.id || x.properties.GDB_ARCHIVE_OID;
    if (!featureId) {
      continue;
    }
    if (mapCropByArcgisId.has(featureId)) {
      continue;
    }
    mapCropByArcgisId.set(featureId, x.properties);
  }

  if (features.length !== mapCropByArcgisId.size) {
    deverror(
      `ArcGis sends duplicate fields' polygons: ` +
        `featureCollection.features.length[${features.length}]` +
        ` != firstEncountered.size[${mapCropByArcgisId.size}]`
    );
  }

  const ret: TArcgisMapCropPropsDto[] = Array.from(mapCropByArcgisId.values());
  return ret;
}
