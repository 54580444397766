import { format } from "date-fns";
import { IFilterFormData } from "../interfaces/filter-form-data";
import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";
import { TAssetUsageFilter } from "../interfaces/asset-usage-filter";
import { ISO8601_DATE_FORMAT } from "../../../../constant";

export function filterFormDataToStateFilter(data: IFilterFormData): TAssetUsageFilter {
  const { isActive, from, to, ...other } = data;
  return {
    ...other,
    fromDate: format(from, ISO8601_DATE_FORMAT),
    toDate: format(to, ISO8601_DATE_FORMAT),
    statusIds: isActive ? [AssetUsageRecordStatus.Created] : [],
    techOperationGroups: [],
    techOperationSubGroups: [],
    cropTypeIds: [],
    farmlandIds: [],
  };
}
