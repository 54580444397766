import { useEffect } from "react";
import { useAppDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import { getEnabled } from "../../store/verification.selector";
import { verificationService } from "../services/verification.service";
import { addNotificationAction } from "../../../notifications";
import { NotificationType } from "../../../notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../notifications/shared/enums/notification-severity";
import {
  setEnabledAction,
  VERIFICATION_MODULE_NAME,
} from "../../store/verification.slice";
import { useModuleApi } from "../../../../shared/utils/use-module-api";

export function useVerification(): void {
  const dispatch = useAppDispatch();
  const enabled = useSelector(getEnabled);

  // INITIALIZATION
  useEffect(() => {
    // default verification handler
    verificationService.onError(
      (error, message = "Ответ сервера не валиден (см. консоль)") => {
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Warning,
            message,
            error,
          })
        );
        console.warn(message, error);
      }
    );

    if (process.env.NODE_ENV === "development") {
      dispatch(setEnabledAction(true));
    }
  }, [dispatch]);

  // enable service
  useEffect(() => verificationService.enable(enabled), [enabled]);

  // extend context by api
  useModuleApi(VERIFICATION_MODULE_NAME, {
    enable: (value: boolean) => dispatch(setEnabledAction(value)),
  });
}
