import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";

import { ISubmitButton } from "../shared/interfaces/auth-form";

export const SubmitButton = ({
  isValid,
  isLoading,
  autoHideMessage,
}: ISubmitButton): JSX.Element => {
  return (
    <Grid item={true} xs={12}>
      <Button
        disabled={!isValid || isLoading}
        type={"submit"}
        color={"primary"}
        variant={"contained"}
        startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
      >
        Изменить
      </Button>

      {autoHideMessage && (
        <Box component="span" mx={4}>
          {autoHideMessage}
        </Box>
      )}
    </Grid>
  );
};
