import { Skeleton } from "@mui/material";
import React, { useLayoutEffect, useMemo, useState } from "react";
import {
  GeoJSON as GeoJSONComponent,
  MapContainer,
  MapContainerProps,
  TileLayer,
} from "react-leaflet";

import { useCropStyles } from "../../../map-page/shared/utils/use-crop-styles";
import { FarmLand } from "../../shared/models/farm-land";
import {
  getFarmFeaturesData,
  getIsLoadingGeoJson,
} from "../../../../pages/map-page/store/map-page.selector";
import { useSelector } from "react-redux";
import { GeoJSON } from "leaflet";
import { TArcgisMapCropPropsDto } from "../../../../pages/map-page/shared/dtos/arcgis-map-crop-props.dto";
import { CropMap } from "../../../map-page/shared/services/geojson.service";
import { Feature, Geometry } from "geojson";
import { useLoadGeoJson } from "../../../map-page/hooks/use-load-geojson";
import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";

export const FarmLandBriefMap = ({
  farmLand,
  mapHeight = 150,
}: MapContainerProps & {
  farmLand: FarmLand;
  mapHeight?: number | string; // could be 100px, 30vh, calc(100vh - 30px)...
}): JSX.Element => {
  const { style } = useCropStyles();

  // https://github.com/PaulLeCam/react-leaflet/issues/936#issuecomment-1637647785
  const [unmountMap, setunmountMap] = useState(false);
  useLayoutEffect(() => {
    setunmountMap(false);
    return () => {
      setunmountMap(true); // prevents "Map container already initialized"
    };
  }, []);

  const farmLandFeaturesData: Feature<Geometry, TArcgisMapCropPropsDto>[] | undefined =
    useSelector(getFarmFeaturesData);

  const isLoadingGeoJson_byParent_FarmLandBrief = useSelector(getIsLoadingGeoJson);
  useLoadGeoJson();

  const singleFarmLandFound: Feature<Geometry, TArcgisMapCropPropsDto> | undefined =
    useMemo(() => {
      if (farmLandFeaturesData === undefined) {
        return undefined;
      }
      const ret = farmLandFeaturesData.find(
        (x) => x.properties.farmLandId === farmLand.id.toLocaleLowerCase()
      );
      return ret;
    }, [farmLandFeaturesData, farmLand.id]);

  const geoJson: GeoJSON<TArcgisMapCropPropsDto, Geometry> | undefined = useMemo(() => {
    if (!singleFarmLandFound) {
      return undefined;
    }
    const feature: Feature<Geometry, TArcgisMapCropPropsDto> = {
      type: "Feature",
      geometry: singleFarmLandFound.geometry,
      properties: singleFarmLandFound.properties,
    };
    const cropMap: CropMap = {
      features: [feature],
      type: "FeatureCollection",
    };
    return new GeoJSON(cropMap);
  }, [singleFarmLandFound]);

  if (unmountMap) {
    // не успевает вывестись на страницу
    return <SpinnerCenter minHeight="6vh" msg={"Загружаем карту..."} />;
  }

  if (isLoadingGeoJson_byParent_FarmLandBrief) {
    return (
      <SpinnerCenter minHeight="6vh" msg={`Загружаем карту всех полей хозяйства...`} />
    );
  }

  if (geoJson === undefined || geoJson.getLayers().length === 0) {
    return <span>Карта поля не заведена</span>;
  }

  return (
    <MapContainer
      style={{ height: mapHeight, width: "100%" }}
      placeholder={
        <Skeleton
          height={mapHeight}
          variant="rectangular"
          placeholder={"Загружаем карту..."}
        />
      }
      bounds={geoJson.getBounds()}
      scrollWheelZoom={false}
      zoomControl={false}
      attributionControl={false}
      dragging={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <GeoJSONComponent data={geoJson.toGeoJSON()} style={style} />
    </MapContainer>
  );
};
