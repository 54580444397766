import { AssetType } from "../enums/asset-type";

const matchTable = {
  [AssetType.SEED]: "Семена",
  [AssetType.CHEMICAL]: "СЗР",
  [AssetType.FERTILISER]: "Удобрения",
};
export function getTitleByAssetType(type: AssetType): string {
  return matchTable[type];
}
