import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const CircularProgressComponent = (props: { show: boolean }): JSX.Element => {
  const { show } = props;

  return (
    <>
      {show && (
        <Box
          sx={{
            height: "100%",
            width: "97%",
            background: "rgb(128 128 128 / 24%)",
            position: "absolute",
            top: 0,
            left: "56px",
            zIndex: 2000,
            padding: "20%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
