import {
  useCallback,
  useEffect, // useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { Seed } from "../../../../modules/seeds/shared/models/seed";
import { getSeedsAll_includeDeleted } from "../../../../modules/seeds/store/seeds.selector";
import { fetchSeedsForCropAction } from "../../../../modules/seeds/store/seeds.slice";
import { devwarn } from "../../../../shared/utils/log";
import { useAppDispatch } from "../../../../store";
import { useDeduplicator } from "./use-deduplicator";

export type SeedsForCrop = {
  allowAllSeeds: boolean;
  setAllowAllSeedsAndFetch: (newAllow: boolean) => void;
  seedsForCrop_noDeleted: Seed[] | undefined;
  seedsAll_includeDeleted: Seed[] | undefined;
};

export function useSeedsForCrop(cropId?: string): SeedsForCrop {
  const dispatch = useAppDispatch();
  const [allowAllSeeds, setAllowAllSeeds] = useState(false);

  const validCropId = cropId !== undefined && cropId !== "";

  // seedsForCropFetched undefined or a non-empty array
  const seedsAll_includeDeleted = useSelector(getSeedsAll_includeDeleted);
  const [seedsForCrop_noDeleted, setSeedsForCrop_noDeleted] = useState<Seed[] | undefined>();

  const { deduplicate } = useDeduplicator<Seed[]>("useSeedsForCrop");

  const fetchSeedsForCrop = useCallback(async () => {
    if (!validCropId) {
      return;
    }

    deduplicate({
      dataRequestKey: cropId,
      backendRequest: () => {
        const thunk = dispatch(fetchSeedsForCropAction(cropId));
        const requestPromise: Promise<Seed[]> = thunk.unwrap();
        return requestPromise;
      },
      onSuccess: (seedsForCropFetched_noDeleted) => {
        // if (seedsForCropFetched.length === 0) {
        //   setAllowAllSeeds(true);
        // }
        // seedsForCropFetched is now a non-empty array
        setSeedsForCrop_noDeleted(seedsForCropFetched_noDeleted);
      },
      onError: (rejectedValueOrSerializedError) => {
        devwarn(`FAILED_TO_fetchSeedsForCrop(${cropId}): `, rejectedValueOrSerializedError);
      },
    });
  }, [validCropId, cropId, deduplicate, dispatch]);

  useEffect(() => {
    fetchSeedsForCrop();
    //eslint-disable-next-line
  }, []);

  const setAllowAllSeedsAndFetch = useCallback(
    (newAllow: boolean) => {
      if (newAllow === allowAllSeeds) {
        return;
      }
      setAllowAllSeeds(newAllow);
      if (!newAllow) {
        return;
      }
      if (seedsForCrop_noDeleted !== undefined) {
        return; // fetchSeedsForCrop() was already invoked for cropId
      }
      fetchSeedsForCrop();
    },
    [allowAllSeeds, setAllowAllSeeds, seedsForCrop_noDeleted, fetchSeedsForCrop]
  );

  return {
    allowAllSeeds,
    setAllowAllSeedsAndFetch,
    seedsForCrop_noDeleted,
    seedsAll_includeDeleted,
  };
}
