import { UseFormReturn, useForm } from "react-hook-form";
import {
  ITechOperationFormData,
  emptyFormTechOp,
} from "../../shared/interfaces/tech-operation-form-data";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { TechOperation } from "../../shared/models/tech-operation";
import { useSelector } from "react-redux";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter";

export const OPERATION_NUMBER_NEW = -1;
export const OPERATION_NUMBER_NOT_SELECTED = -2;

export type RhfTechOpEditFormUtils = {
  sourceFarmLandId: string | undefined;
  resetFieldsBelowSubGroup: () => void;
  resetFieldsBelowGroup_forAddNewTechop: () => void;
  defaultValues: ITechOperationFormData;
  setDefaultValues: React.Dispatch<React.SetStateAction<ITechOperationFormData>>;
  rhfMethods: UseFormReturn<ITechOperationFormData>;
  assetsKeyToResetRhfAssetsArray: number;
  setAssetsKeyToResetRhfAssetsArray: React.Dispatch<React.SetStateAction<number>>;
  absorbTechOp_toDefaultValues: (techOp: TechOperation) => void;
  operationNumber: number;
  nextOperationNumber: number | undefined;
  setNextOperationNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
  cropId: string | undefined;
  setCropIdWrapper: (cropId?: string) => void;
  renderAfterControllerFilled: boolean;
  setRenderAfterControllerFilled: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useTechOpFormUtils(): RhfTechOpEditFormUtils {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const sourceFarmLandId = query.get("farmLandId") ?? undefined;

  const appFilterFarmId =
    useSelector(getFilter(FilterName.FarmId)) ?? "SELECT_FARM_FROM_UPPER_DROPDOWN";
  const appFilterSeasonId =
    useSelector(getFilter(FilterName.SeasonId)) ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN";

  const [defaultValues, setDefaultValues] = useState<ITechOperationFormData>(() => {
    // return {
    //   ...new TechOperation().setFarmLandId(filterFarmLandId).asFormData,
    //   startDate: null,
    //   finishDate: null,
    // };
    return emptyFormTechOp(appFilterFarmId, appFilterSeasonId, sourceFarmLandId);
  });

  const rhfMethods: UseFormReturn<ITechOperationFormData> =
    useForm<ITechOperationFormData>({
      mode: "all",
      reValidateMode: "onBlur",
      defaultValues,
    });

  // don't depend on rhfMethods in effects!!
  // rhfMethods is always a new pointer; while setValue is wrapped into useCallback()
  const watchFields = rhfMethods.watch();
  const { setValue, trigger } = rhfMethods; // resetField, reset, control, formState

  // KEY_INCREMENT: CLEANS_UP all assets added after switching to another SubGroup
  // "Внесение СЗР", "Обработка СЗР", добавить 5 СЗР => "Дератизация", стало 0 СЗР
  const anyNumber_incrementWillReset_assetsArray = 1;
  const [assetsKeyToResetRhfAssetsArray, setAssetsKeyToResetRhfAssetsArray] = useState(
    anyNumber_incrementWillReset_assetsArray
  );

  const [cropId, setCropId] = useState<string>();
  const setCropIdWrapper = useCallback(
    (cropIdPassed?: string) => {
      // setValue("cropId", cropId, { shouldDirty: true, shouldValidate: true });
      // setPartialDefaultValue({ cropId });
      setCropId(cropIdPassed);
    },
    [
      // setValue,
      // setPartialDefaultValue,
      setCropId,
    ]
  );

  const resetFieldsBelowSubGroup = useCallback(() => {
    setValue("comment", "");
    setValue("employeeId", "");
    setValue("fieldSize", null);
    setValue("finishDate", null);
    setValue("startDate", null);
    setValue("chemicalsMAE", null);
    setValue("seedsMAE", null);
    setValue("fertilisersMAE", null);

    // null to avoid "techAssetId is missing in the 'defaultValue' prop of either its Controller"
    setValue("techAssetId", "");
    setValue("trailerAssetId", "");

    // invalidate useFetchTechAssets_forTechOpIdQuery() cache?
    // dispatch(setTechOperationAssets(undefined));

    if (watchFields.assets !== undefined && watchFields.assets.length > 0) {
      try {
        // may not be needed: setAssetsKeyToResetRhfAssetsArray() works 100%
        setValue("assets", []);
      } catch (e) {
        // eslint-disable-next-line
        console.error(e); // setValue("assets", []) throws
      }
    }

    // https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
    // KEY_INCREMENT: CLEANS_UP all assets added after switching to another SubGroup
    // "Внесение СЗР", "Обработка СЗР", добавить 5 СЗР => "Дератизация", стало 0 СЗР
    setAssetsKeyToResetRhfAssetsArray((prev) => prev + 1);

    if (watchFields.params && Object.values(watchFields.params).length > 0) {
      setValue("params", {});
    }
  }, [
    setValue,
    watchFields.assets,
    watchFields.params,
    setAssetsKeyToResetRhfAssetsArray,
  ]);

  const resetFieldsBelowGroup_forAddNewTechop = useCallback(() => {
    const newDefaults: ITechOperationFormData = {
      ...emptyFormTechOp(
        defaultValues.farmId,
        defaultValues.seasonId || "SEASON_ERROR",
        defaultValues.farmLandId
      ),
      techOperationGroupId: defaultValues.techOperationGroupId,
      operationNumber: OPERATION_NUMBER_NEW,
    };
    setDefaultValues(newDefaults);
  }, [
    defaultValues.farmId,
    defaultValues.seasonId,
    defaultValues.farmLandId,
    defaultValues.techOperationGroupId,
    setDefaultValues,
  ]);

  // const [hasSubGroupId, setHasSubGroupId] = useState<boolean>(false);

  const [renderAfterControllerFilled, setRenderAfterControllerFilled] = useState(false);
  const absorbTechOp_toDefaultValues = useCallback(
    (techOp: TechOperation) => {
      setValue("operationNumber", techOp.operationNumber, { shouldValidate: true });
      setValue("techOperationSubGroupId", techOp.techOperationSubGroupId, {
        shouldValidate: true,
      });
      setValue("employeeId", techOp.employeeId);
      setValue("techAssetId", techOp.techAssetId);
      setValue("trailerAssetId", techOp.trailerAssetId ?? "");
      // setValue("startDate", parseISO(techOp.startDate)); // .startDate="2023-12-06"
      // setValue("finishDate", parseISO(techOp.finishDate)); // .startDate="2023-12-06"
      setValue("startDate", techOp.startDate);
      setValue("finishDate", techOp.finishDate);
      setValue("fieldSize", techOp.fieldSize);
      setValue("comment", techOp.comment);
      setValue(
        "assets",
        techOp.Asset.map((x) => x.asFormData)
      );

      // setHasSubGroupId(Boolean(techOp.techOperationSubGroupId));
      if (renderAfterControllerFilled) {
        trigger("startDate"); // triggers date validation (otherwise red error)
        trigger("finishDate"); // triggers date validation (otherwise red error)
      }

      if (techOp.cropId !== undefined) {
        // if techOp.cropId === undefined, then taken from farmLand chosen on the form
        setCropIdWrapper(techOp.cropId);
      }
    },
    [setValue, renderAfterControllerFilled, trigger, setCropIdWrapper]
  );

  const operationNumber: number = useMemo(() => {
    if (
      watchFields.operationNumber === undefined ||
      watchFields.operationNumber === null ||
      watchFields.operationNumber === ""
    ) {
      return OPERATION_NUMBER_NOT_SELECTED;
    }
    // after changing options in OperationNumber dropdown,
    // watchFields.operationNumber = "-1" as a string despite
    // watchFields.operationNumber's type declared is "number"
    const rhfGlitch = parseInt(watchFields.operationNumber.toString());
    return rhfGlitch;
  }, [watchFields.operationNumber]);

  const [nextOperationNumber, setNextOperationNumber] = useState<number>();

  return {
    sourceFarmLandId,
    resetFieldsBelowSubGroup,
    resetFieldsBelowGroup_forAddNewTechop,
    defaultValues,
    setDefaultValues,
    rhfMethods,
    assetsKeyToResetRhfAssetsArray,
    setAssetsKeyToResetRhfAssetsArray,
    absorbTechOp_toDefaultValues,
    operationNumber,
    nextOperationNumber,
    setNextOperationNumber,
    cropId,
    setCropIdWrapper,
    renderAfterControllerFilled,
    setRenderAfterControllerFilled,
  };
}
