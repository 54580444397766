import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useHideAssetsWriteOff } from "src/hooks/use-hide-asserts-writeoff";
import { useDisableGlobalFilters } from "../../../../modules/filter";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { AssetUsageRecordsMaster } from "../asset-usage-records-master/asset-usage-records-master";
import { useAppDispatch } from "../../../../store";
import { resetFilterAction } from "../../store/asset-usage-records.slice";

export const AssetUsageRecords = (): React.JSX.Element => {
  useDisableGlobalFilters();
  const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetFilterAction());
    },
    [dispatch]
  );

  return isAssetUsageRecordsEnabled ? (
    <CommonLayout title={"Списание ТМЦ"} contentPadding={0}>
      <Routes>
        <Route index={true} element={<AssetUsageRecordsMaster />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </CommonLayout>
  ) : (
    <Routes>
      <Route path="*" element={<Navigate to={"/map"} replace={true} />} />
    </Routes>
  );
};
