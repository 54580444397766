import { IIndicatorDto } from "./indicator.dto";

export interface IFarmLandDto {
  id: string;
  farmId: string;
  name: string;
  area: number | null;
  importMeteoStationId: string | null;
  arcgisObjectId: number;
  cropTypeId: string;
  arcgisGuid?: string;
  arcGisPreviousGuid?: string;
  shape?: { lat: number; lng: number }[]; // DEPRECATED !but needed
  shapeJson?: string;
  agrochemistry?: string;
  departmentId?: string;
  legalId?: string;
  seasonId?: string;
  number?: number;
  lastCropId?: string;
  cropId?: string;
  lat?: number;
  lng?: number;
  importId?: string;
  meteoStationId?: string;
  legalArea?: number;
  startedSowingAt?: number;
  finishedSowingAt?: number;
  startedHarvestingAt?: number;
  finishedHarvestingAt?: number;
  productivity?: number;
  plot?: string;
  importFieldId?: string;
  importCropName?: string;
  importCropSort?: string;
  cropShortName?: string;
  type?: string;
  score?: number;
  contourAreaInputMethod?: string;
  seedVariety?: string;
  varietyId?: string;
  seedOrigin?: string;
  productionPurpose?: string;
  growTechnology?: string;
  arcgisDepartmentId?: string;
  arcGisCreatedAt?: number;
  arcGisUpdatedAt?: number;
  arcGisSeason?: number;
  arcGisChangeSeason?: number;
  cropCode1c?: string;
  cropGuid1c?: string;
  cropAge?: number;
  importNzpCrop?: string;
  importNzpCropId?: string;
  arcGisNameGuid?: string;
  organicStatus?: string;
  deletedAt?: number;
  isDeleted?: boolean;
  createdAt?: number;
  updatedAt?: number;
  createdByUserId?: string;
  updatedByUserId?: string;
  soilTypeId?: string;
  _agrochemistryData?: IIndicatorDto;
  meteoStationDataId?: string;
}

export function formatFarmLand(dto?: IFarmLandDto): string {
  if (!dto) {
    return "ПОЛЕ НЕ ЗАДАНО";
  }
  return `${dto.name} - ${dto.cropShortName}: ${dto.area} га`;
}
