import React from "react";
import { Box } from "@mui/material";

import { ToggleFilterButton } from "./buttons/toggle-filter-button";
import { SettingsButton } from "./buttons/settings-button";
import { LastDayButton } from "./buttons/last-day-button";

export function AssetUsageRecordListControls(): React.JSX.Element {
  return (
    <Box>
      <ToggleFilterButton />
      <SettingsButton />
      <LastDayButton />
    </Box>
  );
}
