import { DevTool } from "@hookform/devtools";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



import { getLoadingUserDto } from "../../../../authentication/store/auth.selector";
import { resetPassword } from "../../../../authentication/store/auth.slice";
import { PATHS } from "../../../../constant";
import { useAppDispatch } from "../../../../store";
import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";

const FormContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  "& > *": {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  "& input": {
    padding: theme.spacing(1),
  },
}));

interface IFormData {
  email: string;
}

export const RestorePassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getLoadingUserDto);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<IFormData>({
    mode: "onChange",
    defaultValues: { email: "" },
  });

  const submitHandler = useCallback(
    async (data: IFormData) => {
      const { email } = data;
      try {
        await dispatch(resetPassword(email)).unwrap();
        navigate(PATHS.MAIN_PAGE);
      } catch (e) {
        dispatch(
          addNotificationAction({
            type: NotificationType.Alert,
            severity: NotificationSeverity.Error,
            message:
              "Указанная почта не найдена, проверьте корректность адреса или обратитесь к системному администратору.",
            error: e,
          })
        );
      }
    },
    [dispatch, navigate]
  );

  return (
    <FormContainer component={"form"} onSubmit={handleSubmit(submitHandler)}>
      <DevTool control={control} />
      <Typography variant="body2">Введите указанный при регистрации адрес электронной почты</Typography>

      <Controller
        name={"email"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            variant="standard"
            {...field}
            required={true}
            fullWidth={true}
            type={"email"}
            error={!!error}
            label={"Адрес электронной почты"}
            helperText={error ? error.message : ""}
          />
        )}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={(isDirty && !isValid) || isLoading}
        fullWidth={true}
      >
        {isLoading ? <CircularProgress size={20} /> : "Отправить запрос на изменение"}
      </Button>
    </FormContainer>
  );
};
