import { FilterList as FilterListIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../store";
import { getIsDrawerOpen } from "../../store/map-page.selector";
import { toggleDrawerOpenAction } from "../../store/map-page.slice";

export const MapFilterControl = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useSelector(getIsDrawerOpen);

  const handleToggleDrawer = useCallback(() => {
    dispatch(toggleDrawerOpenAction());
  }, [dispatch]);

  return (
    <Box
      sx={{
        zIndex: 1000,
        position: "absolute",
        top: 155,
        left: 12,
      }}
    >
      <IconButton size={"small"} sx={{ opacity: isDrawerOpen ? 0.3 : 1 }} onClick={handleToggleDrawer}>
        <FilterListIcon />
      </IconButton>
    </Box>
  );
};
