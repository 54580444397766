import { useCallback, useContext, useState } from "react";

import { OrderGroup } from "../../../../shared/interface";
import { PaginationContext } from "../containers/pagination-provider/pagination-provider";

export type Pagination = {
  limit: number;
  order: OrderGroup;
  page: number;
  offset: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeOrder: (orderGroup: OrderGroup) => void;
  handleChangeLimit: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function usePagination(defaultLimit = 10): Pagination {
  const [limit, setLimit] = useState<number>(defaultLimit);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<OrderGroup>([]); // TODO: use transform into adapter

  const handleChangePage = useCallback((_, pageNo: number) => setPage(pageNo), [setPage]);
  const handleChangeOrder = useCallback(
    (orderGroup: OrderGroup) => setOrder(orderGroup),
    [setOrder]
  );
  const handleChangeLimit = useCallback(
    (event: { target: { value: string | number } }) => {
      setLimit(+event.target.value);
      setPage(0);
    },
    [setLimit]
  );

  return {
    limit,
    order,
    page,
    offset: limit * page,
    handleChangePage, // TablePagination.onPageChange
    handleChangeOrder, // EnhancedTableHead.onOrderChange
    handleChangeLimit, // TablePagination.onRowsPerPageChange
  };
}

export function usePaginationContext(): Pagination {
  return useContext(PaginationContext);
}
