import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { ITechOperationDto } from "../../tech-operations/shared/dtos/tech-operation.dto";
import { TechOperation } from "../../tech-operations/shared/models/tech-operation";
import { techOperationsService } from "../../tech-operations/shared/services/tech-operations.service";
import { IFarmLandsTechState } from "../shared/interfaces/farm-lands-tech-state";
import {
  IFilters,
  emptyFilters,
} from "../../../modules/filter/shared/interfaces/filters-state";
import { initialPagination } from "../../../modules/filter/shared/utils/use-pagination-stored";
import { isEqual } from "lodash";
import { getFarmLandsTechState } from "./farm-lands-tech.selector";
import { RootState } from "../../../store-types";

export const FARM_LANDS_TECH_MODULE_NAME = "farmLandsTech";
export const farmLandsTechSlice = createSlice({
  name: FARM_LANDS_TECH_MODULE_NAME,
  initialState: () => {
    const initial: IFarmLandsTechState = {
      isLoading: false,
      list: [],
      noPredecessorsList: [],
      removeSplittedFields: false,
      listCount: 0,
      filters: emptyFilters,
      reRequestAfterEdit: false,
      pagination: { ...initialPagination },
      currentFarmLandId: "", // re-requests backend after we changed the field
    };
    return initial;
  },
  reducers: {
    setListAction(state, action): void {
      state.list = action.payload;
    },
    setListCountAction(state, action): void {
      state.listCount = action.payload;
    },
    setPredecessorsList(state, action: PayloadAction<TechOperation[]>): void {
      state.noPredecessorsList = action.payload;
    },
    setRemoveSplittedFields(state, action: PayloadAction<boolean>): void {
      state.removeSplittedFields = action.payload;
    },
    setFiltersAction(state, action): void {
      state.filters = action.payload;
    },
    setReRequestAfterEdit(state, action): void {
      state.reRequestAfterEdit = action.payload;
    },
    setPagination(state, action): void {
      state.pagination = action.payload;
    },
    setCurrentFarmLandId(state, action): void {
      state.currentFarmLandId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFarmLandsTechAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmLandsTechAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmLandsTechAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const farmLandsTechReducer = farmLandsTechSlice.reducer;
export const {
  setListAction,
  setListCountAction,
  setFiltersAction,
  setReRequestAfterEdit,
  setPagination,
  setCurrentFarmLandId,
  setPredecessorsList,
  setRemoveSplittedFields,
} = farmLandsTechSlice.actions;

// async actions
export const fetchFarmLandsTechAction = createAsyncThunk<
  void,
  { filter: ILoopbackFilter }
>(
  `${FARM_LANDS_TECH_MODULE_NAME}/fetchFarmLandsTech`,
  async ({ filter }, { dispatch }) => {
    const count = await techOperationsService.listCount(filter);
    const dtos: ITechOperationDto[] = await techOperationsService.list(filter);

    const models: TechOperation[] = dtos.map((dto) => {
      const model = new TechOperation(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));

    const noPredecessors = models.filter((x) => x.isOriginal);
    dispatch(setPredecessorsList(noPredecessors));

    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);

// https://github.com/reduxjs/redux-toolkit/issues/1888
export const cachedFetchFarmLandsTechAction = createAsyncThunk<
  void,
  {
    farmLandId: string;
    filter: ILoopbackFilter;
    filters: IFilters;
    forceRequest?: boolean;
  },
  { state: RootState }
>(
  `${FARM_LANDS_TECH_MODULE_NAME}/cachedFetchFarmLandsTechAction`,
  async ({ farmLandId, filter, filters }, { dispatch }) => {
    // cachedFetchFarmLandsTechAction() WILL request&fill .list when:
    // 1) forceRequest == true
    // 2) after item was edited, onSubmitted={() => dispatch(setReRequestAfterEdit(true))}
    // 3) filters are the same as for previous
    dispatch(setReRequestAfterEdit(false));
    dispatch(setFiltersAction(filters));
    dispatch(setCurrentFarmLandId(farmLandId));
    dispatch(fetchFarmLandsTechAction({ filter }));
  },
  {
    // condition: () => boolean: return false to cancel
    condition: ({ farmLandId, filters, forceRequest }, thunkApi) => {
      const WILL_REQUEST = true;
      const CANCEL_REQUEST = false;

      if (forceRequest) {
        return WILL_REQUEST;
      }

      const { getState } = thunkApi;
      const rootState: RootState = getState();
      const state: IFarmLandsTechState = getFarmLandsTechState(rootState);

      if (state.isLoading) {
        // false => cancel request; true => dispatch(fetchFarmLandsTechAction)
        return CANCEL_REQUEST;
      }

      if (state.reRequestAfterEdit) {
        // true => dispatch(fetchFarmLandsTechAction), false => cancel request
        return WILL_REQUEST;
      }

      if (state.currentFarmLandId !== farmLandId) {
        // true => dispatch(fetchFarmLandsTechAction), false => cancel request
        return WILL_REQUEST;
      }

      if (filters) {
        const sameFilters = isEqual(state.filters, filters);
        if (sameFilters) {
          // false => cancel request; true => dispatch(fetchFarmLandsTechAction)
          return CANCEL_REQUEST;
        }
      }

      // true => dispatch(fetchFarmLandsTechAction), false => cancel request
      return WILL_REQUEST;
    },
  }
);
