import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { IFilterFormData } from "../../../shared/interfaces/filter-form-data";
import { AssetType } from "../../../shared/enums/asset-type";
import { getTitleByAssetType } from "../../../shared/utils/get-title-by-asset-type";

export const AssetTypesField = (): React.JSX.Element => {
  const { control } = useFormContext<IFilterFormData>();

  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel id="toCropDailyFarm">Tип ТМЦ</InputLabel>
      <Controller
        name={"assetCategories"}
        control={control}
        render={({ field }) => (
          <Select variant="standard" multiple {...field}>
            {Array.from(Object.values(AssetType)).map((item) => (
              <MenuItem key={item} value={item}>
                {getTitleByAssetType(item)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
