import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store-types";
import { ICurrentFarmLandsState } from "./current-farm-lands-state";
import { CURRENT_FARM_LANDS_MODULE_NAME } from "./current-farm-lands.slice";

export const getFarmLandsState = (rootState: RootState): ICurrentFarmLandsState =>
  rootState[CURRENT_FARM_LANDS_MODULE_NAME];

export const getLoadingCurrentFarmLands = createDraftSafeSelector(
  getFarmLandsState,
  (state: ICurrentFarmLandsState) => state.isLoading
);
export const getCurrentFarmLands_includeDeleted = createDraftSafeSelector(
  getFarmLandsState,
  (state: ICurrentFarmLandsState) => state.сurrentFarmLands_includeDeleted
);
export const getCurrentFarmLands_nonDeleted = createDraftSafeSelector(
  getFarmLandsState,
  (state: ICurrentFarmLandsState) => state.сurrentFarmLands_nonDeleted
);
