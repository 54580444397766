import React, { useCallback, useMemo, useState } from "react";
import { Event as EventIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../store";
import { endOfDay, format, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import {
  setFilterAction,
  toggleIsFilterOpen,
} from "../../../store/asset-usage-records.slice";
import { getFilter } from "../../../store/asset-usage-records.selector";
import { getInfoSeasons } from "../../../../../modules/info-data";
import { ISO8601_DATE_FORMAT } from "../../../../../constant";

enum LastDayOption {
  Day,
  Week,
  Month,
  Season,
}

const translations = ["Прошлый день", "За неделю", "За месяц", "За сезон"];
export const LastDayButton = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useSelector(getFilter);
  const seasons = useSelector(getInfoSeasons);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const currentSeason = useMemo(() => seasons?.find((item) => item.isCurrent), [seasons]);
  const [period, setPeriod] = useState<LastDayOption>(LastDayOption.Day);

  // TODO: handle outer period change (need to track from-to state vars and add 'произвольный период')

  const handleSelectOption = useCallback(
    (option: LastDayOption) => {
      let from: Date;

      switch (option) {
        case LastDayOption.Day:
          from = startOfDay(new Date());
          setPeriod(LastDayOption.Day);
          break;
        case LastDayOption.Week:
          from = startOfWeek(new Date());
          setPeriod(LastDayOption.Week);
          break;
        case LastDayOption.Month:
          from = startOfMonth(new Date());
          setPeriod(LastDayOption.Month);
          break;
        case LastDayOption.Season:
          if (!currentSeason) {
            return;
          }
          from = startOfDay(new Date(currentSeason.startedAt));
          setPeriod(LastDayOption.Season);
          break;
        default:
          return;
      }

      const toDate = format(endOfDay(new Date()), ISO8601_DATE_FORMAT);
      const fromDate = format(from, ISO8601_DATE_FORMAT);

      // TODO: remove this temp hack in favor of update filter from store date (logic should be in filter - use watch)
      dispatch(toggleIsFilterOpen());
      dispatch(
        setFilterAction({
          ...filter,
          toDate,
          fromDate,
        })
      );
      Promise.resolve().then(() => dispatch(toggleIsFilterOpen()));
    },
    [dispatch, filter, currentSeason]
  );

  return (
    <>
      <Button
        id="predefined-asset-usage-dates"
        aria-controls={open ? "predefined-asset-usage-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<EventIcon />}
        onClick={handleOpenMenu}
      >
        {translations[period].toUpperCase()}
      </Button>

      <Menu
        id="predefined-asset-usage-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "predefined-asset-usage-dates",
        }}
      >
        <MenuItem onClick={() => handleSelectOption(LastDayOption.Day)}>
          {translations[LastDayOption.Day]}
        </MenuItem>
        <MenuItem onClick={() => handleSelectOption(LastDayOption.Week)}>
          {translations[LastDayOption.Week]}
        </MenuItem>
        <MenuItem onClick={() => handleSelectOption(LastDayOption.Month)}>
          {translations[LastDayOption.Month]}
        </MenuItem>
        <MenuItem onClick={() => handleSelectOption(LastDayOption.Season)}>
          {translations[LastDayOption.Season]}
        </MenuItem>
      </Menu>
    </>
  );
};
