import { Box, Tooltip, Zoom } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";

export function BoxTooltipMouseover({
  title,
  children,
}: PropsWithChildren<{
  title: string | React.ReactElement;
}>): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const toggleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      onOpen={toggleTooltipOpen}
      open={open}
      disableFocusListener
      disableTouchListener
      TransitionComponent={Zoom}
      placement="bottom-end"
      title={title}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
