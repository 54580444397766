import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { EmployeeModel, IEmployeesDto, IEmployeesState, employeesService } from "../shared";

const initialState: IEmployeesState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const MODULE_NAME = "employees";
export const employeesSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<EmployeeModel[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmployeesAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchEmployeesAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployeesAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const employeesReducer = employeesSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } = employeesSlice.actions;

export const fetchEmployeesAction = createAsyncThunk(`${MODULE_NAME}/fetchEmployees`, async (params, { dispatch }) => {
  const dtos = await employeesService.list({});
  safeSortEmployeesByName(dtos);
  const models = dtos.map((dto) => {
    const model = new EmployeeModel(dto.id);
    model.updateFromDto(dto);
    return model;
  });
  dispatch(setListAction(models));
});

export const deleteEmployeesAction = createAsyncThunk<void, string[]>(
  `${MODULE_NAME}/deleteEmployeesAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => employeesService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

function safeSortEmployeesByName(dtos: IEmployeesDto[]): void {
  dtos.sort((a, b) => {
    try {
      if (a === null || b === null) {
        return 0; // never happened (insert your breakpoint here)
      }
      if (a.fullName === null) {
        a.fullName = a.lastName + " " + a.firstName; // "Тестовый 3 Пользователь 3"
      }
      if (b.fullName === null) {
        b.fullName = b.lastName + " " + b.firstName; // "Тестовый 2 Пользователь 2"
      }
      const ret = a.fullName.localeCompare(b.fullName);
      return ret;
    } catch (e) {
      // console.error(`safeSortEmployeesByName: [e, a, b]`, [e, a, b]);
      return 0;
    }
  });
}
