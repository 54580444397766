import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store-types";
import { IUserDto } from "../shared/dtos/user.dto";
import { IAuthState } from "../shared/interfaces/auth-state";
import { ILoginCredentials } from "../shared/interfaces/login-credentials";
import { ITokenResponse } from "../shared/interfaces/token-response";
import { IUser } from "../shared/interfaces/user";
import { User } from "../shared/models/user";
import { authService } from "../shared/services/auth.service";
import { getAuthState } from "./auth.selector";

export const AUTH_MODULE_NAME = "auth";
export const TOKEN_STORED_KEY = `${AUTH_MODULE_NAME}/token`;
export const USER_ID_STORED_KEY = `${AUTH_MODULE_NAME}/userId`;

export const initialState: IAuthState = {
  isAuthenticated: false,
  user: null,
  userId: localStorage.getItem(USER_ID_STORED_KEY),
  token: localStorage.getItem(TOKEN_STORED_KEY),
  isLoadingUserDto: false,
  isAuthenticating: false,
  error: null,
};

export const authSlice = createSlice({
  name: AUTH_MODULE_NAME,
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<ITokenResponse>) => {
      state.isAuthenticated = true;
      state.token = action.payload.id;
      state.userId = action.payload.userId;

      localStorage.setItem(TOKEN_STORED_KEY, state.token);
      localStorage.setItem(USER_ID_STORED_KEY, state.userId);

      // TODO: backward compatibility (remove it later)
      localStorage.setItem("access_token", state.token);
      localStorage.setItem("user_id", state.userId);
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.userId = null;
      localStorage.removeItem(TOKEN_STORED_KEY);
      localStorage.removeItem(USER_ID_STORED_KEY);

      // TODO: backward compatibility (remove it later)
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_id");

      (async () => {
        const dbs = await window.indexedDB.databases();
        dbs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name!); // eslint-disable-line
        });
      })();
    },
    reloadPage: () => {
      document.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoadingUserDto = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoadingUserDto = false;
        authSlice.caseReducers.setUser(state, action);
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLoadingUserDto = false;
        state.error = action.payload as string;
        authSlice.caseReducers.logoutSuccess(state);
      })
      .addCase(authenticate.pending, (state) => {
        state.isAuthenticating = true;
        state.error = null;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.isAuthenticating = false;
        authSlice.caseReducers.loginSuccess(state, action);
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.isAuthenticating = false;
        state.error = action.error.message || null;
        authSlice.caseReducers.logoutSuccess(state);
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoadingUserDto = true; // TODO: create a new boolean in State?
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoadingUserDto = false; // TODO: create a new boolean in State?
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoadingUserDto = false; // TODO: create a new boolean in State?
      });
  },
});

export const { loginSuccess, logoutSuccess, setUser, reloadPage } = authSlice.actions;

export const authSliceReducer = authSlice.reducer;

export const getCurrentUser = createAsyncThunk<IUser, void>(
  `${AUTH_MODULE_NAME}/getCurrentUser`,
  async (_, { getState, rejectWithValue }) => {
    const { token, userId } = getAuthState(getState() as RootState); // TODO: remove token if it wasn't needed

    if (!token || !userId) {
      return rejectWithValue("There are no required credential to get user info");
    }

    try {
      const userDto = await authService.get(userId);
      const user = new User(userDto.id);
      user.updateFromDto(userDto);

      return user;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk<IUser, IUserDto>(
  `${AUTH_MODULE_NAME}/updateUser`,
  async (dto, { getState, rejectWithValue }) => {
    const { token, userId, user } = getAuthState(getState() as RootState);

    if (!token || userId !== dto.id || !user) {
      return rejectWithValue("There are no required credential to update user info");
    }

    try {
      const userDto = await authService.updateUser(dto, token);
      user.updateFromDto(userDto);

      return user;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateOwnPassword = createAsyncThunk<
  void,
  { oldPassword: string; newPassword: string }
>(
  `${AUTH_MODULE_NAME}/updateOwnPassword`,
  async ({ oldPassword, newPassword }, { getState, rejectWithValue }) => {
    const { token } = getAuthState(getState() as RootState);

    if (!token) {
      return rejectWithValue("There are no required credential to change password");
    }

    try {
      await authService.changeOwnPassword({ oldPassword, newPassword, token });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const authenticate = createAsyncThunk<ITokenResponse, ILoginCredentials>(
  `${AUTH_MODULE_NAME}/authenticate`,
  async (credentials, { rejectWithValue }) => {
    const { email, password } = credentials;
    if (!email || !password) {
      return rejectWithValue("There are no required credential to authenticate user");
    }

    try {
      return authService.getToken(credentials);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const login = createAsyncThunk<void, ILoginCredentials>(
  `${AUTH_MODULE_NAME}/login`,
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(authenticate(credentials)).unwrap();
      await dispatch(getCurrentUser()).unwrap();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetPassword = createAsyncThunk<void, string>(
  `${AUTH_MODULE_NAME}/resetPassword`,
  async (email, { rejectWithValue }) => {
    if (!email) {
      return rejectWithValue("Email address should be provided");
    }

    try {
      await authService.resetPassword({ email });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
