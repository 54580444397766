export type InclinationDefinition = {
  zero: string;
  one: string;
  twentyTwoToTwentyFour: string;
  rest: string;
};

export function inclination({
  count,
  definition,
  feminine = false,
}: {
  count: number;
  definition: InclinationDefinition;
  feminine?: boolean;
}): string {
  const { zero, one, twentyTwoToTwentyFour, rest } = definition;

  const remainder = count % 10; // 21 % 10 = 1
  if (remainder === 0) {
    // показать 10 20 30 => "записей"
    // убрать 10 20 30 => "предшественников"
    // 10 20 30 => "предшественников"
    return zero;
  }

  if (remainder >= 2 && remainder <= 4) {
    if (feminine) {
      if (count < 10) {
        // 2 3 4 => "записи"
        return twentyTwoToTwentyFour;
      } else if (count < 20) {
        // 12 13 14 => "записей"
        return rest;
      } else {
        // показать 2 3 4 => "записи"
        // показать 22 33 44  => "записи"
        return twentyTwoToTwentyFour;
      }
    } else {
      if (count < 10) {
        // 2 3 4 => "предшественника"
        return twentyTwoToTwentyFour;
      } else if (count < 20) {
        // 12 13 14  => "предшественников"
        return rest;
      } else {
        // 22 33 44  => "предшественника"
        return twentyTwoToTwentyFour;
      }
    }
  }

  if (remainder === 1) {
    if (feminine) {
      if (count === 11) {
        // 11 12 13 => "записей"
        return zero;
      } else {
        // показать 11 21 31 => "запись"
        // 21 31 => "запись"
        return one;
      }
    } else {
      if (count < 10) {
        // 1 => "предшественник"
        return one;
      } else if (count < 20) {
        // 12 13 14  => "предшественников"
        return rest;
      } else {
        // убрать 11 21 101 => "предшественника"
        // 21 101 => "предшественник"
        return one;
      }
    }
  }

  // показать 6 12 39 55 => "записей"
  // убрать 6 12 39 => "предшественников"
  return rest;
}
