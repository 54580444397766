import { Navigate, Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./authentication/containers/protected-route/protected-route";
import { AssetUsageRecords } from "./pages/asset-usage-records/containers/asset-usage-records/asset-usage-records";
import { Catalogs } from "./pages/catalogs/catalogs";
import { Dashboard } from "./pages/dashboard/containers/dashboard/dashboard";
import { FarmLands } from "./pages/fields/containers/farm-lands/farm-lands";
import { Login } from "./pages/login";
import { MapComponent } from "./pages/map-page";
import { Observations } from "./pages/observations/containers/observations/observations";
import { Settings } from "./pages/settings/containers/settings/settings";
import { TechOperations } from "./pages/tech-operations";
import { PATHS } from "./constant";

export const AppRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path={`${PATHS.LOGIN_PAGE}/*`} element={<Login />} />
      <Route
        path={PATHS.MAIN_PAGE}
        element={<ProtectedRoute urlWhenUserFetchFailed={PATHS.LOGIN_PAGE} />}
      >
        <Route path={`${PATHS.CATALOG_PAGE}/*`} element={<Catalogs />} />
        <Route path={`${PATHS.MAP_PAGE}/*`} element={<MapComponent />} />
        <Route path={`${PATHS.TECHOPERATIONS_PAGE}/*`} element={<TechOperations />} />
        <Route path={`${PATHS.FIELDS_PAGE}/*`} element={<FarmLands />} />
        <Route path={`${PATHS.OBSERVATIONS_PAGE}/*`} element={<Observations />} />
        <Route path={`${PATHS.DASHBOARD}/*`} element={<Dashboard />} />
        <Route path={`${PATHS.SETTINGS}/*`} element={<Settings />} />
        <Route path={`${PATHS.ASSET_USAGE_RECORDS}/*`} element={<AssetUsageRecords />} />
        <Route index={true} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
        <Route path={"*"} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
      </Route>
    </Routes>
  );
};
