import { useCallback, useState } from "react";

import { OrderGroup } from "../../../../shared/interface";

export type PaginationData = {
  limit: number;
  order: OrderGroup;
  page: number;
};

export const initialPagination: PaginationData = {
  limit: 10,
  order: [],
  page: 0,
};

export type PaginationHandle = PaginationData & {
  offset: number;
  handleChangePage: (_, pageNo: number) => void;
  handleChangeOrder: (orderGroup: OrderGroup) => void;
  handleChangeLimit: (event: { target: { value: string | number } }) => void;

  // paginationClicksAfterMount is a counter of each click on pagination (order/page/pageSize);
  // paginationClicksAfterMount=0 after we went away from list to another tab and came back;
  // used in list-bound effects to re-request the list on page changed; EXAMPLE:
  // const force = paginationClicksAfterMount > 0; requestPageForFilters(force);
  paginationClicksAfterMount: number;
};

export function usePaginationStored({
  fromStore,
  toStore,
}: {
  fromStore: () => PaginationData;
  toStore: (full: PaginationData) => void;
}): PaginationHandle {
  const pagination = fromStore();
  const { limit, order, page } = pagination;
  const [paginationClicksAfterMount, setPaginationClicksAfterMount] = useState(0);

  const setToStore = useCallback(
    (partial: Partial<PaginationData>): void => {
      toStore({ ...pagination, ...partial });
    },
    [pagination, toStore]
  );

  const handleChangePage = useCallback(
    (_, pageNo: number) => {
      setToStore({
        page: pageNo,
      });
      setPaginationClicksAfterMount((x) => x + 1);
    },
    [setToStore, setPaginationClicksAfterMount]
  );

  const handleChangeOrder = useCallback(
    (orderGroup: OrderGroup) => {
      setToStore({
        order: orderGroup,
      });
      setPaginationClicksAfterMount((x) => x + 1);
    },
    [setToStore, setPaginationClicksAfterMount]
  );

  const handleChangeLimit = useCallback(
    (event: { target: { value: string | number } }) => {
      setToStore({
        limit: +event.target.value,
        page: 0,
      });
      setPaginationClicksAfterMount((x) => x + 1);
    },
    [setToStore, setPaginationClicksAfterMount]
  );

  return {
    limit,
    order,
    page,
    offset: limit * page,
    handleChangePage,
    handleChangeOrder,
    handleChangeLimit,
    paginationClicksAfterMount,
  };
}
