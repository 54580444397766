import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IFilter } from "../../../shared/interface";
import { IWeedsState, WeedModel, weedsService } from "../shared";

const initialState: IWeedsState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const MODULE_NAME = "weeds";
export const weedsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<WeedModel[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWeedsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchWeedsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchWeedsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const weedsReducer = weedsSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } = weedsSlice.actions;

export const fetchWeedsAction = createAsyncThunk<void, Partial<IFilter> | undefined>(
  `${MODULE_NAME}/fetchWeeds`,
  async (params = {}, { dispatch }) => {
    const dtos = await weedsService.list(params);
    const models = dtos.map((dto) => {
      const model = new WeedModel(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
  }
);

export const deleteWeedsAction = createAsyncThunk<void, string[]>(
  `${MODULE_NAME}/deleteCropsAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => weedsService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
