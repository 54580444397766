import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";

export const TechOpEditAssetTotal = ({ idx }: { idx: number }): JSX.Element => {
  const { watch, setValue, unregister } = useFormContext<ITechOperationFormData>();
  const assets = watch("assets");
  const fieldSize = watch("fieldSize");

  const formAsset = useMemo(() => {
    if (assets === undefined) {
      return undefined;
    }
    if (assets.length < idx - 1) {
      return undefined;
    }
    return assets[idx] as ITechOperationAssetFormData;
  }, [assets, idx]);

  const handleChange = (value: string) => {
    const quantityReal = Number(Number(value)?.toFixed(3));
    // «Факт. расход на 1 га» («Норма расхода») = «Общий расход ТМЦ» (Количество затраченных ТМЦ) / «Завершённая площадь»
    const applicationRate = Number((fieldSize ? quantityReal / fieldSize : 0).toFixed(3));
    setValue(`assets.${idx}.quantityReal`, quantityReal);
    setValue(`assets.${idx}.applicationRate`, applicationRate);
    unregister(`assets.${idx}.quantity`);
  };

  return (
    <RHFTextField
      name={`assets.${idx}.quantityReal`}
      rules={{
        min: 0.001,
      }}
      TextFieldProps={{
        required: true,
        label: "Общий расход ТМЦ",
        type: "number",
        value: formAsset?.quantityReal || "",
      }}
      renderOnChange={handleChange}
    />
  );
};
