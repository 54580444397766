import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { devlog, devwarn } from "../../../shared/utils/log";
import { ISeedsDto, ISeedsState, seedsService } from "../shared";
import { Seed } from "../shared/models/seed";
import { verificationService } from "src/modules/verification/shared/services/verification.service";
import { SeedsListSchema } from "../shared/dtos/seeds.schema";

const initialState: ISeedsState = {
  isLoading: false,
  list: [],
  seedsAll_includeDeleted: undefined, // undefined while NOT_YET_LOADED
  selectedIds: [],
  searchText: "",
};
export const MODULE_NAME = "seeds";
export const seedsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<Seed[]>): void {
      state.list = action.payload;
    },
    setSeedsIncludeDeletedAction(state, action: PayloadAction<Seed[]>): void {
      state.seedsAll_includeDeleted = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeedsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchSeedsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchSeedsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const seedsReducer = seedsSlice.reducer;
export const {
  setListAction,
  setSeedsIncludeDeletedAction,
  setSelectedIdsAction,
  toggleId,
  setSearchText,
} = seedsSlice.actions;

export const fetchSeedsAction = createAsyncThunk(
  `${MODULE_NAME}/fetchSeeds`,
  async (params, { dispatch }) => {
    const dtos: ISeedsDto[] = await seedsService.list({}).then(
      verificationService.check({
        cacheId: "SeedsListSchema",
        schema: SeedsListSchema,
        message: "Невалидный ответ сервера (SeedsListSchema)",
      })
    );

    // TODO: remove this filtration in favor of proper backend responce
    const models: Seed[] = dtos
      .filter((dto) => Boolean(dto.isDeleted) === false) // 3355
      .map((dto) => {
        const model = new Seed(dto.id);
        model.updateFromDto(dto);
        return model;
      });
    models.sort((a: Seed, b: Seed) => a.name.localeCompare(b.name));
    dispatch(setListAction(models));
  }
);

export const fetchAllSeedsIncludeDeletedAction = createAsyncThunk(
  `${MODULE_NAME}/fetchAllSeedsIncludeDeletedAction`,
  async (_, { dispatch }) => {
    const dtos: ISeedsDto[] = await seedsService
      .list({
        where: { or: [{ isDeleted: true }, { isDeleted: false }] },
      })
      .then(
        verificationService.check({
          cacheId: "SeedsListSchema",
          schema: SeedsListSchema,
          message: "Невалидный ответ сервера (SeedsListSchema)",
        })
      );
    const models: Seed[] = dtos.map((dto) => {
      const model = new Seed(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    models.sort((a: Seed, b: Seed) => a.name.localeCompare(b.name));
    dispatch(setSeedsIncludeDeletedAction(models));
    return models;
  }
);

export const fetchSeedsForCropAction = createAsyncThunk(
  `${MODULE_NAME}/fetchSeedsForCrop`,
  async (cropId: string) => {
    const dtos: ISeedsDto[] = await seedsService.seedsForCrop(cropId).then(
      verificationService.check({
        cacheId: "SeedsListSchema",
        schema: SeedsListSchema,
        message: "Невалидный ответ сервера (SeedsListSchema)",
      })
    );

    const noDeleted = dtos.filter((x) => x.isDeleted === false);
    const allSeedsLength = dtos.length;
    const noDeletedLength = noDeleted.length;
    const deletedSeeds = allSeedsLength - noDeletedLength;
    if (deletedSeeds !== 0) {
      devwarn(
        `fetchSeedsForCrop: deletedSeeds[${deletedSeeds}]` +
          ` = allSeedsLength[${allSeedsLength}] - noDeletedLength[${noDeletedLength}]`
      );
    } else {
      devlog(
        `fetchSeedsForCrop: deletedSeeds[${deletedSeeds}]` +
          `; allSeedsLength[${allSeedsLength}] = noDeletedLength[${noDeletedLength}]`
      );
    }

    const seedsContainingCropId = noDeleted.filter(
      (x) => x.crops === undefined || x.crops.map((y) => y.id).includes(cropId)
    );

    // for x.crops === undefined, seedsForUnrelatedCrop = 0
    const seedsForUnrelatedCrop = dtos.length - seedsContainingCropId.length;
    if (seedsForUnrelatedCrop) {
      const garbage = dtos.filter(
        (x) => x.crops === undefined || !x.crops.map((y) => y.id).includes(cropId)
      );
      devlog(
        `seedsForUnrelatedCrop[${seedsForUnrelatedCrop}]` +
          ` = dtos[${dtos.length}] - dtosFiltered[${seedsContainingCropId.length}]` +
          `; garbage:`,
        garbage
      );
    }

    const models: Seed[] = seedsContainingCropId.map((dto) => {
      const model = new Seed(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    models.sort((a: Seed, b: Seed) => a.name.localeCompare(b.name));
    return models;
  }
);

export const deleteSeedsAction = createAsyncThunk<Promise<void> | unknown, string[]>(
  `${MODULE_NAME}/deleteCropsAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => seedsService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
