import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PATHS } from "../../../../constant";
import {
  last5digitsGuid,
  techOpEditUrl,
} from "../../../../shared/utils/format-techop-id";
import { ITechOperationSubGroupDto } from "../../shared/dtos/tech-operation-sub-group.dto";
import { ITechOperationDto } from "../../shared/dtos/tech-operation.dto";
import { inclination } from "../../shared/utils/inclination";
import { FieldFormatted } from "./field-formatted";
import { TechOpPredecessorsFilter } from "./techop-history-filter";

export function TechopHistory({
  operationsForSeason,
  noPredecessorsList,
  techOperationsSubGroups,
  subGroupId,
  fieldMax,
  remainderForThisOpNumber,
  onDoubleClick,
  firstLinesToShow = 4,
}: {
  operationsForSeason: ITechOperationDto[];
  noPredecessorsList: ITechOperationDto[];
  techOperationsSubGroups: ITechOperationSubGroupDto[];
  subGroupId: string;
  fieldMax: number;
  remainderForThisOpNumber?: number;
  onDoubleClick?: () => void;
  firstLinesToShow?: number;
}): JSX.Element {
  const hasPredecessors = operationsForSeason.length !== noPredecessorsList.length;
  const [removePredecessors, setRemovePredecessors] = useState(true);
  const agroWorks = useMemo(
    () => (removePredecessors ? noPredecessorsList : operationsForSeason),
    [removePredecessors, noPredecessorsList, operationsForSeason]
  );

  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const onlyForCurrentSubGroup = useMemo(() => {
    // 3612: sum area for agroworks only with current techOperationSubGroupId
    const ret = agroWorks.filter((x) => x.techOperationSubGroupId === subGroupId);
    return ret;
  }, [agroWorks, subGroupId]);

  // 3628 3626 3625 3630: sum area for agroworks only for ORIGINAL farmLands (no predecessor)
  // const onlyForNoPredecessor = onlyForCurrentSubGroup.filter(isOriginalDto);

  const areaProcessed = useMemo(() => {
    const ret = onlyForCurrentSubGroup.reduce((acc, op) => acc + op.fieldSize, 0);
    return ret;
  }, [onlyForCurrentSubGroup]);

  const moreThanLimit = useMemo(
    () => agroWorks.length > firstLinesToShow,
    [agroWorks, firstLinesToShow]
  );

  const tableRows_limitedOrAll = useMemo(() => {
    if (agroWorks.length <= firstLinesToShow) {
      return agroWorks;
    }
    if (collapsed) {
      // return firstLinesToShow records, as expanded=false
      return agroWorks.filter((_, index: number) => index <= firstLinesToShow - 1);
    }
    // return all available records: show all
    return agroWorks;
  }, [agroWorks, firstLinesToShow, collapsed]);

  const subGroupName = useMemo(() => {
    const subGroupFound = techOperationsSubGroups.find((x) => x.id === subGroupId);
    return ` [${subGroupFound?.name || ""}]`;
  }, [techOperationsSubGroups, subGroupId]);

  return (
    <Table>
      <TableHead onDoubleClick={onDoubleClick}>
        <TableRow>
          <TableCell width={90} align="right">
            ID Агро- работы
          </TableCell>
          <TableCell width={100} align="right">
            Обрабо- тано, га
          </TableCell>
          <TableCell align="center">Подтип</TableCell>
          <TableCell align="center">Поле</TableCell>
          <TableCell width={10}>№</TableCell>
          <TableCell>
            <Grid container alignItems="center" style={{ paddingRight: "20px" }}>
              <Grid item xs={11}>
                Дата
              </Grid>
              <Grid item xs={1}>
                {moreThanLimit && (
                  <Button
                    onClick={toggleCollapsed}
                    title={
                      collapsed
                        ? `Показать ещё ${agroWorks.length - firstLinesToShow} записи(ей)`
                        : `Свернуть до ${firstLinesToShow} строк`
                    }
                  >
                    {collapsed ? (
                      <ArrowDropDownSharp color="primary" fontSize="large" />
                    ) : (
                      <ArrowDropUpSharp color="primary" fontSize="large" />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {tableRows_limitedOrAll.map((techOp) => (
          <AgroWorkTableRow
            key={techOp.id}
            techOpDto={techOp}
            techOperationsSubGroups={techOperationsSubGroups}
          />
        ))}
        <ExpandCollapseTableRow
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          moreRecordsAvailableCount={agroWorks.length - firstLinesToShow}
        >
          <Box>
            <TechOpPredecessorsFilter
              forCurrentSubGroupCount={operationsForSeason.length}
              noPredecessorsCount={noPredecessorsList.length}
              checked={removePredecessors}
              onChange={() => {
                setRemovePredecessors((prev) => !prev);
              }}
            />
          </Box>
        </ExpandCollapseTableRow>
      </TableBody>

      <TableFooter>
        {(collapsed === false || moreThanLimit === false) && (
          <TableRow style={moreThanLimit ? { cursor: "pointer" } : {}}>
            <TableCell colSpan={2} align="right" onClick={toggleCollapsed}>
              <div>{areaProcessed.toFixed(2)} га</div>
              <div>{(fieldMax - areaProcessed).toFixed(2)} га</div>
              {remainderForThisOpNumber ? (
                <div>{remainderForThisOpNumber.toFixed(2)} га</div>
              ) : null}
              <div>{fieldMax.toFixed(2)} га</div>
            </TableCell>
            <TableCell colSpan={4}>
              <Grid container alignItems="center" style={{ paddingRight: "0px" }}>
                <Grid
                  item
                  xs={
                    hasPredecessors
                      ? moreThanLimit
                        ? 12 - 3 - 1
                        : 12 - 3
                      : moreThanLimit
                      ? 12 - 1
                      : 12
                  }
                  onClick={toggleCollapsed}
                >
                  <div>обработано суммарно {subGroupName}</div>
                  <div>доступная площадь {subGroupName}</div>
                  {remainderForThisOpNumber ? (
                    <div>максимальная площадь при редактировании</div>
                  ) : null}
                  <div>общая площадь поля</div>
                </Grid>

                {hasPredecessors && (
                  <Grid item xs={3}>
                    <TechOpPredecessorsFilter
                      forCurrentSubGroupCount={operationsForSeason.length}
                      noPredecessorsCount={noPredecessorsList.length}
                      checked={removePredecessors}
                      onChange={() => {
                        setRemovePredecessors((prev) => !prev);
                      }}
                    />
                  </Grid>
                )}

                {moreThanLimit && (
                  <Grid item xs={1}>
                    <Button
                      title={
                        collapsed
                          ? `Показать ещё ${
                              agroWorks.length - firstLinesToShow
                            } записи(ей)`
                          : `Свернуть до ${firstLinesToShow} строк`
                      }
                      onClick={toggleCollapsed}
                    >
                      {collapsed ? (
                        <ArrowDropDownSharp color="primary" fontSize="medium" />
                      ) : (
                        <ArrowDropUpSharp color="primary" fontSize="medium" />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableFooter>
    </Table>
  );
}

function AgroWorkTableRow({
  techOpDto,
  techOperationsSubGroups,
}: {
  techOpDto: ITechOperationDto;
  techOperationsSubGroups: ITechOperationSubGroupDto[];
}): JSX.Element {
  const { id: techOpId_fromUrl } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // navigation works okay for EDITING:
  // http://localhost:3000/tech-operations/editing/CC9E74FD-9F3D-4FED-BB32-394F52E1167C
  // but UI gets broken when for NEW TechOp I click on this AgroWorks in this table:
  // http://localhost:3000/tech-operations/new
  const isDisabled = techOpId_fromUrl === PATHS.EDIT_NEW_PAGE;

  const subGroupResolved = techOperationsSubGroups.find(
    (x) => x.id === techOpDto.techOperationSubGroupId
  );

  const dateRange =
    techOpDto.startDate !== techOpDto.finishDate
      ? `${techOpDto.startDate}...${techOpDto.finishDate}`
      : `${techOpDto.startDate}`;

  const isRowSelected = techOpDto.id === techOpId_fromUrl;
  const workSize = useMemo(() => {
    return `${techOpDto.fieldSize.toFixed(2)} га`;
  }, [techOpDto.fieldSize]);

  return (
    <TableRow
      key={techOpDto.id}
      onClick={() => {
        if (isRowSelected || isDisabled) {
          return;
        }

        if (isDisabled) {
          return;
        }

        const urlAgroWorkEdit = techOpEditUrl(techOpDto.id);
        navigate(urlAgroWorkEdit, { replace: false });
      }}
      hover
      selected={isRowSelected}
      style={{ cursor: isRowSelected || isDisabled ? "default" : "pointer" }}
      title={`ID агроработы: ${techOpDto.id}`}
    >
      <TableCell align="right">
        <Box title={`ID агроработы: ${techOpDto.id}`}>
          ...{last5digitsGuid(techOpDto.id)}
        </Box>
      </TableCell>
      <TableCell align="right">
        {/* {isPredecessorDto(techOpDto) ? (
          <BoxTooltipMouseover
            title={
              <pre style={{ textAlign: "left" }}>
                {workSize} не отнимаются от доступной площади
                <br />
                т.к были обработаны в техоперации на поле-предшественнике
                <br />
                {farmLandFormatCombined(
                  techOpDto.Real_farmLandName,
                  farmLands.find((x) => x.id === techOpDto.Real_farmLandId)
                )}
              </pre>
            }
          >
            <Box style={{ color: "red" }}>{workSize}</Box>
          </BoxTooltipMouseover>
        ) : ( */}
        <>{workSize}</>
        {/* )} */}
      </TableCell>
      <TableCell>{subGroupResolved?.name}</TableCell>
      <TableCell>
        <FieldFormatted techOpDto={techOpDto} />
      </TableCell>
      <TableCell align="center">{techOpDto.operationNumber}</TableCell>
      <TableCell align="center">{dateRange}</TableCell>
    </TableRow>
  );
}

function ExpandCollapseTableRow({
  collapsed,
  toggleCollapsed,
  moreRecordsAvailableCount,
  children,
}: PropsWithChildren<{
  collapsed: boolean;
  toggleCollapsed: () => void;
  moreRecordsAvailableCount: number;
}>): JSX.Element {
  const recordsInclination = useMemo(
    () =>
      inclination({
        count: moreRecordsAvailableCount,
        definition: {
          zero: "записей", // показать 10 20 30 => "записей"
          one: "запись", // показать 11 21 31 => "запись"
          twentyTwoToTwentyFour: "записи", // показать 22 33 44  => "записи"
          rest: "записей", // показать 6 12 39 55 => "записей"
        },
        feminine: true,
      }),
    [moreRecordsAvailableCount]
  );

  if (moreRecordsAvailableCount <= 0) {
    return <></>;
  }

  if (collapsed === false) {
    return <></>;
  }

  return (
    <TableRow hover style={{ cursor: "pointer" }}>
      <TableCell align="center" onClick={toggleCollapsed}>
        ...
      </TableCell>
      <TableCell align="center" onClick={toggleCollapsed}>
        ...
      </TableCell>
      <TableCell align="center" onClick={toggleCollapsed}>
        ...
      </TableCell>
      <TableCell align="center" sx={{ fontSize: "smaller" }}>
        {children || <>...</>}
      </TableCell>
      <TableCell colSpan={2} align="center">
        <Grid container alignItems="flex-end" style={{ paddingRight: "20px" }}>
          <Grid item xs={11} onClick={toggleCollapsed}>
            {collapsed
              ? `Раскрыть ещё ${moreRecordsAvailableCount} ${recordsInclination} >>`
              : "Свернуть"}
          </Grid>
          <Grid item xs={1}>
            <Button onClick={toggleCollapsed}>
              {collapsed ? (
                <ArrowDropDownSharp color="primary" fontSize="medium" />
              ) : (
                <ArrowDropUpSharp color="primary" fontSize="medium" />
              )}
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
