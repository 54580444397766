import { InputLabel } from "@mui/material";
import { styled } from "@mui/styles";

export const FieldLabel = styled(InputLabel)(() => ({
  position: "relative",
  paddingRight: 25,
  "& + div": {
    marginTop: 0,
  },
}));
