import { IObservationPhoto } from "../../pages/observations/shared/models/observation-photo.model";

export const observationPhotoUrl = (photoData: IObservationPhoto, postfix = ""): string => {
  const filename = photoData.s3Filename;
  const fileParts: string[] = filename.split(".");

  if (fileParts.length < 2) {
    return ""; // TODO: set default image name (when image url doesn't match conditions)
  }

  const extention = (fileParts.pop() as string).toLowerCase();
  const name = fileParts.join(".");
  const filenameWithPostfix = `${name}${postfix}.${extention}`;

  return [process.env.REACT_APP_API_ENDPOINT, "ObservationPhotos/image", filenameWithPostfix].join("/");
};
