/**
 * In some mui elements like <Autocomplete /> id is transferred to directly wrapped element, and in
 * some modifications to other elements. In case of Autocomplete it is label
 * and it will look like <label id="example-label" for="example" />
 *                       <input id="example" />
 * thus, qa can access them and it easy for devs to maintain id's.
 * For other cases to ensure uniqness and to not worry about breaking some tests
 * data-set is gonna be used. QA will access it by [data-test="example"]
 * Also, in elements like <Autocomplete /> data-set is pointing to the top lvl wrapper,
 * thus, we can set three unique identifiers using only one name!
 *              <div class="MuiAutocomplete-root" data-test="example" >
 *                       <label id="example-label" for="example" />
 *                       <input id="example" />
 * resulting in: data-test="example", label id="example-label", input id="example"
 */

export enum TechOpsTestIds {
  TechOpsEditField = "techops_edit__field",
  TechOpsEditOpType = "techops_edit__operation-type",
  TechOpsEditOpNumber = "techops_edit__operation-number",
  TechOpsEditOpSubType = "techops_edit__operation-subtype",
  TechOpsEditTechAssetsSelfPropelledVehicles = "techops_edit__tech-assets__self-propelled-vehicles",
  TechOpsEditTechAssetsTrailedVehicles = "techops_edit__tech-assets__trailed-vehicles",
  TechOpsEditTechAssetsFromAllFarms = "techops_edit__tech-assets__from-all-farms",
  TechOpsEditTechAssetsAllVehicles = "techops_edit__tech-assets__all-vehicles",
  TechOpsEditEmployeesMechanizer = "techops_edit__employees__mechanizer",
  TechOpsEditEmployeesFromAllFarms = "techops_edit__employees__from-all-farms",
  TechOpsEditEmployeesAllEmployees = "techops_edit__employees__all-employees",
  TechOpsEditEmployeesWorkStartTime = "techops_edit__employees__work-info__start-time",
  TechOpsEditEmployeesWorkEndTime = "techops_edit__employees__work-info__end-time",
  TechOpsEditEmployeesWorkInfoSquare = "techops_edit__employees__work-info__square",
  TechOpsEditEmployeesWorkInfoComment = "techops_edit__employees__work-info__comment",
  Empty = "empty",
}
