import { useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { Button, Checkbox, Menu, MenuItem } from "@mui/material";
import { useAppDispatch } from "../../../../../store";
import { toggleVisibleColumn } from "../../../store/asset-usage-records.slice";
import {
  getConfigurableColumns,
  getVisibleColumnIds,
} from "../../../store/asset-usage-records.selector";

export const SettingsButton = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const configurableColumns = useSelector(getConfigurableColumns);
  const visibleColumnIds = useSelector(getVisibleColumnIds);
  const visibleColumnIdsHash = useMemo(
    () => new Set(visibleColumnIds),
    [visibleColumnIds]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  return (
    <>
      <Button startIcon={<SettingsIcon />} onClick={handleClick}>
        НАСТРОЙКИ ТАБЛИЦЫ
      </Button>
      <Menu
        id="asset-usage-records-settings"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {configurableColumns.map(({ id, label }) => {
          return (
            <MenuItem key={id} onClick={() => dispatch(toggleVisibleColumn(id))}>
              <Checkbox
                edge="start"
                checked={visibleColumnIdsHash.has(id)}
                tabIndex={-1}
              />

              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
