import { DefaultTheme } from "@mui/styles/defaultTheme";

import { IconsEnum } from "./types";

const bgSecondaryHighlightColor = {
  backgroundColor: ({ palette }: DefaultTheme) => palette.secondary.main,
};
export const bgHighlighted = (isActive: boolean) =>
  isActive ? bgSecondaryHighlightColor : {};
export const bgPrimaryContrast = {
  backgroundColor: ({ palette }: DefaultTheme) => palette.primary.contrast,
};
export const sideBarLinksStyle: React.CSSProperties = {
  display: "flex",
  // ["flex-direction"]: "column", // RED F12 Console on this line
  flexDirection: "column",
  height: "100%",
};
export const settingsLinkStyle = (icon: IconsEnum) => ({
  marginTop: icon === IconsEnum.SettingsIcon ? "auto" : "",
});
export const colorWhite = {
  color: ({ palette }: DefaultTheme) => palette.primary.contrast,
};
