import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Skeleton, Typography } from "@mui/material";

import { getLoading } from "../../store/farm-lands.selector";
import { getInfoDepartments, getInfoFarms } from "../../../../modules/info-data";
import { FarmLand } from "../../shared/models/farm-land";
import { FarmLandBriefMap } from "../farm-land-brief-map/farm-land-brief-map";

export const FarmLandBrief = ({
  model,
  mapHeight = 200,
}: {
  model: FarmLand | null;
  mapHeight?: number;
}): JSX.Element => {
  const isLoading = useSelector(getLoading);
  const farms = useSelector(getInfoFarms);
  const departments = useSelector(getInfoDepartments);

  const farmName = useMemo(() => {
    if (!model) {
      return "-";
    }
    const farm = farms.find((item) => item.id === model.farmId);
    return farm ? farm.name : "-";
  }, [farms, model]);

  const departmentName = useMemo(() => {
    if (!model) {
      return "-";
    }
    const department = departments.find((item) => item.id === model.departmentId);
    return department ? department.name : "-";
  }, [departments, model]);

  return (
    <Box
      sx={{
        width: 200,
      }}
    >
      {isLoading || model === null ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton height={mapHeight} variant="rectangular" />
        </>
      ) : (
        <>
          <Typography variant={"h5"}>{model.name}</Typography>
          <Typography variant={"h6"}>{model.area} га</Typography>
          <Typography variant={"body2"}>
            Хозяйство: {farmName} ({departmentName})
          </Typography>
          <Box
            sx={{
              height: mapHeight,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: (theme) => theme.palette.grey[500],
            }}
          >
            <FarmLandBriefMap farmLand={model} mapHeight={mapHeight} />
          </Box>
        </>
      )}
    </Box>
  );
};
