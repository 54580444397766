import React, { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import Grid2 from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";
import { TechOperationAssetsGroup } from "../../components/tech-operation-editing-assets-group/tech-operation-editing-assets-group";
import {
  useFetchAssetTypes_forTechOpQuery,
  useFetchTechAssets_forTechOpIdQuery,
} from "../../../../modules/tech-assets/store/techop-assets.rtkq";
import { TECHOPID_NEW_KEYWORD } from "../../../../constant";
import { MateriallyAccountableEmployee } from "../../components/tech-operation-editing-asset/fields/materially-accountable-employee";
import { INVENTORY_ITEMS_FIELDS, TECH_OPS_SUB_GROUP } from "../../shared/constants";
import { useFetchAllStocksQuery } from "src/modules/asset-stocks/store";
import { getFilter } from "src/modules/filter";
import { FilterName } from "src/modules/filter/shared/enums/filter-name";
import { TechOperationEditingAssetsType } from "../../shared/types";
import { useTabs } from "../../components/tech-operation-editing-assets-group/use-tabs";
import { UseControlledFields } from "../../components/tech-operation-editing-assets-group/use-controlled-fields";
import {
  fieldsAndEntityJuxtapositionPipe,
  prepareFieldsForTabs,
} from "../../shared/utils/use-controlled-fields-helpers";

export const TechOperationEditingAssets = memo(
  ({
    techOpId,
    operationNumber,
    techOperationSubGroup,
    isExistingTechOperation,
    disabled,
    cropId,
  }: TechOperationEditingAssetsType): React.JSX.Element => {
    const farmId = useSelector(getFilter(FilterName.FarmId)) || "";
    const { data: allStocks } = useFetchAllStocksQuery(farmId);
    const { data: assetTypes } = useFetchAssetTypes_forTechOpQuery();
    const { data: techOpAssets } = useFetchTechAssets_forTechOpIdQuery(techOpId || "", {
      skip: techOpId === undefined || techOpId === TECHOPID_NEW_KEYWORD,
    });

    const techOpSubGroupMapped = INVENTORY_ITEMS_FIELDS.map(
      (val) =>
        techOperationSubGroup[val] &&
        TECH_OPS_SUB_GROUP(isExistingTechOperation, cropId)[val]
    ).filter(Boolean);

    const entityTypes = techOpSubGroupMapped.map((subGroup) => subGroup.assetEntityType);

    const [tabs, setTabs] = useTabs(entityTypes);
    const [controlledFields, destructAndSetFields] = UseControlledFields();

    useEffect(() => {
      if (!controlledFields.length) {
        return;
      }

      const fieldsJuxtaposition = fieldsAndEntityJuxtapositionPipe(
        controlledFields,
        entityTypes
      );
      const fieldsPreparedForTabs = prepareFieldsForTabs(fieldsJuxtaposition);

      setTabs(fieldsPreparedForTabs);
    }, [controlledFields.length]); // eslint-disable-line

    const columnSize = useMemo(() => {
      const typeCount = INVENTORY_ITEMS_FIELDS.reduce(
        (nums, key) => nums + techOperationSubGroup[key],
        0
      );
      return typeCount % 2 ? 12 : 6;
    }, [techOperationSubGroup]);

    if (isExistingTechOperation && techOpAssets === undefined) {
      // WAITING_UNTIL_fetchAssets_forTechOperationIdAction_COMPLETES
      return (
        <SpinnerCenter
          minHeight={"10vh"}
          msg={`Загружаются ТМЦ для техоперации №${operationNumber}...`}
        />
      );
    }

    if (assetTypes === undefined) {
      // WAITING_UNTIL_fetchAssetTypesAction_COMPLETES
      return (
        <SpinnerCenter minHeight={"10vh"} msg={"Загружается общий справочник ТМЦ..."} />
      );
    }

    return (
      <Grid2 container={true} padding={2} spacing={2}>
        <Grid2 xs={12}>
          <Typography variant={"h6"}>ТМЦ (элемент звена №{operationNumber})</Typography>
        </Grid2>

        {techOpSubGroupMapped?.map((values) => {
          if (!values) {
            return <></>;
          }
          return (
            <Grid2
              key={values.fieldName}
              container={true}
              xs={columnSize}
              alignContent={"start"}
            >
              <MateriallyAccountableEmployee fieldName={values.fieldName} />

              <Grid2 xs={12}>
                <TechOperationAssetsGroup
                  controlledFields={controlledFields}
                  optionsLabel={values.optionsLabel}
                  noOptionsText={values.noOptionsText}
                  assetEntityType={values.assetEntityType}
                  disabled={disabled}
                  cropId={cropId}
                  isExistingTechOperation={isExistingTechOperation}
                  allStocks={allStocks}
                  tabs={tabs}
                  setTabs={setTabs}
                  destructAndSetFields={destructAndSetFields}
                />
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
    );
  }
);

TechOperationEditingAssets.displayName = "TechOperationEditingAssets";
