import { DevTool } from "@hookform/devtools";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

import { getFarmSortedList } from "../../../modules/farms";
import { useAppDispatch } from "../../../store";
import { IUser } from "../../shared/interfaces/user";
import { getUser } from "../../store/auth.selector";
import { updateUser } from "../../store/auth.slice";
import { addNotificationAction } from "../../../modules/notifications";
import { NotificationType } from "../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../modules/notifications/shared/enums/notification-severity";

interface IEditUserForm {
  lastName: string;
  firstName: string;
  middleName: string;
  positionName: string;
  defaultFarmId: string;
  phone: string;
  avatarUrl: string;
}

export const model2form = (model: IUser): IEditUserForm => {
  const { defaultFarmId, lastName, firstName, middleName, positionName, phone, avatarUrl } = model;
  return {
    defaultFarmId: defaultFarmId || "",
    lastName: lastName || "",
    firstName: firstName || "",
    middleName: middleName || "",
    positionName: positionName || "",
    phone: phone || "",
    avatarUrl: avatarUrl || "",
  };
};

export const EditUser = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const farms = useSelector(getFarmSortedList);
  const defaultValues = useMemo<IEditUserForm>(
    () =>
      user
        ? model2form(user)
        : {
            defaultFarmId: "",
            lastName: "",
            firstName: "",
            middleName: "",
            positionName: "",
            phone: "",
            avatarUrl: "",
          },
    [user]
  );
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    watch,
    trigger,
  } = useForm<IEditUserForm>({
    mode: "onChange",
    defaultValues,
  });
  const [editAvatar, setEditAvatar] = useState(false);

  const onSubmit = useCallback(
    async (data: IEditUserForm) => {
      if (!user) {
        return;
      }

      const { defaultFarmId, lastName, firstName, middleName, positionName, phone, avatarUrl } = data;

      try {
        await dispatch(
          updateUser({
            ...user.asDto,
            id: user.id,
            fullName: user.fullName,
            defaultFarmId,
            lastName,
            firstName,
            middleName: middleName !== "" ? middleName : null,
            positionName,
            phone: phone !== "" ? phone : null,
            avatarUrl,
          })
        ).unwrap();

        reset({
          ...data,
          avatarUrl: avatarUrl !== undefined ? avatarUrl : defaultValues.avatarUrl, // ignore avatar changing
        });

        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Success,
            message: "Данные пользователя успешно изменены",
          })
        );
      } catch (e) {
        reset();
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Error,
            message: "Произошла ошибка при изменении данных пользователя",
            error: e,
          })
        );
      }
    },
    [dispatch, user, reset, defaultValues.avatarUrl]
  );

  const avatarUrlWatch = watch("avatarUrl");

  // revalidate old data (if phone has no correct format etc)
  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Grid component={"form"} onSubmit={handleSubmit(onSubmit)} container={true} spacing={2}>
      <DevTool control={control} />
      <Grid item={true} md={2} xs={12}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setEditAvatar((value) => !value)} size="large">
            <Avatar style={{ width: 100, height: 100 }} src={avatarUrlWatch} variant={"circular"} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item={true} md={10} xs={12}>
        {editAvatar && (
          <Controller
            name={"avatarUrl"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                variant="standard"
                {...field}
                fullWidth={true}
                label={"URL аватара"}
                error={!!error}
                helperText={error ? error.message : ""}
              />
            )}
          />
        )}

        {farms.length > 0 ? (
          <Controller
            name={"defaultFarmId"}
            control={control}
            rules={{
              required: "Поле должно быть заполнено",
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="standard" fullWidth={true}>
                <InputLabel id="defaultFarmId-label"> Хозяйство по умолчанию </InputLabel>

                <Select
                  variant="standard"
                  {...field}
                  labelId="defaultFarmId-label"
                  label={"Хозяйство по умолчанию"}
                  error={!!error}
                >
                  {farms.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={true}>{error ? error.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        ) : null}

        <Controller
          name={"positionName"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant="standard"
              {...field}
              fullWidth={true}
              disabled={true}
              label={"Должность"}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />

        <Controller
          name={"phone"}
          control={control}
          rules={{
            pattern: {
              value: /^(\+7-)?\d{3}-\d{3}-\d{2}-\d{2}$/,
              message: "Формат телефона не верен",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputMask mask="+7-999-999-99-99" {...field}>
              {(inputProps) => (
                <TextField
                  variant="standard"
                  {...inputProps}
                  fullWidth={true}
                  label={"Телефон"}
                  error={!!error}
                  type={"tel"}
                  helperText={error ? error.message : " "}
                />
              )}
            </InputMask>
          )}
        />
      </Grid>
      <Grid item={true} md={4} xs={12}>
        <Controller
          name={"lastName"}
          control={control}
          rules={{ required: "Поле должно быть заполнено" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant="standard"
              {...field}
              fullWidth={true}
              label={"Фамилия"}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item={true} md={4} xs={12}>
        <Controller
          name={"firstName"}
          control={control}
          rules={{ required: "Поле должно быть заполнено" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant="standard"
              {...field}
              fullWidth={true}
              label={"Имя"}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item={true} md={4} xs={12}>
        <Controller
          name={"middleName"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant="standard"
              {...field}
              fullWidth={true}
              label={"Отчество"}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Button disabled={!isValid} type={"submit"} color={"primary"} variant={"contained"}>
          Изменить
        </Button>
      </Grid>
    </Grid>
  );
};
