import { Autocomplete, Paper, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TLabel } from "../../../../shared/interface";
import { useAppDispatch } from "../../../../store";
import { getFarmLandNameSearchOptions } from "../../store/map-controls.selector";
import { setSearchFarmLandNameAction } from "../../store/map-controls.slice";
import { setHighlightedFarmLandId } from "../../store/map-page.slice";
import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";

export const FilterCTVName = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const aggregationOptions = useSelector(getFarmLandNameSearchOptions);
  const [isWrongFormat, setIsWrongFormat] = useState(false);

  const handleOnChange = useCallback(
    (_, option: TLabel | null | string) => {
      if (!option) {
        return;
      }

      if (typeof option === "string") {
        return;
      }

      dispatch(setHighlightedFarmLandId(option.id));
    },
    [dispatch]
  );

  const handleOnInputChange = useCallback(
    (e, value) => {
      if (!value) {
        dispatch(setSearchFarmLandNameAction(""));
        setIsWrongFormat(false);
        return;
      }

      const DIGITS_AND_DOTS = /^[\d.]+$/;
      if (!DIGITS_AND_DOTS.test(value)) {
        if (isWrongFormat) {
          return;
        }

        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Warning,
            message: "Некорректный формат названия поля",
          })
        );
        setIsWrongFormat(true);
        return;
      } else {
        setIsWrongFormat(false);
      }

      dispatch(setSearchFarmLandNameAction(value));
    },
    [dispatch, isWrongFormat, setIsWrongFormat]
  );

  useEffect(() => {
    dispatch(setSearchFarmLandNameAction(""));
  }, [dispatch]);

  return (
    <Paper>
      <Autocomplete
        id={"filter-ctv-name"}
        freeSolo={true}
        options={aggregationOptions}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.title)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(x) => x}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        fullWidth={true}
        noOptionsText={"Поля не найдены"}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            error={isWrongFormat}
            label="Название поля"
            variant="outlined"
          />
        )}
      />
    </Paper>
  );
};
