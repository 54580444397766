import { TextField, TextFieldProps } from "@mui/material";
import React, { useCallback } from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import { TechOpsTestIds } from "src/pages/tech-operations/shared/types/tech-operation-data-test";

interface IProps {
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  renderOnChange?: (value: string, onChange: ControllerRenderProps["onChange"]) => void;
  TextFieldProps?: Partial<TextFieldProps>;
  id?: TechOpsTestIds | string;
  title?: string;
}

export const RHFTextField = (props: IProps): JSX.Element => {
  const rhfMethods = useFormContext();

  const renderTextField = useCallback(
    ({
      field,
      fieldState,
    }: {
      field: ControllerRenderProps<FieldValues, string>;
      fieldState: ControllerFieldState;
    }) => (
      <TextField
        id={props.id}
        variant="standard"
        value={field.value !== null && field.value !== undefined ? field.value : ""}
        onChange={(evt) =>
          props.renderOnChange
            ? props.renderOnChange(evt.target.value, field.onChange)
            : field.onChange(evt.target.value)
        }
        fullWidth={true}
        error={!!fieldState.error}
        {...props.TextFieldProps}
        title={props.title}
      />
    ),
    [props]
  );

  return (
    <Controller
      name={props.name}
      control={rhfMethods.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={renderTextField}
    />
  );
};
