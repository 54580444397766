import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { devlog, devwarn } from "../../../../shared/utils/log";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import {
  getCurrentFarmLands,
  getCurrentFarmLandsCacheKey,
} from "../../../fields/store/farm-lands.selector";
import { fetchFarmLandsByFarmId } from "../../../fields/store/farm-lands.slice";
import { useDeduplicator } from "../../components/tech-operation-editing-asset/use-deduplicator";

export type FarmLands = {
  isLoadingFarmLands: boolean;
  loadFarmLands: () => Promise<IFarmLandDto[] | undefined>;
  farmLands: IFarmLandDto[] | undefined;
};

export function useFarmLands({
  cropTypeId,
  requestOnMount = true,
}: {
  cropTypeId?: string;
  requestOnMount?: boolean;
}): FarmLands {
  const dispatch = useAppDispatch();

  const appFilterFarmId =
    useSelector(getFilter(FilterName.FarmId)) ?? "SELECT_FARM_FROM_UPPER_DROPDOWN";
  const appFilterSeasonId =
    useSelector(getFilter(FilterName.SeasonId)) ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN";

  // const [farmLands, setFarmLands] = useState<IFarmLandDto[] | undefined>(); // undefined => NOT_YET_FETCHED
  const storedFarmLands = useSelector(getCurrentFarmLands); // set by fetchFarmLandsByFarmId()
  const storedCachedKey = useSelector(getCurrentFarmLandsCacheKey); // set by fetchFarmLandsByFarmId()

  const { isLoading, deduplicate } = useDeduplicator<IFarmLandDto[]>("useFarmLands");

  const loadFarmLands = useCallback(async (): Promise<IFarmLandDto[] | undefined> => {
    const cachedKey = `${appFilterFarmId}:${appFilterSeasonId}`;
    if (!appFilterFarmId || !appFilterSeasonId) {
      devwarn(
        `FAILED_TO_loadFarmLands(${cachedKey}): FarmId and SeasonId must be selected in upper dropdown`
      );
      return undefined; // NOT_YET_LOADED
    }

    if (cachedKey === storedCachedKey && storedFarmLands !== undefined) {
      return Promise.resolve(storedFarmLands);
    }

    const promise: Promise<IFarmLandDto[] | undefined> = deduplicate({
      dataRequestKey: cachedKey,
      backendRequest: () => {
        // : AsyncThunkPromise<REQUEST, RESPONSE>X
        const thunk = dispatch(
          fetchFarmLandsByFarmId({ farmId: appFilterFarmId, seasonId: appFilterSeasonId })
        );
        const requestPromise: Promise<IFarmLandDto[]> = thunk.unwrap();
        return requestPromise;
      },
      onError: (rejectedValueOrSerializedError) => {
        devwarn(
          `FAILED_TO_fetchFarmLandsByFarmId(${cachedKey}): `,
          rejectedValueOrSerializedError
        );
      },
    });

    return promise;
  }, [
    appFilterFarmId,
    appFilterSeasonId,
    storedCachedKey,
    storedFarmLands,
    deduplicate,
    dispatch,
  ]);

  useEffect(() => {
    if (requestOnMount === false) {
      return;
    }
    // deduplicate().hasResponseUnderKey(dataRequestKey) will resolve
    // loadFarmLands() promise with cachedResponseRef.current
    // if (farmLands !== undefined) {
    //   return;
    // }
    loadFarmLands();
  }, [
    appFilterFarmId,
    appFilterSeasonId,
    storedFarmLands, // from store
    requestOnMount,
    loadFarmLands,
  ]);

  const filteredFarmLands = useMemo(() => {
    if (!storedFarmLands) {
      return storedFarmLands;
    }

    if (cropTypeId) {
      const filtered = storedFarmLands.filter((x) => x.cropTypeId === cropTypeId);
      if (filtered.length === 0) {
        devlog(
          `NO_FARMLANDS_FOUND with cropTypeId[${cropTypeId}]; returning all available`
        );
        // ЭТОТ_КОММЕНТ_МНЕ_НУЖЕН return storedFarmLands;
      }
      return filtered;
    }
    return storedFarmLands;
  }, [storedFarmLands, cropTypeId]);

  return {
    // re-render-free => console error is gone
    // isLoadingFarmLands: isLoadingRef.current,
    isLoadingFarmLands: isLoading,
    loadFarmLands,
    farmLands: filteredFarmLands,
  };
}
