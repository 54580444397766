import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useCallback } from "react";

import { CATALOG_SECTIONS_INFO } from "./catalog-tab-layout-constants";
import { CatalogTabLayoutProps } from "./catalog-tab-layout-types";
import { catalogTabpanelStyles } from "./catalog-tab-layout.style";

export const CatalogTabLayout = (props: CatalogTabLayoutProps): JSX.Element | null => {
  const { children, type, onSearch } = props;
  const classes = catalogTabpanelStyles();

  const [searchText, setSearchText] = React.useState<string>("");

  const catalogTabInfo = CATALOG_SECTIONS_INFO[type];

  const onSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onSearchChange = useCallback(
    (text: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(text.target.value);
      if (onSearch) {
        onSearch(text.target.value);
      }
    },
    [onSearch]
  );

  const onSearchClear = useCallback(() => {
    setSearchText("");
    if (onSearch) {
      onSearch("");
    }
  }, [setSearchText, onSearch]);

  return (
    <div className={classes.root}>
      <div className={classes.manualHeader}>{catalogTabInfo.name}</div>
      <form className={classes.inputSearch} noValidate autoComplete="off" onSubmit={onSubmit}>
        {onSearch && (
          <TextField
            id="catalog-tab-layout-search-field"
            variant="outlined"
            inputProps={{ "aria-label": "Полнотекстовый поиск внутри каталога" }}
            label="Найти"
            onChange={onSearchChange}
            size="small"
            value={searchText}
            style={{
              minWidth: "420px",
            }}
            InputProps={{
              endAdornment: [
                searchText.length > 0 && (
                  <CloseIcon key="closeIcon" onClick={onSearchClear} style={{ cursor: "pointer" }} />
                ),
                <SearchIcon key="searchIcon" />,
              ],
            }}
          />
        )}
      </form>

      <div className={classes.manualBody}>{children}</div>
    </div>
  );
};
