import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IDiseasesState } from "../shared";
import { MODULE_NAME } from "./diseases.slice";
import { RootState } from "../../../store-types"

export const getDiseasesState = (rootState: RootState): IDiseasesState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.isLoading);
export const getList = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getDiseasesState, (state: IDiseasesState) => state.searchText);
