import React, { useCallback } from "react";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../../../../store";
import { toggleIsFilterOpen } from "../../../store/asset-usage-records.slice";

export const ToggleFilterButton = (): React.JSX.Element => {
  const dispatch = useAppDispatch();

  const toggleFilterHandler = useCallback(() => {
    dispatch(toggleIsFilterOpen());
  }, [dispatch]);

  return (
    <Button startIcon={<FilterListIcon />} onClick={toggleFilterHandler}>
      ФИЛЬТРЫ
    </Button>
  );
};
