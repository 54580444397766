import { useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { TechOpEditingAssetsTabs } from "./tech-operation-editing-assets-tabs";
import { TechOpEditingAssetsButtons } from "./tech-operation-editing-assets-buttons";
import { TechOpEditingAssetsTabsBoxType } from "../../shared/types";

export const TechOpEditingAssetsTabsBox = ({
  controlledFields,
  destructAndSetFields,
  tabs,
  setTabs,
  assetEntityType,
  disabled,
}: TechOpEditingAssetsTabsBoxType) => {
  useEffect(() => {
    // TODO: add new item is useTabs instead of here
    if (!controlledFields?.[assetEntityType]?.length) {
      destructAndSetFields(assetEntityType, "add", tabs[assetEntityType] || 0);
    }
  }, []); //eslint-disable-line

  return (
    <Grid2
      container={true}
      xs={12}
      sx={{ alignItems: "center", borderBottom: 1, borderColor: "divider" }}
    >
      <TechOpEditingAssetsTabs
        tabs={tabs}
        setTabs={setTabs}
        controlledFields={controlledFields}
        assetEntityType={assetEntityType}
      />
      <TechOpEditingAssetsButtons
        destructAndSetFields={destructAndSetFields}
        tabs={tabs}
        setTabs={setTabs}
        controlledFields={controlledFields}
        disabled={disabled}
        assetEntityType={assetEntityType}
      />
    </Grid2>
  );
};

TechOpEditingAssetsTabsBox.displayName = "TechOpEditingAssetsTabsBox";
