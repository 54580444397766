import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";

export function farmLandFormatCombined(
  unresolved?: string,
  resolved?: IFarmLandDto
): string | undefined {
  if (!resolved) {
    return unresolved;
  }
  return farmLandFormat(resolved);
}

export function farmLandFormat(
  resolved?: IFarmLandDto,
  indicateDeleted?: boolean
): string {
  if (!resolved) {
    return "";
  }

  let ret = `${resolved.name} - ${resolved.cropShortName}: ${resolved.area} га`;
  if (indicateDeleted && resolved.isDeleted === true) {
    ret += " (предшественник)";
  }
  return ret;
}

export function farmLandFormatShort(resolved?: IFarmLandDto): string {
  if (!resolved) {
    return "";
  }

  const ret = `${resolved.cropShortName}: ${resolved.area} га`;
  return ret;
}
