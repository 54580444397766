import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { AutocompleteRenderOptionState } from "@mui/material/Autocomplete/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import lodashGet from "lodash/get";
import React, { useCallback, useMemo } from "react";
import {
  Controller,
  ControllerRenderProps,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

import { TechOpsTestIds } from "src/pages/tech-operations/shared/types/tech-operation-data-test";

// TODO: type with @mui/lab/AutocompleteProps
interface IAutocompleteProps<T> {
  options: T[];
  disabled?: boolean;
  getOptionLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  isOptionEqualToValue?: (option: T, val: T) => boolean;
  noOptionsText?: string;
}

interface IProps<T> {
  name: string;
  AutocompleteProps: IAutocompleteProps<T>;
  dataTest?: TechOpsTestIds;
  defaultValue?: string;
  rules?: RegisterOptions;
  renderValue?: (val: string) => T | null;
  renderOnChange?: (val: T | null, onChange: ControllerRenderProps["onChange"]) => void;
  TextFieldProps?: Partial<TextFieldProps>;
  style?: React.CSSProperties;
}

// eslint-disable-next-line
export const RHFAutocomplete = <T extends Record<string, any>>(
  props: IProps<T>
): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, props.name)),
    [props, rhfMethods.formState.errors]
  );

  const getOptionLabel = useCallback(
    (option: T) => option.name || option.title || "",
    []
  );
  const isOptionEqualToValue = useCallback((option, val) => option.id === val.id, []);

  const renderInput = useCallback(
    (params) => (
      <TextField variant="standard" error={error} {...props.TextFieldProps} {...params} />
    ),
    [error, props]
  );

  const filterOptions = createFilterOptions<T>({
    limit: 100,
  });

  const renderAutocomplete = useCallback(
    ({ field: { value, onChange } }) => (
      <Autocomplete<T>
        fullWidth={true}
        id={props.dataTest}
        data-test={props.dataTest}
        value={props.renderValue ? props.renderValue(value) : value}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(_, val) =>
          !props.renderOnChange ? onChange(val?.id) : props.renderOnChange(val, onChange)
        }
        renderInput={renderInput}
        filterOptions={filterOptions}
        style={props.style}
        {...props.AutocompleteProps}
      />
    ),
    [filterOptions, getOptionLabel, isOptionEqualToValue, props, renderInput]
  );

  return (
    <Controller
      name={props.name}
      control={rhfMethods.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={renderAutocomplete}
    />
  );
};
