import { DevTool } from "@hookform/devtools";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { getLoadingUserDto } from "../../../../authentication/store/auth.selector";
import { login } from "../../../../authentication/store/auth.slice";
import { LoginFormDataTypes } from "../../../../common-types";
import { PATHS } from "../../../../constant";
import { useAppDispatch } from "../../../../store";
import AvatarImg from "../../img/img_crop.jpg";
import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";
import { useAutoHide } from "../../../../shared/hooks/autohide";

const FormContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  "& > *": {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  "& input": {
    padding: theme.spacing(1),
  },
}));

const RestorePasswordLink = styled(NavLink)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const hideErrorAfterMillis = 6000;

export const LoginForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getLoadingUserDto);
  const navigate = useNavigate();
  const { autoHideMessage, autoHide } = useAutoHide();
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    handleSubmit,
    // formState: {errors},
    control,
    formState: { isValid, isDirty },
  } = useForm<LoginFormDataTypes>({
    defaultValues: { email: "", password: "" },
    mode: "onChange",
  });

  const submitHandler = useCallback(
    async (data: LoginFormDataTypes) => {
      const { email, password } = data;
      try {
        await dispatch(login({ email, password })).unwrap();
        navigate(PATHS.MAIN_PAGE);
      } catch (e) {
        const msg = "Неверный логин или пароль. Повторите попытку ещё раз.";
        dispatch(
          addNotificationAction({
            type: NotificationType.Alert,
            severity: NotificationSeverity.Error,
            message: msg,
            error: e,
          })
        );
        autoHide(msg, true, hideErrorAfterMillis);
      }
    },
    [dispatch, navigate, autoHide]
  );

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    [setShowPassword]
  );
  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    []
  );

  return (
    <FormContainer component={"form"} onSubmit={handleSubmit(submitHandler)}>
      <DevTool control={control} />
      <Avatar src={AvatarImg} style={{ height: "105px", width: "105px" }} />

      <Typography variant="h5">EkoCrop</Typography>

      <Typography variant="body2">
        Введите логин и пароль выданные Вам администратором
      </Typography>

      <Controller
        name={"email"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            variant="standard"
            {...field}
            required={true}
            fullWidth={true}
            type={"email"}
            error={!!error}
            label={"Логин (e-mail)"}
            helperText={error ? error.message : ""}
          />
        )}
      />

      <Controller
        name={"password"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl variant="standard" fullWidth={true} error={!!error}>
            <InputLabel required htmlFor="password">
              Пароль
            </InputLabel>
            <Input
              id={"password"}
              required={true}
              type={showPassword ? "text" : "password"}
              {...field}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Показать пароль"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility color="primary" /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{error ? error.message : ""}</FormHelperText>
          </FormControl>
        )}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={(isDirty && !isValid) || isLoading}
        fullWidth={true}
      >
        {isLoading ? <CircularProgress size={20} /> : "Войти"}
      </Button>

      <RestorePasswordLink to={"restore-password"}>Забыли пароль?</RestorePasswordLink>

      {autoHideMessage && (
        <Box component="span" mx={4}>
          {autoHideMessage}
        </Box>
      )}
    </FormContainer>
  );
};
