import React, { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { Controller, useFormContext } from "react-hook-form";
import { IFilterFormData } from "../../../shared/interfaces/filter-form-data";
import { TLabel } from "../../../../../shared/interface";
import { useSelector } from "react-redux";
import { useMateriallyAccountableEmployeeListQuery } from "../../../../../modules/employees/store";
import { getFilter } from "../../../../../modules/filter";
import { FilterName } from "../../../../../modules/filter/shared/enums/filter-name";

export const MateriallyAccountableEmployeesField = (): React.JSX.Element => {
  const { control } = useFormContext<IFilterFormData>();
  const farmId = useSelector(getFilter(FilterName.FarmId)) || undefined;

  const { data: molList, isLoading } = useMateriallyAccountableEmployeeListQuery({
    farmId,
  });
  const options: TLabel[] = useMemo(
    () =>
      molList
        ? molList.map(({ id, name }) => ({
            id,
            title: name,
          }))
        : [],
    [molList]
  );

  return (
    <Controller
      name={"materiallyAccountableEmployees"}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <Autocomplete
          {...fieldProps}
          multiple={true}
          loading={isLoading}
          loadingText={"Загружаем список МОЛ"}
          fullWidth={true}
          value={options.filter((option) => value.includes(option.id))}
          onChange={(_, data) => onChange(data.map((opt) => opt.id))}
          getOptionLabel={(item: TLabel) => item.title}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          options={options}
          filterOptions={createFilterOptions<TLabel>({ limit: 10 })}
          noOptionsText={"Материально ответственных лиц не найдено"}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={!!error}
              label="МОЛ"
              placeholder="выберите МОЛ"
              helperText={error ? error.message : ""}
            />
          )}
        />
      )}
    />
  );
};
