import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { PATHS } from "../../../../constant";
import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";
import { TechOperationsEditingPage } from "../tech-operations-editing/tech-operations-editing";

const TechOperationsList = lazy(
  () => import("../tech-operations-list/tech-operations-list")
);

export const TechOperations = (): JSX.Element => {
  // https://stackoverflow.com/questions/52326686/react-router-v4-rendering-component-twice
  return (
    <Suspense fallback={<SpinnerCenter msg={"Загружаются тех.операции..."} />}>
      <Routes>
        <Route index={true} element={<TechOperationsList />} />
        <Route path={`${PATHS.EDIT_PAGE}/:id`} element={<TechOperationsEditingPage />} />
        {/* <Route path="*" element={<Navigate to={"."} replace={true} />} /> */}
      </Routes>
    </Suspense>
  );
};
