import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AppUserModel, IAppUsersState, appUsersService } from "../shared";

const initialState: IAppUsersState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};

export const MODULE_NAME = "appUsers";
export const appUsersSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<AppUserModel[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAppUsersAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchAppUsersAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchAppUsersAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const appUsersReducer = appUsersSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } = appUsersSlice.actions;

export const fetchAppUsersAction = createAsyncThunk(`${MODULE_NAME}/fetchAppUsers`, async (params, { dispatch }) => {
  const dtos = await appUsersService.list({});
  const models = dtos.map((dto) => {
    const model = new AppUserModel(dto.id);
    model.updateFromDto(dto);
    return model;
  });
  dispatch(setListAction(models));
});

export const deleteAppUsersAction = createAsyncThunk<void, string[]>(
  `${MODULE_NAME}/deleteAppUsersAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => appUsersService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
