import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";

import { AUTOCOMPLETE_OFF } from "../shared/constants";
import { IControlledPasswordTextField } from "../shared/interfaces/auth-form";

export const ControlledPasswordTextField = ({
  control,
  name,
  label,
  rules,
}: IControlledPasswordTextField): JSX.Element => {
  return (
    <Grid item={true} xs={12}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            variant="standard"
            inputProps={AUTOCOMPLETE_OFF}
            type={"password"}
            fullWidth={true}
            label={label}
            error={!!error}
            helperText={error ? error.message : ""}
          />
        )}
      />
    </Grid>
  );
};
