import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Grid2 from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";

import { useAppDispatch } from "../../../../store";
import { HUMAN_DATE_FORMAT } from "../../../../constant";
import {
  last5digitsGuid,
  techOpEditUrl,
} from "../../../../shared/utils/format-techop-id";
import { getDetails } from "../../store/asset-usage-records.selector";
import { setDetails } from "../../store/asset-usage-records.slice";
import { SectionDivider } from "../../components/section-divider/section-divider";
import { deverror } from "../../../../shared/utils/log";

const RowTitle = styled(Typography)({ fontWeight: "bold" });

export const AssetUsageRecordsPopup = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const model = useSelector(getDetails);

  const onEditHandler = useCallback(() => {
    if (!model) {
      deverror(`CANT_EDIT_MODEL=null, returning`);
      return;
    }

    const editUrl = techOpEditUrl(model.techOperationId); // model.techOperationId is lowerCase here
    navigate(editUrl);
  }, [model, navigate]);

  const closeHandler = useCallback(() => {
    dispatch(setDetails(null));
  }, [dispatch]);

  if (!model) {
    return <></>;
  }

  const techOpIdLast5digits = last5digitsGuid(model.techOperationId); // model.techOperationId is lowerCase here

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={closeHandler}>
      <DialogTitle sx={{ px: 2, py: 0 }}>
        <Grid container style={{ alignItems: "center" }}>
          <Grid sx={{ flexGrow: 1, justifyContent: "start", display: "flex" }}>
            Агроработа № {techOpIdLast5digits}
          </Grid>
          <Grid>
            <IconButton onClick={closeHandler} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers={true} sx={{ p: 1 }}>
        <Grid container={true} padding={2} spacing={1}>
          <Grid xs={12}>
            <SectionDivider>Основные параметры техоперации</SectionDivider>
          </Grid>

          <Grid2 xs={2}>
            <RowTitle>Номер поля</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{model.farmLandName}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Номер операции</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{techOpIdLast5digits}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Дата начала</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{format(new Date(model.dateStart), HUMAN_DATE_FORMAT)}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Дата завершения</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{format(new Date(model.dateFinish), HUMAN_DATE_FORMAT)}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Культура</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{model.cropTypeName}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Завершённая площадь</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{model.fieldSize}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Тип техоперации</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{model.techOperationGroupName}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Подтип техоперации</RowTitle>
          </Grid2>
          <Grid2 xs={4}>{model.techOperationSubGroupName}</Grid2>
        </Grid>

        <Grid container={true} padding={2} spacing={1}>
          <Grid xs={12}>
            <SectionDivider>Техника</SectionDivider>
          </Grid>

          <Grid2 xs={2}>
            <RowTitle>Механизатор:</RowTitle>
          </Grid2>
          <Grid2 xs={10}>{model.employeeName}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Самоходная техника:</RowTitle>
          </Grid2>
          <Grid2 xs={10}>{model.techAssetName}</Grid2>

          <Grid2 xs={2}>
            <RowTitle>Прицепная техника:</RowTitle>
          </Grid2>
          <Grid2 xs={10}>{model.trailerAssetName}</Grid2>
        </Grid>

        <Grid container={true} padding={2} spacing={1}>
          <Grid xs={12}>
            <SectionDivider>Используемые ТМЦ</SectionDivider>
          </Grid>

          {model.techOperationAssets.length ? (
            model.techOperationAssets.map((asset) => (
              <Grid2 key={asset.id} xs={6} container={true}>
                <Grid2 xs={4}>
                  <RowTitle>Наименование:</RowTitle>
                </Grid2>
                <Grid2 xs={8}>{asset.name}</Grid2>
                <Grid2 xs={4}>
                  <RowTitle>Колличество</RowTitle>
                </Grid2>
                <Grid2 xs={8}>
                  {asset.quantity} {asset.unit}
                </Grid2>
              </Grid2>
            ))
          ) : (
            <Grid xs={12}>Не указаны</Grid>
          )}
        </Grid>

        <Grid container={true} padding={2} spacing={1}>
          <Grid xs={12}>
            <SectionDivider>Комментарий</SectionDivider>
          </Grid>

          <Grid2 xs={2}>
            <RowTitle>Комментарий:</RowTitle>
          </Grid2>
          <Grid2 xs={10}>
            {model.comment?.length > 0 ? model.comment : "отсутствует"}
          </Grid2>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={onEditHandler}
          sx={{ mr: 3 }}
        >
          РЕДАКТИРОВАТЬ ТЕХОПЕРАЦИЮ
        </Button>
        <Button variant="contained" size="small" color="primary" onClick={closeHandler}>
          ОТМЕНА
        </Button>
      </DialogActions>
    </Dialog>
  );
};
