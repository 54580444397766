import { Delete as DeleteIcon, GetApp as GetAppIcon } from "@mui/icons-material";
import { Box, LinearProgress } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import L from "leaflet";
import "leaflet.offline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useMap } from "react-leaflet";

import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { useAppDispatch } from "../../../../store";
import { MuiTheme } from "../../../../styles/mui-theme";
import { updateControlTitles } from "../../shared/utils/update-save-control-titles";

export const OfflineMapLayer = (): JSX.Element => {
  const map = useMap();
  const dispatch = useAppDispatch();
  const [progress, setProgress] = useState<[number, number]>([0, 0]);

  // without ThemeProvider styles of app will be reset to default (some bug)
  const saveText = useMemo(
    () =>
      renderToStaticMarkup(
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MuiTheme}>
            <GetAppIcon fontSize={"small"} style={{ verticalAlign: "middle" }} />
          </ThemeProvider>
        </StyledEngineProvider>
      ),
    []
  );
  const rmText = useMemo(
    () =>
      renderToStaticMarkup(
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MuiTheme}>
            <DeleteIcon
              fontSize={"small"}
              style={{
                verticalAlign: "middle",
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      ),
    []
  );
  const zoomLevels = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], []);

  const onSaveStart = useCallback((e) => {
    setProgress(([innerProgress, total]) => {
      return [innerProgress, total + e._tilesforSave.length];
    });
  }, []);

  const onSaveEnd = useCallback((_) => {
    setProgress(([innerProgress, total]) => {
      return innerProgress + 1 >= total ? [0, 0] : [innerProgress + 1, total];
    });
  }, []);

  const confirmRemoval = useCallback(
    (e, successCallback) => {
      dispatch(
        addNotificationAction({
          type: NotificationType.Confirm,
          title: "Подтверждение действия",
          message: "Будут удалены все сохраненные карты на всех слоях. Вы действительно хотите это сделать?",
          cb: successCallback,
        })
      );
    },
    [dispatch]
  );

  const onTilesRemoved = useCallback(() => {
    dispatch(
      addNotificationAction({
        type: NotificationType.Snack,
        severity: NotificationSeverity.Success,
        message: "Все сохраненные участки карт были удалены",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!map) {
      return;
    }

    // OpenStreetMap
    const osmLayer = L.tileLayer.offline("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      minZoom: 6,
    });
    osmLayer.on("savestart", onSaveStart);
    osmLayer.on("savetileend", onSaveEnd);
    osmLayer.on("tilesremoved", onTilesRemoved);
    osmLayer.addTo(map);
    const osmSaveControl = L.control.savetiles(osmLayer, { zoomlevels: zoomLevels, saveText, rmText, confirmRemoval });
    updateControlTitles(osmSaveControl);
    osmSaveControl.addTo(map);

    // Arcgis
    const cosmoLayer = L.tileLayer.offline(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      {
        attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA",
        minZoom: 6,
      }
    );
    cosmoLayer.on("savestart", onSaveStart);
    cosmoLayer.on("savetileend", onSaveEnd);
    cosmoLayer.on("tilesremoved", onTilesRemoved);
    const cosmoSaveControl = L.control.savetiles(cosmoLayer, {
      zoomlevels: zoomLevels,
      saveText,
      rmText,
      confirmRemoval,
    });

    L.control.layers({ OpenStreetMap: osmLayer, Космоснимок: cosmoLayer }).addTo(map);

    // switching tile layers
    map.on("baselayerchange", ({ layer }) => {
      if (!layer) {
        return;
      }

      let oldControl;
      let newControl;
      switch (layer) {
        case osmLayer:
          oldControl = cosmoSaveControl;
          newControl = osmSaveControl;
          break;

        case cosmoLayer:
          oldControl = osmSaveControl;
          newControl = cosmoSaveControl;
          break;

        default:
          throw new Error("Map layer hasn't been detected");
      }

      map.removeControl(oldControl);
      map.addControl(newControl);
      updateControlTitles(newControl);
    });
  }, [map, onSaveStart, onSaveEnd, confirmRemoval, onTilesRemoved, rmText, saveText, zoomLevels]);

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 99999,
        width: "100%",
        bottom: 0,
      }}
    >
      {progress[1] > 0 && (
        <>
          <LinearProgress
            sx={{
              height: 20,
            }}
            variant={"determinate"}
            value={(progress[0] / progress[1]) * 100}
          />
          <Box
            sx={(theme) => ({
              position: "absolute",
              zIndex: 99999,
              width: "100%",
              bottom: 0,
              textAlign: "center",
              fontWeight: "bold",
              color: theme.palette.primary.contrastText,
            })}
          >
            Загружаем выбранную область ({Math.round((progress[0] / progress[1]) * 100)}%)
          </Box>
        </>
      )}
    </Box>
  );
};
