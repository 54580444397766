import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";
import { useAppDispatch } from "../../store";
import { FilterName } from "../filter/shared/enums/filter-name";
import { getFilter } from "../filter/store/filters.selector";
import { getCurrentFarmLands_nonDeleted } from "./current-farm-lands.selector";
import { fetchFarmLands_forFarmId_includeDeleted } from "./current-farm-lands.slice";

// this crazy hook is trying to prevent duplicate network requests to /api/FarmLands
// now moved to rtkq-based implementation current-farm-lands.rtkq.ts
export function useCurrentFarmLands(): CurrrentFarmLands {
  const dispatch = useAppDispatch();
  const farmLands = useSelector(getCurrentFarmLands_nonDeleted);

  const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));
  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));

  // const [farmLands, setFarmLands] = useState<IFarmLandDto[] | undefined>(); // undefined => NOT_YET_FETCHED

  // const [isLoadingFarmLands, setLoadingFarmLands] = useState(false);

  const farmLandsLoadedKey = useRef<string>();
  // eslint-disable-next-line
  const farmLandsPromise = useRef<any>(null); // don't use <ReturnType>
  // I need farmLands earlier than setFarmLands() invokes rerender
  // farmLandsPromise is non-null while fetching => can do await
  // if farmLandsPromise==null, farmLandsRef contains sorted list for farmLandsLoadedKey
  const farmLandsRef = useRef<IFarmLandDto[] | undefined>();

  const loadFarmLands = useCallback(async (): Promise<IFarmLandDto[] | undefined> => {
    if (!appFilterFarmId || !appFilterSeasonId) {
      return;
    }

    const cachedKey = `${appFilterFarmId}:${appFilterSeasonId}`;
    if (farmLandsLoadedKey.current === cachedKey) {
      // I need farmLands earlier than setFarmLands() invokes rerender
      if (farmLandsPromise.current !== null) {
        // happens when loadFarmLands() was invoked for 2nd time
        // while previous network request is still running
        await farmLandsPromise.current;
      }
      // once fetched, subsequential loadFarmLands() will return cached farms list
      // I return ref value because such second call happens
      // BEFORE farmLands state variable gets filled as a result of setFarmLands()
      return farmLandsRef.current;
    }
    farmLandsLoadedKey.current = cachedKey;

    // setLoadingFarmLands(true);

    const promise = dispatch(
      fetchFarmLands_forFarmId_includeDeleted({
        farmId: appFilterFarmId.toString(),
        excludeGeometry: true,
        filter: {
          where: {
            seasonId: appFilterSeasonId,
            farmId: appFilterFarmId.toString(),
            // isDeleted: false,
            // isDeleted: true,
            isDeleted: { neq: null },
          },
        },
      })
    );

    // await farmLandsPromise.current, when invoked during previous network request
    farmLandsPromise.current = promise;

    promise
      .unwrap()
      .then((list) => {
        // list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));

        // I need farmLandsRef.current earlier than setFarmLands() invokes rerender
        farmLandsRef.current = list;
        // setFarmLands(list);

        // TODO: instead 2 lines above, test immediate state change below:
        // setFarmLands(() => list);

        return list;
      })
      .finally(() => {
        // setLoadingFarmLands(false); // triggers <CircularProgress /> re-render
        farmLandsPromise.current = null;
      });
  }, [
    appFilterFarmId,
    appFilterSeasonId,
    dispatch,
    // setLoadingFarmLands,
    farmLandsPromise,
    farmLandsRef,
  ]);

  useEffect(() => {
    loadFarmLands();
  }, [appFilterFarmId, appFilterSeasonId, loadFarmLands]);

  return {
    farmLands,
    farmLandsRef,
    farmLandsLoadedKey,
    loadFarmLands,
    farmLands_emptyWhileFetching: farmLands || [],
  };
}

export type CurrrentFarmLands = {
  farmLands: IFarmLandDto[] | undefined;
  farmLandsRef: React.MutableRefObject<IFarmLandDto[] | undefined>;
  farmLandsLoadedKey: React.MutableRefObject<string | undefined>;
  loadFarmLands: () => Promise<IFarmLandDto[] | undefined>;
  farmLands_emptyWhileFetching: IFarmLandDto[];
};
