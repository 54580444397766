import { TOKEN_STORED_KEY } from "../../authentication/store/auth.slice";
import { stringify } from "qs";

export function toQueryString(
  searchParams: Record<string, unknown> = {},
  addAccessToken = true
): string {
  if (addAccessToken) {
    const access_token = localStorage.getItem(TOKEN_STORED_KEY);
    return stringify({
      access_token,
      ...searchParams,
    });
  }
  return stringify(searchParams);
}
