import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import Grid2 from "@mui/material/Unstable_Grid2";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useHideAssetsWriteOff } from "src/hooks/use-hide-asserts-writeoff";

import { useMateriallyAccountableEmployeeListQuery } from "../../../../../modules/employees/store";
import { ITechOperationFormData } from "../../../shared/interfaces/tech-operation-form-data";

type maeKeys = "seedsMAE" | "fertilisersMAE" | "chemicalsMAE";

interface IMAELabel {
  id: string;
  name: string;
}

export const MateriallyAccountableEmployee = ({
  fieldName,
}: {
  fieldName: maeKeys;
}): React.JSX.Element => {
  const { control, watch, formState } = useFormContext<ITechOperationFormData>();
  const MAEDefValue = (formState?.defaultValues?.[fieldName] as IMAELabel) ?? null;
  const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();
  const farmId = watch("farmId");

  const { data: molList, isLoading } = useMateriallyAccountableEmployeeListQuery({
    farmId,
  });

  const options: IMAELabel[] = useMemo(
    () =>
      molList
        ? molList.map(({ id, name }) => ({
            id,
            name,
          }))
        : [],
    [molList]
  );
  const filterOptions = createFilterOptions<IMAELabel>({ limit: 100 });
  const inputLabel = useMemo(() => `МОЛ (${molList?.length || "-"})`, [molList?.length]);
  const findOptionById = (value: IMAELabel | null): IMAELabel | null => {
    return options.find((option) => option.id === value?.id) || null;
  };

  if (!isAssetUsageRecordsEnabled || !fieldName) {
    return <></>;
  }

  return (
    <Grid2 xs={12}>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required: "Поле должно быть заполнено" }}
        render={({
          field: { onChange, value, ...fieldProps },
          fieldState: { error },
        }) => {
          return (
            <Autocomplete<IMAELabel>
              {...fieldProps}
              loading={isLoading}
              loadingText={"Загружаем список МОЛ"}
              fullWidth={true}
              value={findOptionById(value) || MAEDefValue}
              onChange={(_, option) => onChange(option)}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, item) => option.id === item.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={!!error}
                  required={true}
                  label={inputLabel}
                  helperText={error ? error.message : " "}
                />
              )}
              filterOptions={filterOptions}
              options={options}
              noOptionsText={"Нет доступных МОЛ"}
            />
          );
        }}
      />
    </Grid2>
  );
};
