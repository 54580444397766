import { useCallback, useEffect, useMemo, useState } from "react";

// import { devlog } from "../../../../shared/utils/log";
import { ITechOperationDto } from "../../shared/dtos/tech-operation.dto";
import { IFarmLandDto } from "../../../../pages/fields/shared/dtos/farm-land.dto";
import { round } from "lodash";
import { buildTooltip } from "../../shared/utils/build-tooltip";
import { ITechOperationSubGroupDto } from "../../shared/dtos/tech-operation-sub-group.dto";

export type AreaHistory = {
  agroWorksForOpNoForSeason: ITechOperationDto[] | undefined;
  onlyForCurrentSubGroup: ITechOperationDto[] | undefined;
  areaProcessedHistory: Map<string, string>;
  fieldSizeAvailable: number;
  remainderForThisOpNumberAndSubGroup: number;
  clearAreaHistory: () => void;
};

export type AreaHistoryInput = {
  isLoadingAreaHistory: boolean;
  fetchedAreaHistory_forOpNo: ITechOperationDto[] | undefined;
  seasonId: string;
  farmLandFromTechOp: IFarmLandDto | undefined;
  subGroupId?: string;
  editingThisTechOpId?: string;
  fieldMax: number;
  techOperationsSubGroups: ITechOperationSubGroupDto[];
};

export function useAreaHistoryCalculator(input: AreaHistoryInput): AreaHistory {
  const {
    isLoadingAreaHistory,
    fetchedAreaHistory_forOpNo,
    seasonId,
    subGroupId,
    editingThisTechOpId,
    fieldMax,
    techOperationsSubGroups,
  } = input;

  const [areaProcessedHistory, setAreaProcessedHistory] = useState<Map<string, string>>(
    new Map()
  );
  const [fieldSizeAvailable, setFieldSizeAvailable] = useState(0);

  const agroWorksForOpNoForSeason = useMemo(
    () => fetchedAreaHistory_forOpNo?.filter((x) => x.seasonId === seasonId),
    [fetchedAreaHistory_forOpNo, seasonId]
  );

  // 3612: sum area for agroworks only with current techOperationSubGroupId
  const onlyForCurrentSubGroup = useMemo(
    () =>
      subGroupId
        ? agroWorksForOpNoForSeason?.filter(
            (x) => x.techOperationSubGroupId === subGroupId
          )
        : [],
    [agroWorksForOpNoForSeason, subGroupId]
  );

  const remainderForThisOpNumberAndSubGroup = useMemo(() => {
    // if (subGroupId) {
    const forSubGroup_orAll = onlyForCurrentSubGroup || agroWorksForOpNoForSeason || [];

    let ret: number | undefined = undefined;
    if (editingThisTechOpId) {
      // isNew = false here
      const exceptEditingOpNo = forSubGroup_orAll.reduce(
        (acc, op) => (op.id === editingThisTechOpId ? acc : acc + op.fieldSize),
        0
      );
      ret = fieldMax - exceptEditingOpNo;
    } else {
      // isNew = true here
      const areaProcessedByAllTechOpsForOpNo = forSubGroup_orAll.reduce(
        (acc, op) => acc + op.fieldSize,
        0
      );
      ret = fieldMax - areaProcessedByAllTechOpsForOpNo;
    }
    ret = round(ret, 2);
    return ret;
  }, [onlyForCurrentSubGroup, agroWorksForOpNoForSeason, editingThisTechOpId, fieldMax]);

  const clearAreaHistory = useCallback(() => {
    // WRONG_RESET_WHEN_ADD_ANOTHER /tech-operations/editing/new
    // ?farmLandId=87531B58-2F19-48B4-9715-BDA584BE70CF
    // &techOperationGroupId=979892A0-D639-11EA-B1CB-3F0E5DDB433F
    // &techOperationSubGroupId=A70EDAF0-D639-11EA-B1CB-3F0E5DDB433F
    // &operationNumber=2
    // setFieldSizeAvailable(fieldMax);
    setAreaProcessedHistory(new Map());
  }, [
    // setFieldSizeAvailable,
    setAreaProcessedHistory,
  ]);

  useEffect(() => {
    if (
      isLoadingAreaHistory ||
      !agroWorksForOpNoForSeason ||
      agroWorksForOpNoForSeason.length === 0
    ) {
      clearAreaHistory();
      return;
    }
    if (!techOperationsSubGroups) {
      return;
    }

    const { newAreaHistory, remainder } = buildTooltip(
      agroWorksForOpNoForSeason,
      techOperationsSubGroups,
      fieldMax,
      remainderForThisOpNumberAndSubGroup
    );

    setAreaProcessedHistory(newAreaHistory);
    setFieldSizeAvailable(remainder);
  }, [
    isLoadingAreaHistory,
    clearAreaHistory,
    agroWorksForOpNoForSeason,
    techOperationsSubGroups,
    fieldMax,
    remainderForThisOpNumberAndSubGroup,
  ]);

  return {
    agroWorksForOpNoForSeason,
    onlyForCurrentSubGroup,
    areaProcessedHistory,
    fieldSizeAvailable,
    remainderForThisOpNumberAndSubGroup,
    clearAreaHistory,
  };
}
