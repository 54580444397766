import { Button, ButtonGroup } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DevTool } from "@hookform/devtools";
import { IFilterFormData } from "../../shared/interfaces/filter-form-data";
import { getFilter } from "../../store/asset-usage-records.selector";
import { stateFilterToFilterFormData } from "../../shared/utils/state-filter-to-filter-form-data";
import { useAppDispatch } from "../../../../store";
import {
  setSelectedAction,
  setFilterAction,
} from "../../store/asset-usage-records.slice";
import { filterFormDataToStateFilter } from "../../shared/utils/filter-form-data-to-state-filter";
import { fetchFarmsAction } from "../../../../modules/farms";
import { fetchAssetTypesAction } from "../../../tech-operations/store/tech-operation-editing.slice";
import { MateriallyAccountableEmployeesField } from "./fields/materially-accountable-employees-field";
import { AssetTypesField } from "./fields/asset-types-field";
import { FromField } from "./fields/from-field";
import { ToField } from "./fields/to-field";

export const AssetUsageRecordsFilter = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const stateFilter = useSelector(getFilter);

  const rhfMethods = useForm<IFilterFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: stateFilterToFilterFormData(stateFilter),
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = rhfMethods;

  const submitHandler = useCallback(
    (data: IFilterFormData) => {
      dispatch(setSelectedAction([]));
      dispatch(setFilterAction(filterFormDataToStateFilter(data)));
    },
    [dispatch]
  );

  // TODO: move it to one time call (info-data), but don't use info-data (fetch responsibility should be moved from it)
  useEffect(() => {
    dispatch(fetchFarmsAction());
    dispatch(fetchAssetTypesAction());
  }, [dispatch]);

  return (
    <FormProvider {...rhfMethods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <DevTool control={control} />
        <Grid2 container={true}>
          <Grid2 xs={12} px={2}>
            <FromField />
          </Grid2>

          <Grid2 xs={12} px={2}>
            <ToField />
          </Grid2>

          <Grid2 xs={12} px={2}>
            <MateriallyAccountableEmployeesField />
          </Grid2>

          <Grid2 xs={12} px={2}>
            <AssetTypesField />
          </Grid2>

          {/* @see https://tracker.yandex.ru/DEVELOPMENT-3184#66d704b19f61d17189c61a80 */}
          {/*<Grid2 xs={12} display={"flex"} justifyContent={"center"} px={2}>*/}
          {/*  <OnlyActiveRecordsField />*/}
          {/*</Grid2>*/}

          <Grid2 xs={12} display={"flex"} justifyContent={"center"} p={2}>
            <ButtonGroup orientation={"vertical"}>
              <Button
                type={"submit"}
                color={"primary"}
                variant={"contained"}
                disabled={!isValid}
              >
                Применить фильтры
              </Button>
              <Button
                type={"reset"}
                color={"primary"}
                variant={"outlined"}
                onClick={() => reset()}
              >
                Сбросить
              </Button>
            </ButtonGroup>
          </Grid2>
        </Grid2>
      </form>
    </FormProvider>
  );
};
