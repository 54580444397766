import { AssetType } from "../enums/asset-type";
import { AssetColor } from "../../../../styles/enums/asset-color";

const matchTable = {
  [AssetType.SEED]: AssetColor.AssetSeed,
  [AssetType.CHEMICAL]: AssetColor.AssetChemical,
  [AssetType.FERTILISER]: AssetColor.AssetFertiliser,
};

export function getThemeColorByAssetType(
  type: AssetType,
  tone: "main" | "light" | "dark" = "main"
): string {
  return [matchTable[type], tone].join(".");
}
