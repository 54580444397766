import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ICropTypesState } from "../shared";
import { CROP_TYPES_MODULE_NAME } from "./crop-types.slice";
import { RootState } from "../../../store-types";
// import { CropType } from "../shared/models/crop-type";

export const getCropTypesState = (rootState: RootState): ICropTypesState =>
  rootState[CROP_TYPES_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getCropTypesState,
  (state: ICropTypesState) => state.isLoading
);

export const getList = createDraftSafeSelector(
  getCropTypesState,
  (state: ICropTypesState) => state.list
);

export const getCropTypeById = createDraftSafeSelector(
  getCropTypesState,
  (state: ICropTypesState) => state.cropTypeById
);

// https://stackoverflow.com/questions/66553130/how-to-use-createselector-with-parameter-and-typescript
// export const _getCropTypeById = createDraftSafeSelector(
//   getList,
//   (state: RootState, itemId: string) => itemId,
//   (cropTypes: CropType[], cropTypeId) => cropTypes.find((x) => x.id === cropTypeId),
//   { memoizeOptions: { maxSize: 100 } }
// );

// export const getCropTypeById = //(cropTypeId: string): Selector<CropType | undefined> =>
//   createDraftSafeSelector(
//     getList,
//     (state: RootState, itemId: string) => itemId,
//     (cropTypes: CropType[], cropTypeId) => cropTypes.find((x) => x.id === cropTypeId),
//     { memoizeOptions: { maxSize: 100 } }
//   );

// export const _getEmployeeById = createDraftSafeSelector(
//   getList,
//   (state: RootState, itemId: string) => itemId,
//   (items, id) => items.find((item) => item.id === id),
//   { memoizeOptions: { maxSize: 100 } }
// );
// export const getEmployeeById = (id: string) => (state: RootState) =>
//   _getEmployeeById(state, id) as Employee | null;
