import { Delete as DeleteIcon, Replay as ReplayIcon } from "@mui/icons-material";
import { Box, Card, CardActions, CardMedia, Fab, Grid } from "@mui/material";
import React from "react";

interface IProps {
  src: string;
  onDelete: () => void;
  isMarkedForDelete?: boolean;
  warning?: string;
}

export const PhotoUploadPreview = (props: IProps): JSX.Element => {
  const { src, onDelete, isMarkedForDelete = false, warning } = props;

  return (
    <Grid item={true} xs={12} sm={6} md={4} lg={2}>
      <Card
        sx={{
          position: "relative",
        }}
      >
        <CardMedia
          image={src}
          sx={{
            height: 200,
            opacity: isMarkedForDelete ? 0.5 : 1,
            border: warning && "1px solid red",
          }}
        />

        <CardActions
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: warning && "red",
          }}
        >
          <Fab onClick={onDelete} size={"medium"}>
            {isMarkedForDelete ? <ReplayIcon /> : <DeleteIcon />}
          </Fab>
        </CardActions>

        {warning && <Box sx={{ color: "red", float: "right", p: 1 }}>{warning}</Box>}
      </Card>
    </Grid>
  );
};
