import { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";

import { ExitToApp } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Divider,
  // Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import { getUser } from "../../authentication/store/auth.selector";
import { logoutSuccess, reloadPage } from "../../authentication/store/auth.slice";
import { useAppDispatch } from "../../store";
import { FilterFarmAndSeason } from "../filter/shared/containers/filter-farm-and-season/filter-farm-and-season";

export const UserPanel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const logoutClickHandler = () => {
    dispatch(logoutSuccess());
    dispatch(reloadPage());
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);

  const handleClose = () => setAnchorElUser(null);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            fontSize: "1.2em",
            color: "#44a6f5",
          }}
        >
          <FilterFarmAndSeason />
        </Box>
      </Box>

      {user && (
        <>
          <Tooltip title="Открыть настройки">
            <IconButton onClick={handleOpenUserMenu} size="large">
              <Avatar src={user.avatarUrl || undefined} alt={user.fullName}>
                <AccountCircle
                  sx={{
                    width: "52px",
                    height: "52px",
                  }}
                />
              </Avatar>
            </IconButton>
          </Tooltip>

          <Menu
            style={{ inset: "42px 0px 0px" }}
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleClose}
            // TransitionComponent={Fade}
          >
            <MenuItem
              sx={{
                "&:focus": { outline: 0 },
              }}
            >
              {/*switch to Stack*/}
              <Grid container={true} direction={"column"}>
                <Grid item={true}>{user.fullName}</Grid>
                <Grid item={true}>
                  <Typography component="div" variant="caption">
                    {user.positionName}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <Divider />
            <MenuItem
              id={"logout=menu-item"}
              selected={false}
              onClick={logoutClickHandler}
              sx={{
                fontSize: "0.95em",
                minWidth: "230px",
              }}
            >
              <ExitToApp sx={{ margin: "0 6px 0 -6px" }} />
              Выход
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};
