import { PATHS } from "src/constant";

export function techOpEditUrl(techOpId: string): string {
  const guidUpperCased = techOpId.toUpperCase();
  const ret = [
    PATHS.TECHOPERATIONS_PAGE, // force multiline
    PATHS.EDIT_PAGE,
    guidUpperCased,
  ].join("/");
  return ret;
}

export function last5digitsGuid(techOpId: string): string {
  const guidUpperCased = techOpId.toUpperCase();
  const last5symbols = guidUpperCased.substring(31);
  return last5symbols;
}
