import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store-types";
import { IAuthState } from "../shared/interfaces/auth-state";
import { AUTH_MODULE_NAME } from "./auth.slice";

export const getAuthState = (rootState: RootState): IAuthState => rootState[AUTH_MODULE_NAME];

export const getLoadingUserDto = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.isLoadingUserDto);
export const getAuthenticating = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.isAuthenticating);
export const getIsAuthenticated = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.isAuthenticated);
export const getUser = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.user);
export const getToken = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.token);
export const getError = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.error);
