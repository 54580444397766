import React, { useMemo, memo } from "react";

import {
  AutocompleteChangeReason,
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteRenderInputParams,
  createFilterOptions,
  TextField,
  TextFieldProps,
} from "@mui/material";
import lodashGet from "lodash/get";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { trueForDev } from "../../../shared/utils/log";
import { removeDuplicates } from "./remove-duplicates";
import { TechOpsTestIds } from "src/pages/tech-operations/shared/types/tech-operation-data-test";

export interface WithIdGuid {
  id: string;
  name?: string;
  title?: string;
}

type TRHFAutocompleteSimple<T> = {
  options: T[];
  name: string;
  // undefined =>
  // "Material-UI: A component is changing the uncontrolled value state of Autocomplete to be controlled.
  // Elements should not switch from uncontrolled to controlled (or vice versa).
  // Decide between using a controlled or uncontrolled Autocomplete element for the lifetime of the component.
  // The nature of the state is determined during the first render, it's considered controlled if the value is not `undefined`."
  optionGuidToObject: (optionGuid: string) => T | undefined; // KEEP DEFINED
  defaultValue?: string;
  rules?: RegisterOptions;
  onOptionSelected?: (
    newOption: T | null,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  disabled?: boolean;
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  noOptionsText?: string;
  textFieldProps: TextFieldProps;
  style?: React.CSSProperties;
  deduplicate?: boolean;
  deduplicateField?: (item: T) => string;
  dataTest?: TechOpsTestIds;
};

const RHFAutocompleteSimpleNoMemo = <T extends WithIdGuid>({
  name,
  defaultValue,
  rules,
  optionGuidToObject,
  onOptionSelected,
  disabled,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  noOptionsText,
  textFieldProps,
  style,
  deduplicate,
  deduplicateField,
  dataTest = TechOpsTestIds.Empty,
}: TRHFAutocompleteSimple<T>): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, name)),
    [name, rhfMethods.formState.errors]
  );

  const filterOptions = createFilterOptions<T>({
    limit: 100,
  });

  if (trueForDev(deduplicate)) {
    // when duplicates found, alphabetic order is lost
    options = removeDuplicates(name, options, deduplicateField);
  }

  const xClickedWrapper = (optionGuidPassed: string | undefined | null) => {
    if (optionGuidPassed === undefined || optionGuidPassed === null) {
      // 1) user cleared previous selection with X => return null => controlled
      // 2) no selection at first load => return null => controlled
      return null;
    }

    const foundInOptions = optionGuidToObject(optionGuidPassed);
    if (foundInOptions !== undefined) {
      return foundInOptions;
    }
    return null; // null makes a component "controlled"
  };

  return (
    <Controller
      name={name}
      control={rhfMethods.control}
      defaultValue={defaultValue || null} // null makes a component "controlled"
      rules={rules}
      render={({
        field: { onChange: onChangeControllerHandler, onBlur, value: optionGuid },
      }) => {
        return (
          <Autocomplete<T>
            fullWidth={true}
            data-test={dataTest}
            id={dataTest}
            value={xClickedWrapper(optionGuid)}
            getOptionLabel={(option: T): string => {
              const ret = getOptionLabel?.(option) || option.name || option.title || "";
              return ret;
            }}
            isOptionEqualToValue={(option: T, value: T | undefined): boolean => {
              if (value === undefined) {
                return false; // WHEN_USER_CLEARS_CURRENT_SELECTION => none of options becomes selected
              }
              const ret = isOptionEqualToValue?.(option, value) || option.id === value.id;
              return ret;
            }}
            onBlur={onBlur}
            onChange={(
              _, // skip this React.ChangeEvent, no need for onOptionSelected to implement it
              newOption: T | null,
              reason: AutocompleteChangeReason,
              details?: AutocompleteChangeDetails<T>
            ) => {
              // https://reacthookform.caitouyun.com/api/usecontroller/controller
              onChangeControllerHandler(newOption?.id); // HACK for WithIdGuid
              onOptionSelected?.(newOption, reason, details);
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                variant="standard"
                error={error}
                {...textFieldProps}
                {...params}
              />
            )}
            filterOptions={filterOptions}
            disabled={disabled}
            options={options}
            noOptionsText={noOptionsText}
            style={style}
          />
        );
      }}
    />
  );
};

const typedMemo: <T>(c: T) => T = memo;
export const RHFAutocompleteSimple = typedMemo(RHFAutocompleteSimpleNoMemo);

RHFAutocompleteSimpleNoMemo.displayName = "RHFAutocompleteSimple";
