import { Paper } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { TechOpEditFormContent } from "./techop-edit-form-content";
import { TechOpEditFormSubmit } from "./techop-edit-form-submit";
import { PATHS } from "../../../../constant";
import { last5digitsGuid } from "../../../../shared/utils/format-techop-id";
import { RhfTechOpEditFormUtils, useTechOpFormUtils } from "./use-form-utils";

export const TechOperationsEditingPage = (): JSX.Element => {
  const { id: techOpId } = useParams<{ id: string }>();

  const [disabled, setDisabled] = useState(false);

  const rhfSingleton: RhfTechOpEditFormUtils = useTechOpFormUtils();
  const { operationNumber } = rhfSingleton;

  // TODO: extract to useTechOpPageTitle
  const isNew = useMemo(() => techOpId === PATHS.EDIT_NEW_PAGE, [techOpId]);

  const pageTitle = useMemo(() => {
    if (isNew) {
      return operationNumber > 0
        ? `Добавление Техоперации (в №${operationNumber})`
        : "Добавление Техоперации (в новый номер)";
    } else {
      let idShort = "";
      if (techOpId) {
        const techOpIdLast5digits = last5digitsGuid(techOpId);
        idShort = "..." + techOpIdLast5digits;
      }
      if (operationNumber > 0) {
        idShort += ` (в №${operationNumber})`;
      }
      return `Редактирование Техоперации ${idShort}`;
    }
  }, [isNew, operationNumber, techOpId]);

  return (
    <CommonLayout title={pageTitle}>
      <Paper>
        <TechOpEditFormSubmit
          techOpId={techOpId}
          rhfSingleton={rhfSingleton}
          disabled={disabled}
        >
          <TechOpEditFormContent
            techOpId={techOpId}
            setDisabled={setDisabled}
            rhfSingleton={rhfSingleton}
          />
        </TechOpEditFormSubmit>
      </Paper>
    </CommonLayout>
  );
};
