import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FarmModel, IFarmsState, farmsService } from "../shared";

const initialState: IFarmsState = {
  isLoading: false,
  list: [],
};
export const MODULE_NAME = "farms";
export const farmsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<FarmModel[]>): void {
      state.list = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFarmsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const farmsReducer = farmsSlice.reducer;
export const { setListAction } = farmsSlice.actions;

export const fetchFarmsAction = createAsyncThunk(`${MODULE_NAME}/fetchFarms`, async (params, { dispatch }) => {
  const dtos = await farmsService.list();
  const models = dtos.map((dto) => {
    const model = new FarmModel();
    model.updateFromDto(dto);
    return model;
  });
  dispatch(setListAction(models));
});
