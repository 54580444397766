import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IErrorsState } from "../shared/interfases/errors-state";

const initialState: IErrorsState = {
  lastMessage: "",
};
export const ERRORS_MODULE_NAME = "errors";
export const errorsSlice = createSlice({
  name: ERRORS_MODULE_NAME,
  initialState,
  reducers: {
    setLastMessageAction(state, action: PayloadAction<string>): void {
      state.lastMessage = action.payload;
    },
    logAction(state, action: PayloadAction<string>): void {
      state.lastMessage = action.payload;
      // eslint-disable-next-line
      console.log(action.payload);
    },
  },
});

export const errorsReducer = errorsSlice.reducer;
export const { logAction } = errorsSlice.actions;
