import { Divider } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const SectionDivider = ({ children }: PropsWithChildren): React.JSX.Element => {
  return (
    <Divider
      textAlign="left"
      sx={{ "::before": { display: "none" }, " > span": { pl: 0 } }}
    >
      {children}
    </Divider>
  );
};
