import { Box, Paper } from "@mui/material";
import { styled } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import BackgroundImage from "../../img/nature-sky-sunny-clouds.jpg";

const Background = styled(Box)({
  background: `url(${BackgroundImage}) no-repeat`,
  backgroundSize: "cover",
  height: "100vh",
  width: "100vw",
  overflow: "auto",
});

const Tile = styled(Paper)({
  margin: "auto",
  marginTop: "8%",
  width: "500px",
  height: "540px",
  opacity: "0.95",
  boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.16)",
  backgroundColor: "#ffffffed",
});

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const LoginLayout = (props: PropsWithChildren<any>): JSX.Element => {
  const { children } = props;
  return (
    <Background>
      <Tile>{children ? children : <Outlet />}</Tile>
    </Background>
  );
};
