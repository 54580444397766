import { Observation } from "../models/observation";
import { IObservationPhoto } from "../models/observation-photo.model";

export interface IObservationEditingState {
  isLoading: boolean;
  oldPhotos: IObservationPhoto[];
  chosenPhotos: File[];
  observation: Observation;
}

export function filesAreIdentical(chosenFile1: File, chosenFile2: File): boolean {
  if (chosenFile1.webkitRelativePath === chosenFile2.webkitRelativePath) {
    return true;
  }

  if (chosenFile1.size === chosenFile2.size && chosenFile1.name === chosenFile2.name) {
    return true;
  }

  return false; // describe above all conditions for "2 files are different"
}

export function findIdenticalFile(
  chosenPhoto: File,
  accumulator: Map<number, File>
): number | undefined {
  let mapKeyFound: number | undefined;
  accumulator.forEach((value: File, mapKey: number) => {
    if (value === chosenPhoto) {
      return; // pointer is different => skip comparing to myself
    }

    const identical = filesAreIdentical(chosenPhoto, value);
    if (identical) {
      mapKeyFound = mapKey; // last found overwrites all previously found mapKeys
    }
  });
  return mapKeyFound;
}
