import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { IFarmLandsTechState } from "../shared/interfaces/farm-lands-tech-state";
import { FARM_LANDS_TECH_MODULE_NAME } from "./farm-lands-tech.slice";

export const getFarmLandsTechState = (rootState: RootState): IFarmLandsTechState =>
  rootState[FARM_LANDS_TECH_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.isLoading
);
export const getList = createDraftSafeSelector(getFarmLandsTechState, (state: IFarmLandsTechState) => state.list);
export const getListCount = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.listCount
);

export const getNoPredecessorsList = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.noPredecessorsList
);

export const getRemoveSplittedFields = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.removeSplittedFields
);

export const getListFilters = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.filters
);
export const getListReRequestAfterEdit = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.reRequestAfterEdit
);
export const getListPagination = createDraftSafeSelector(
  getFarmLandsTechState,
  (state: IFarmLandsTechState) => state.pagination
);
