import { ButtonGroup, IconButton } from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@mui/icons-material";

import { TechOpEditingAssetsButtonsType } from "../../shared/types";

export const TechOpEditingAssetsButtons = ({
  assetEntityType,
  disabled,
  destructAndSetFields,
  tabs,
}: TechOpEditingAssetsButtonsType) => {
  return (
    <ButtonGroup sx={{ flexGrow: 1, justifyContent: "space-between" }}>
      <IconButton
        onClick={() => destructAndSetFields(assetEntityType, "add")}
        disabled={disabled}
      >
        <AddCircleOutlineIcon />
      </IconButton>
      <IconButton
        onClick={() =>
          tabs[assetEntityType] &&
          destructAndSetFields(assetEntityType, "remove", tabs[assetEntityType])
        }
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ButtonGroup>
  );
};
