import { Box, Grid } from "@mui/material";
import React, { PropsWithChildren, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { SidebarPanel } from "../../../modules/sidebar";
import { useAppbarHeight } from "../../utils/use-app-bar-height";

interface IProps {
  title?: string;
  contentPadding?: number;
}

// TODO: try this with Route element=<commonLayout...
export const CommonLayout = (props: PropsWithChildren<IProps>): JSX.Element => {
  const { children, title = "Эко кроп", contentPadding = 1 } = props;
  const { appbarHeight } = useAppbarHeight();

  return (
    <Grid container={true} direction="row" wrap="nowrap">
      <Grid item={true}>
        <SidebarPanel title={title} />
      </Grid>

      <Grid
        item={true}
        xs={12}
        sx={(theme) => ({
          // TODO: drawerOpen also should be taken into account
          maxWidth: `calc(100% - calc(${theme.spacing(6)} + 1px))`,
          [theme.breakpoints.up("sm")]: {
            maxWidth: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
          },
        })}
      >
        <Box
          component={"div"}
          sx={{
            minHeight: `${appbarHeight}px`,
          }}
        />
        <Suspense fallback={<div>Loading...</div>}>
          <Box
            sx={{
              p: contentPadding,
            }}
          >
            {children ? children : <Outlet />}
          </Box>
        </Suspense>
      </Grid>
    </Grid>
  );
};
