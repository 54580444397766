import Grid from "@mui/material/Grid";
import React, { lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { fetchFarmsAction } from "../../modules/farms";
import { CommonLayout } from "../../shared/components/common-layout/common-layout";
import { useAppDispatch } from "../../store";
import { CatalogsSubmenu } from "./catalogs-submenu";

const TechAssets = lazy(() => import("../../modules/tech-assets"));
const Employees = lazy(() => import("../../modules/employees"));
const AppUsers = lazy(() => import("../../modules/app-users"));
const Chemicals = lazy(() => import("../../modules/chemicals"));
const Fertilisers = lazy(() => import("../../modules/fertilisers"));
const Seeds = lazy(() => import("../../modules/seeds"));
const Crops = lazy(() => import("../../modules/crop/containers/crops/crops"));
const Diseases = lazy(() => import("../../modules/diseases"));
const Weeds = lazy(() => import("../../modules/weeds"));
const Pests = lazy(() => import("../../modules/pests"));
const Phenophases = lazy(() => import("../../modules/phenophases"));

export const Catalogs = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFarmsAction());
  }, [dispatch]);

  return (
    <CommonLayout title={"Справочники"}>
      <Grid container={true} direction="row" wrap="nowrap">
        <Grid item={true}>
          <CatalogsSubmenu />
        </Grid>

        <Grid item={true} style={{ flexGrow: 1 }}>
          <Routes>
            <Route path={"techassets/*"} element={<TechAssets />} />
            <Route path={"employees/*"} element={<Employees />} />
            <Route path={"appusers/*"} element={<AppUsers />} />
            <Route path={"chemicals/*"} element={<Chemicals />} />
            <Route path={"fertilisers/*"} element={<Fertilisers />} />
            <Route path={"seeds/*"} element={<Seeds />} />
            <Route path={"crops/*"} element={<Crops />} />
            <Route path={"diseases/*"} element={<Diseases />} />
            <Route path={"weeds/*"} element={<Weeds />} />
            <Route path={"pests/*"} element={<Pests />} />
            <Route path={"phenophases/*"} element={<Phenophases />} />
            <Route path="*" element={<Navigate to={"./techassets"} replace={true} />} />
          </Routes>
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
