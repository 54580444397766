import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { MapContainer } from "react-leaflet";
import { useSelector } from "react-redux";

import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { useAppbarHeight } from "../../../../shared/utils/use-app-bar-height";
import { TArcgisMapCropPropsDto } from "../../shared/dtos/arcgis-map-crop-props.dto";
import { getCenter, getZoom } from "../../store/map-page.selector";
import { FarmLandsLayer } from "../farm-lands-layer/farm-lands-layer";
import { MapDrawerPanel } from "../map-drawer-panel/map-drawer-panel";
import { MapFilterControl } from "../map-filter-control/map-filter-control";
import { OfflineMapLayer } from "../offline-map-layer/offline-map-layer";

export const MapComponent = (): JSX.Element => {
  const { appbarHeight } = useAppbarHeight();
  const center = useSelector(getCenter);
  const zoom = useSelector(getZoom);

  const [feature, setFeature] = useState<TArcgisMapCropPropsDto | null>(null);

  return (
    <CommonLayout title={"Карта"} contentPadding={0}>
      <Grid
        container={true}
        sx={{
          height: `calc(100vh - ${appbarHeight}px)`,
        }}
      >
        <Grid
          item={true}
          sx={{
            height: "inherit",
            overflow: "auto",
          }}
        >
          <MapDrawerPanel />
        </Grid>

        <Grid
          item={true}
          sx={{
            flexGrow: 1,
            height: "inherit",
          }}
        >
          <MapContainer
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <OfflineMapLayer />

            <MapFilterControl />

            {/* it shows us farm lands fields and crops */}
            <FarmLandsLayer setFeature={setFeature} />

            {feature && (
              <Box
                sx={(theme) => ({
                  background: theme.palette.background.default,
                  zIndex: 1000,
                  position: "absolute",
                  width: "auto",
                  bottom: 0,
                  margin: "15px",
                  padding: "5px 10px",
                  borderRadius: "5px",
                })}
              >
                <p>
                  <b>Подразделение: </b>
                  {feature.farm}
                </p>
                <p>
                  <b>Культура: </b>
                  {feature.crop}
                </p>
                <p>
                  <b>Поле: </b>
                  {feature.name}
                </p>
                <p>
                  <b>Площадь: </b>
                  {feature.area_2d}Га
                </p>
              </Box>
            )}
          </MapContainer>
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
