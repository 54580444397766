import { ProductionType } from "../../../../shared/enums";
import { TechOperationAssetDtoWithoutUnitsType } from "./tech-operation-asset.dto";
import { ITechOperationValueDto } from "./tech-operation-param.dto";
import { ITimestampsDto } from "../../../../shared/dtos/timestamps.dto";
import { ILocalTimestampsDto } from "../../../../shared/dtos/local-timestamps.dto";

export interface ITechOperationDto extends ITimestampsDto, ILocalTimestampsDto {
  id: string;
  farmId: string;
  farmLandId: string;
  /**
   * @deprecated shouldn't be used because of architecture problems
   */
  farmLandName: string;

  // release/2.1 hotfix for DEVELOPMENT-3626, DEVELOPMENT-3628
  Real_farmLandId: string;
  Real_farmLandName: string;

  cropTypeId: string;
  startDate: string; // ISO8601
  finishDate: string; // ISO8601
  techOperationGroupId: string;
  techOperationSubGroupId: string;
  fieldSize: number;
  operationNumber: number;
  comment: string;
  productionType: ProductionType;
  techAssetId: string | null;
  trailerAssetId: string | null; // why null: 3127
  employeeId: string | null; // why null: 3127 3601
  appUserId: string;
  reportNumber?: number;
  feedJson?: string;
  commercialJson?: string;
  byProductJson?: string;
  tillageJson?: string;
  sprayingFertilisingJson?: string;
  departmentId?: string;
  cropId?: string;
  seasonId?: string;
  /**
   * @deprecated shouldn't be used because of architecture problems
   */
  techAssetName?: string;
  /**
   * @deprecated shouldn't be used because of architecture problems
   */
  techAssetInventoryNumber?: string;
  /**
   * @deprecated shouldn't be used because of architecture problems
   */
  trailerAssetName?: string;
  /**
   * @deprecated shouldn't be used because of architecture problems
   */
  trailerAssetInventoryNumber?: string;
  massCleanedProduct?: number;
  productivitySet?: number;
  prevId?: string;
  TechOperationValues?: ITechOperationValueDto[];
  materiallyAccountableEmployees: {
    seeds: { id: string; name: string } | null;
    fertilisers: { id: string; name: string } | null;
    chemicals: { id: string; name: string } | null;
  };
  // NB!!! backend sends entityType and entityId swapped, one instead of the other
  Asset?: TechOperationAssetDtoWithoutUnitsType[];
}

export function isPredecessorDto(dto: ITechOperationDto): boolean {
  return dto.Real_farmLandId !== undefined
    ? dto.Real_farmLandId !== dto.farmLandId
    : false;
}

export function isOriginalDto(dto: ITechOperationDto): boolean {
  return dto.Real_farmLandId !== undefined
    ? dto.Real_farmLandId === dto.farmLandId
    : true;
}
