import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getCropsList } from "../../../../modules/crop";
import { getCropTypesList } from "../../../../modules/crop-types";
import { useCurrentFarmLands_RtkQ } from "../../../../modules/farm-lands/current-farm-lands.hook.rtkq";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { devlog } from "../../../../shared/utils/log";

export type HumidityImpurityCalculator = {
  basicHumidity: number | undefined;
  basicImpurity: number | undefined;
};

export function useHumidityImpurityCalculator(
  farmLandId: string | undefined
): HumidityImpurityCalculator {
  const cropTypes = useSelector(getCropTypesList);
  const crops = useSelector(getCropsList);

  const {
    // isLoadingFarmLands,
    // farmLands_includeDeleted: farmLands,
    // if a TO was created on a deleted farmField,
    // dropdown will have an empty value => SAVE will ERASE the farmField
    // (SAVE should be disabled until a field is selected)
    farmLands_nonDeleted: farmLands,
  } = useCurrentFarmLands_RtkQ();

  const [basicHumidity, setBasicHumidity] = useState<number | undefined>();
  const [basicImpurity, setBasicImpurity] = useState<number | undefined>();

  useEffect(() => {
    if (!farmLands) {
      return;
    }

    const farmLand = findModelByProperty(farmLands, farmLandId);
    const currentCrop = crops.find((item) => item.id === farmLand?.cropId);
    const currentCropType = cropTypes.find((item) => item.id === farmLand?.cropTypeId);

    const newCropType_basicHumidity =
      currentCrop?.basicHumidity || currentCropType?.basicHumidity || 0;
    setBasicHumidity(newCropType_basicHumidity);

    const newCropType_basicImpurity =
      currentCrop?.basicImpurity || currentCropType?.basicImpurity || 0;
    setBasicImpurity(newCropType_basicImpurity);

    devlog(
      `newCropType_basicHumidity[${newCropType_basicHumidity}] newCropType_basicImpurity[${newCropType_basicImpurity}]`
    );
  }, [cropTypes, crops, farmLands, basicHumidity, setBasicImpurity, farmLandId]);

  // rerenders the invoker twice, due to 2 setState()
  return {
    basicHumidity,
    basicImpurity,
  };
}
