import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IChemicalsState } from "../shared";
import { MODULE_NAME } from "./chemicals.slice";
import { RootState } from "../../../store-types"

export const getChemicalsState = (rootState: RootState): IChemicalsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getChemicalsState, (state: IChemicalsState) => state.isLoading);
export const getList = createDraftSafeSelector(getChemicalsState, (state: IChemicalsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getChemicalsState, (state: IChemicalsState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getChemicalsState, (state: IChemicalsState) => state.searchText);
