import { useMemo } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import lodashGet from "lodash/get";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface IRHFSelectSimple<T> {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: T;
  rules?: RegisterOptions;
  options: T[];
  renderOnChange?: (evt: SelectChangeEvent) => void;
}

export const RHFSelectSimple = <T extends string>({
  name,
  label,
  disabled,
  defaultValue,
  rules,
  options,
  renderOnChange,
}: IRHFSelectSimple<T>): JSX.Element => {
  const rhfMethods = useFormContext();

  const error = useMemo(
    () => Boolean(lodashGet(rhfMethods.formState.errors, name)),
    [name, rhfMethods.formState.errors]
  );

  const handleSelectChange = (onChange) => (evt) => {
    renderOnChange?.(evt) || onChange(evt.target.value);
  };

  return (
    <Controller
      name={name}
      control={rhfMethods.control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value: optionLabel } }) => {
        return (
          <FormControl fullWidth={true} disabled={disabled} error={error}>
            {label && <InputLabel id={name + "-label"}>{label}</InputLabel>}
            <Select
              variant="standard"
              labelId={name + "-label"}
              value={[optionLabel] || ""}
              onChange={handleSelectChange(onChange)}
            >
              {options.map((option, idx) => (
                <MenuItem key={`${idx}.${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};
