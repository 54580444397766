import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IWeedsState } from "../shared";
import { MODULE_NAME } from "./weeds.slice";
import { RootState } from "../../../store-types"

export const getWeedsState = (rootState: RootState): IWeedsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getWeedsState, (state: IWeedsState) => state.isLoading);
export const getList = createDraftSafeSelector(getWeedsState, (state: IWeedsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getWeedsState, (state: IWeedsState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getWeedsState, (state: IWeedsState) => state.searchText);
