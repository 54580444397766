import { format as formatDate } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { IUserDto } from "../../../../authentication/shared/dtos/user.dto";
import { ISO8601_DATETIME_FORMAT, ISO8601_DATE_FORMAT } from "../../../../constant";
import { ICropDto } from "../../../../modules/crop";
import { IFarmsDto } from "../../../../modules/farms/shared";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import { IObservationPhotoDto } from "../dtos/observation-photo.dto";
import { IObservationDto } from "../dtos/observations.dto";
import { IPhenophaseDto } from "../../../../modules/phenophases/shared";
import { IViolationDto } from "../dtos/violation.dto";

export class Observation {
  readonly id: string;
  appUserId: string;
  farmId: string;
  farmLandId: string;
  phenoPhaseId: string | null;
  cropTypeId: string;
  seasonId: string;
  reportNumber: number;
  comment: string;
  violations: IViolationDto[];
  observationPhotos: Pick<IObservationPhotoDto, "id" | "s3Filename" | "isDeleted">[];
  isReportComplete = false;
  cropId: string;
  departmentId: string;
  appUser: Pick<IUserDto, "fullName">;
  crop: Pick<ICropDto, "name">;
  farm: Pick<IFarmsDto, "name">;
  farmLand: Pick<IFarmLandDto, "name">;
  phenoPhase: Pick<IPhenophaseDto, "name">;

  createdOnClientAt: string | null;
  observationDate = new Date();
  updatedAt?: number; // unixtime
  isDeleted: boolean;

  get observationDateHuman(): string {
    return formatDate(this.observationDate, ISO8601_DATE_FORMAT);
  }

  get editDateHuman(): string {
    return this.updatedAt ? formatDate(this.updatedAt, ISO8601_DATETIME_FORMAT) : "-";
  }

  get asDto(): IObservationDto {
    return {
      id: this.id,
      farmId: this.farmId,
      farmLandId: this.farmLandId,
      phenoPhaseId: this.phenoPhaseId,
      cropTypeId: this.cropTypeId,
      seasonId: this.seasonId,
      reportNumber: this.reportNumber,
      comment: this.comment,
      violations: this.violations,
      appUser: { fullName: this.appUser?.fullName },
      crop: { name: this.crop?.name },
      farm: { name: this.farm?.name },
      farmLand: { name: this.farmLand?.name },
      phenoPhase: { name: this.phenoPhase?.name },
      isReportComplete: this.isReportComplete,
      appUserId: this.appUserId,
      observationDate: formatDate(this.observationDate, ISO8601_DATE_FORMAT),
      updatedAt: this.updatedAt,
      createdOnClientAt: this.createdOnClientAt,
      isDeleted: this.isDeleted,
      observationPhotos: this.observationPhotos,
      cropId: this.cropId,
      departmentId: this.departmentId,
    };
  }

  updateFromDto(dto: IObservationDto): void {
    this.appUserId = dto.appUserId;
    this.farmId = dto.farmId;
    this.farmLandId = dto.farmLandId;
    this.phenoPhaseId = dto.phenoPhaseId || null;
    this.cropTypeId = dto.cropTypeId;
    this.seasonId = dto.seasonId;
    this.reportNumber = dto.reportNumber;
    this.comment = dto.comment;
    this.violations = dto.violations || [];
    this.observationPhotos = dto.observationPhotos || [];
    this.isReportComplete = dto.isReportComplete;
    this.appUser = { fullName: dto.appUser?.fullName || "" };
    this.crop = { name: dto.crop?.name || "" };
    this.farm = { name: dto.farm?.name || "" };
    this.farmLand = { name: dto.farmLand?.name || "" };
    this.phenoPhase = { name: dto.phenoPhase?.name || "" };
    this.createdOnClientAt = dto.createdOnClientAt;
    this.observationDate = new Date(dto.observationDate);
    this.updatedAt = dto.updatedAt;
    this.isDeleted = dto.isDeleted || false;
    this.cropId = dto.cropId;
    this.departmentId = dto.departmentId;
  }

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  setFarmId(value: string): void {
    this.farmId = value;
  }

  setPhenoPhaseId(value: string | null): void {
    this.phenoPhaseId = value === "" ? null : value;
  }

  setCropTypeId(value: string): void {
    this.cropTypeId = value;
  }

  setSeasonId(value: string): void {
    this.seasonId = value;
  }

  setComment(value: string): void {
    this.comment = value;
  }

  setViolations(value: IViolationDto[]): void {
    this.violations = value;
  }

  setObservationDate(value: Date): void {
    this.observationDate = value;
  }

  completeReport(): void {
    this.isReportComplete = true;
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setFarmLandId(farmLandId: string | null): Observation {
    if (farmLandId) {
      this.farmLandId = farmLandId;
    }
    return this;
  }

  resetCreatedOnClientAt(): void {
    this.createdOnClientAt = formatDate(new Date(), ISO8601_DATETIME_FORMAT);
  }
}
