import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";

const hashTable = {
  [AssetUsageRecordStatus.Created]: "Создан",
  [AssetUsageRecordStatus.PreparedToSend]: "Подготовлен к отправке",
  [AssetUsageRecordStatus.Expired]: "Просрочен",
  [AssetUsageRecordStatus.Sent]: "Отправлен в 1С",
  [AssetUsageRecordStatus.WrittenOff]: "Списан",
  [AssetUsageRecordStatus.Error]: "Ошибка",
};

export function getTitleByStatus(status: AssetUsageRecordStatus): string {
  return hashTable[status];
}
