import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { IEmployeesDto } from "../../../../modules/employees/shared";
import { getList as getEmployeesList } from "../../../../modules/employees/store";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { getInfoWorkPlace } from "../../../../modules/info-data";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { isMachineOperator } from "../../../../shared/utils/is-machine-operator";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOpsTestIds } from "../../shared/types/tech-operation-data-test";

interface IProps {
  isRequired: boolean;
  isExistingTechOperation: boolean;
  disabled?: boolean;
}

export const TechOperationEditingEmployees = (props: IProps): JSX.Element => {
  const {
    isRequired = false,
    isExistingTechOperation: isEditingTechOperation,
    disabled,
  } = props;
  const rhfMethods = useFormContext<ITechOperationFormData>();
  const watchFields = rhfMethods.watch();

  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));
  const employeesRaw = useSelector(getEmployeesList);
  const workPlaces = useSelector(getInfoWorkPlace);

  const [employeeAllowAllFarms, setEmployeeAllowAllFarms] = useState(false);
  const [employeeAllowAllPositions, setEmployeeAllowAllPositions] = useState(false);

  useEffect(() => {
    if (!watchFields.farmId || !appFilterSeasonId || !isEditingTechOperation) {
      return;
    }

    setEmployeeAllowAllFarms(true);
    setEmployeeAllowAllPositions(true);
  }, [watchFields.farmId, appFilterSeasonId, isEditingTechOperation]);

  const employees = useMemo(() => {
    return employeesRaw.map((employee) => {
      return {
        ...employee,
        workPlace: workPlaces.find((item) => {
          return item.id === employee.workPlaceId;
        }),
      };
    });
  }, [employeesRaw, workPlaces]);

  const availableEmployees = useMemo(() => {
    return employees.filter(
      (item) =>
        (employeeAllowAllFarms || item.workPlace?.farmId === watchFields.farmId) &&
        (employeeAllowAllPositions || isMachineOperator(item))
    );
  }, [employees, employeeAllowAllFarms, employeeAllowAllPositions, watchFields.farmId]);

  if (!employees) {
    return <></>;
  }

  return (
    <Grid container={true} spacing={2} alignItems={"center"}>
      <Grid item={true} xs={8}>
        <RHFAutocomplete<IEmployeesDto>
          name="employeeId"
          dataTest={TechOpsTestIds.TechOpsEditEmployeesMechanizer}
          rules={{ required: isRequired }}
          renderValue={(value) => findModelByProperty(availableEmployees, value)}
          AutocompleteProps={{
            disabled: !watchFields.farmLandId || disabled,
            options: availableEmployees,
            getOptionLabel: (option) =>
              option.positionName + " / " + option.fullName || "",
            renderOption: (renderProps, option) => {
              return (
                <li {...renderProps} key={option.id}>
                  {option.positionName + " / " + option.fullName || ""}
                </li>
              );
            },
            noOptionsText: "Механизаторов не найдено",
          }}
          TextFieldProps={{
            required: isRequired,
            label: "Механизатор",
          }}
        />
      </Grid>

      <Grid item={true}>
        <FormControlLabel
          data-test={TechOpsTestIds.TechOpsEditEmployeesFromAllFarms}
          control={
            <Switch
              onChange={(evt) => setEmployeeAllowAllFarms(evt.target.checked)}
              checked={employeeAllowAllFarms}
              disabled={disabled}
              color={"primary"}
              id={TechOpsTestIds.TechOpsEditEmployeesFromAllFarms}
            />
          }
          label="Из всех хозяйств"
        />
      </Grid>

      <Grid item={true}>
        <FormControlLabel
          data-test={TechOpsTestIds.TechOpsEditEmployeesAllEmployees}
          control={
            <Switch
              onChange={(evt) => setEmployeeAllowAllPositions(evt.target.checked)}
              checked={employeeAllowAllPositions}
              disabled={disabled}
              color={"primary"}
              id={TechOpsTestIds.TechOpsEditEmployeesAllEmployees}
            />
          }
          label="Все сотрудники"
        />
      </Grid>
    </Grid>
  );
};
