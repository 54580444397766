import { Tab, Tabs } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PATHS } from "../../../../constant";

export const FarmLandsSubmenu = (): JSX.Element => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.pathname.split("/")[3] || "");
  const handleChange = useCallback((event, newValue) => setTabValue(newValue), [setTabValue]);

  return (
    <Tabs
      orientation={"vertical"}
      value={tabValue}
      onChange={handleChange}
      aria-label="farm lands submenu"
      // ecocrop styles
      className={"is-ecocrop"}
    >
      <Tab component={Link} to={"."} state={{ tabValue: "" }} value="" label="Основная информация" />
      <Tab
        component={Link}
        to={PATHS.FIELD_TECH}
        state={{ tabValue: PATHS.FIELD_TECH }}
        value={PATHS.FIELD_TECH}
        label="Техоперации"
      />
      <Tab
        component={Link}
        to={PATHS.FIELD_REPORTS}
        state={{ tabValue: PATHS.FIELD_REPORTS }}
        value={PATHS.FIELD_REPORTS}
        label="Отчеты осмотров"
      />
    </Tabs>
  );
};
