import { IObservationDto } from "../dtos/observations.dto";
import { IObservationFormData } from "../interfaces/observation-form-data";

export function dtoToObservationFormData(dto: IObservationDto): IObservationFormData {
  return {
    id: dto.id,
    farmId: dto.farmId || "",
    farmLandId: dto.farmLandId || "",
    phenoPhaseId: dto.phenoPhaseId || "",
    cropTypeId: dto.cropTypeId || "",
    observationDate: new Date(dto.observationDate),
    seasonId: dto.seasonId || "",
    comment: dto.comment || "",
    violations: dto.violations || [],
  };
}
