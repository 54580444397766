import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { ICropsState } from "../shared";
import { CROPS_MODULE_NAME } from "./crops.slice";

export const getCropsState = (rootState: RootState): ICropsState =>
  rootState[CROPS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getCropsState,
  (state: ICropsState) => state.isLoading
);

export const getList = createDraftSafeSelector(
  getCropsState,
  (state: ICropsState) => state.list
);

export const getSelectedIds = createDraftSafeSelector(
  getCropsState,
  (state: ICropsState) => state.selectedIds
);

export const getSearchText = createDraftSafeSelector(
  getCropsState,
  (state: ICropsState) => state.searchText
);
