import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { NotificationsArea } from "./modules/notifications/containers/notifications/notifications-area";
import { AppRouter } from "./router";
import { MuiTheme } from "./styles/mui-theme";
import { useVerification } from "./modules/verification/shared/utils/use-verification";

const router = createBrowserRouter([{ path: "*", Component: AppRouter }]);

export function App(): React.JSX.Element {
  // initialize verification module
  useVerification();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ru}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <CssBaseline /*enableColorScheme={true} TODO: in mui v5+ */ />
          <RouterProvider router={router} />
          <NotificationsArea />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
