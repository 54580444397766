import { Tab, Tabs } from "@mui/material";

import { TAB_NAMES } from "../../constants";
import { TechOpEditingAssetsTabsType } from "../../shared/types";

export const TechOpEditingAssetsTabs = ({
  controlledFields,
  tabs,
  setTabs,
  assetEntityType,
}: TechOpEditingAssetsTabsType) => {
  const visibleFields = controlledFields.filter(
    ({ field }) => field.entityType === assetEntityType
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabs((prev) => ({ ...prev, [assetEntityType]: newValue }));
  };

  const tabName = TAB_NAMES[assetEntityType];

  return (
    <Tabs
      value={tabs[assetEntityType]}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ maxWidth: `calc(100% - 100px)` }}
    >
      {visibleFields.map(({ index, field }) => (
        <Tab key={field.id} label={tabName} value={index} />
      ))}
    </Tabs>
  );
};
