import React, { useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { HUMAN_DATE_FORMAT } from "../../../../../constant";
import { IFilterFormData } from "../../../shared/interfaces/filter-form-data";

export const ToField = (): React.JSX.Element => {
  const { control, watch } = useFormContext<IFilterFormData>();
  const from = watch("from");
  const currentDate = useMemo(() => endOfDay(new Date()), []);

  return (
    <Controller
      control={control}
      name={"to"}
      rules={{
        required: false,
        validate: (date: Date | null) => {
          if (!date) return true;

          if (isAfter(date, endOfDay(currentDate))) {
            return `Дата "до" не может быть позднее текущей`;
          }

          if (isBefore(date, startOfDay(from))) {
            return `Дата "до" не может быть раньше даты "от"`;
          }

          return true;
        },
      }}
      render={({ fieldState: { error }, field }) => (
        <DatePicker
          {...field}
          label={"От"}
          format={HUMAN_DATE_FORMAT}
          minDate={from}
          maxDate={currentDate}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : "",
              InputLabelProps: {
                required: false,
              },
              InputProps: {
                required: false,
              },
            },
          }}
        />
      )}
    />
  );
};
