import { TechOpEditChemical } from "../tech-operation-editing-asset/techop-edit-chemical";
import { TechOpEditFertiliser } from "../tech-operation-editing-asset/techop-edit-fertiliser";
import { TechOpEditSeed } from "../tech-operation-editing-asset/techop-edit-seed";
import { TechOperationAssetEntityType } from "../../shared/types/tech-operation-asset-entity";
import { TechopVisibleFieldsEditType } from "../../shared/types";

export const TechopVisibleFieldsEdit = ({
  optionsLabel,
  noOptionsText,
  cropId,
  isExistingTechOperation,
  controlledFields,
  assetEntityType,
  disabled,
  allStocks,
  tabs,
}: TechopVisibleFieldsEditType) => {
  const visibleFields = controlledFields?.filter(
    ({ field }) => field.entityType === assetEntityType
  );

  if (!controlledFields) {
    return <></>;
  }

  return (
    <>
      {visibleFields.map(({ index, field }) => {
        switch (assetEntityType) {
          case TechOperationAssetEntityType.CHEMICAL:
            return (
              <div hidden={tabs[assetEntityType] !== index} key={field.key}>
                <TechOpEditChemical
                  key={field.key}
                  globalIndex={index}
                  initialFormAsset={field}
                  optionsLabel={optionsLabel}
                  noOptionsText={noOptionsText}
                  disabled={disabled}
                  allStocks={allStocks}
                />
              </div>
            );

          case TechOperationAssetEntityType.FERTILISER:
            return (
              <div hidden={tabs[assetEntityType] !== index} key={field.key}>
                <TechOpEditFertiliser
                  key={field.key}
                  globalIndex={index}
                  initialFormAsset={field}
                  optionsLabel={optionsLabel}
                  noOptionsText={noOptionsText}
                  disabled={disabled}
                  allStocks={allStocks}
                />
              </div>
            );

          case TechOperationAssetEntityType.SEED:
            return (
              <div hidden={tabs[assetEntityType] !== index} key={field.key}>
                <TechOpEditSeed
                  key={field.key}
                  globalIndex={index}
                  initialFormAsset={field}
                  optionsLabel={optionsLabel}
                  noOptionsText={noOptionsText}
                  cropId={cropId}
                  isExistingTechOperation={isExistingTechOperation || false}
                  disabled={disabled}
                  allStocks={allStocks}
                />
              </div>
            );

          default:
            return <></>;
        }
      })}
    </>
  );
};
