import { useSelector } from "react-redux";

import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";
import { FilterName } from "../filter/shared/enums/filter-name";
import { getFilter } from "../filter/store/filters.selector";
import {
  selectNonDeletedOnly,
  useFetchFarmLands_forFarmId_includeDeletedQuery,
} from "./current-farm-lands.rtkq";
import { useMemo } from "react";

export function useCurrentFarmLands_RtkQ(): CurrrentFarmLands {
  const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));
  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));

  const { isLoading, data } = useFetchFarmLands_forFarmId_includeDeletedQuery(
    {
      seasonId: appFilterSeasonId || "",
      farmId: appFilterFarmId || "",
    },
    { skip: !appFilterFarmId || !appFilterSeasonId }
  );

  const farmLands_nonDeleted = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }

    return selectNonDeletedOnly(data, "useCurrentFarmLands_RtkQ farmLands_nonDeleted: ");
  }, [data]);

  return {
    isLoadingFarmLands: isLoading,
    farmLands_includeDeleted: data,
    farmLands_nonDeleted,
  };
}

export type CurrrentFarmLands = {
  isLoadingFarmLands: boolean;
  farmLands_includeDeleted: IFarmLandDto[] | undefined;
  farmLands_nonDeleted: IFarmLandDto[] | undefined;
};
