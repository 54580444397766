export interface IObservationPhoto {
  id: string;
  s3Filename: string;
  toDelete?: boolean;
}

export interface IObservationPhotoOriginal extends IObservationPhoto {
  fileHash: string; // presumably, contains crc(contents)
}

export function observationPhotosAreIdentical(
  existing1: IObservationPhoto,
  existing2: IObservationPhoto
): boolean {
  if (Object.hasOwn(existing1, "fileHash") && Object.hasOwn(existing2, "fileHash")) {
    if (
      (existing1 as IObservationPhotoOriginal).fileHash ===
      (existing2 as IObservationPhotoOriginal).fileHash
    ) {
      return true;
    }
  }

  // if (existing1.s3Filename === existing2.s3Filename && existing1.id !== existing2.id) {
  //   return true;
  // }

  // if (existing1.s3Filename !== existing2.s3Filename) {
  //   return false;
  // }

  return false; // describe above all conditions for "2 files are different"
}

export function findIdenticalObservationPhoto(
  oldPhoto: IObservationPhoto,
  accumulator: Map<number, IObservationPhoto>
): number | undefined {
  let mapKeyFound: number | undefined;
  accumulator.forEach((value: IObservationPhoto, mapKey: number) => {
    if (value === oldPhoto) {
      return; // pointer is different => skip comparing to myself
    }

    const identical = observationPhotosAreIdentical(oldPhoto, value);
    if (identical) {
      mapKeyFound = mapKey; // last found overwrites all previously found mapKeys
    }
  });
  return mapKeyFound;
}
