import { Checkbox } from "@mui/material";
import React, { useMemo } from "react";

import { BoxTooltipMouseover } from "./box-tooltip-mouseover";

export function TechOpPredecessorsFilter({
  forCurrentSubGroupCount,
  noPredecessorsCount,
  onChange,
  disabled,
  checked,
}: {
  forCurrentSubGroupCount: number;
  noPredecessorsCount: number;
  onChange?: () => void;
  disabled?: boolean;
  checked: boolean;
}): JSX.Element {
  const predecessorsCount = useMemo(() => {
    return forCurrentSubGroupCount - noPredecessorsCount;
  }, [forCurrentSubGroupCount, noPredecessorsCount]);

  const hasPredecessors = forCurrentSubGroupCount !== noPredecessorsCount;
  if (!hasPredecessors) {
    return <></>;
  }

  return (
    <BoxTooltipMouseover
      title={
        <pre style={{ textAlign: "left" }}>
          в списке агроработ есть такие, которые заведены на предшественника
          <br />
          по умолчанию, агроработы на предшественнике не выводятся,
          <br />
          <br />
          но подсчитывается сколько их, и пишется:
          <br />
          <br />
          вывести {predecessorsCount} агроработ, выполненных на предшественнике,
          <br />в этом номере, в этом типе, в этом подтипе
        </pre>
      } 
    > 
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <Checkbox id="predecessorCbx" disabled={disabled} checked={checked} onClick={onChange} />
        <label htmlFor="predecessorCbx" style={{ marginTop: 10}}>
          {`${predecessorsCount} `} 
          на пред&shy;шест&shy;вен&shy;ни&shy;ке
        </label>
      </div>
    </BoxTooltipMouseover>
  );
}
