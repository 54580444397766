import { useState } from "react";

import { TabsInterface, TabsUseStateInterface } from "../../shared/types";
import { TechOperationAssetEntityType } from "../../shared/types/tech-operation-asset-entity";

export const useTabs = (
  entityTypes: TechOperationAssetEntityType[]
): [TabsUseStateInterface["tabs"], TabsUseStateInterface["setTabs"]] => {
  const tabDefaultValue = entityTypes.reduce((acc, entity, index) => {
    acc[entity] = index;
    return acc;
  }, {});
  const [tabs, setTabs] = useState<TabsInterface>({ ...tabDefaultValue });

  return [tabs, setTabs];
};

// returns fields and tabs
// -> addEntity -> adds new item (creates new index and field) sets new index,
//  preserve others
// ->
// после выоплнения задачи переписать в синглтон
// и ексекьютить значения где надо без проп дриллинга

// TODO: Let this comments be. In future we should avoid arrays in such cases, better use mapped object and rewrite on it
// TODO: And before Submit transfer to array if needed

// Какие вообще способы есть? Есть поля, юзФилд дает полям ключ,
// Берется массив полей с ключами -> индексы просто по очереди даются
// удаление елемента вызывается переписку всех индексов массива
// тут можно пойти двумя путями
// 1) Каждый раз пайпить по пути сопоставления и сортировки по категориям
// 2) делать дырки в массиве и потом профильтровывать их
// Второй вариант крутой, но все типы просто разлетаются
