import React, { useCallback, useMemo } from "react";

import { Comment as CommentIcon, LiveHelp as LiveHelpIcon } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import { ISO8601_DATE_FORMAT } from "../../../../constant";
import { ContainerBackdrop } from "../../../../shared/components/container-backdrop/container-backdrop";
import { AssetUsageRecord } from "../../shared/models/asset-usage-record";

import {
  getList,
  getListCount,
  getLoading,
  getSelectedIds,
  getIsAllSelected,
  getVisibleColumns,
  getVisibleColumnIds,
} from "../../store/asset-usage-records.selector";
import {
  addSelectedAction,
  removeSelectedAction,
  setDetails,
  toggleSelectedAction,
} from "../../store/asset-usage-records.slice";

import { usePaginationContext } from "../../../../modules/filter/shared/utils/use-pagination";
import { EnhancedTableHead } from "../../../../components/enhanced-table-head/enhanced-table-head";
import { TablePaginationStartPrevNextEnd } from "../../../../shared/components/table-pagination-start-prev-next-end/table-pagination-start-prev-next-end";
import { getThemeColorByAssetType } from "../../shared/utils/get-theme-color-by-asset-type";
import { getTitleByAssetType } from "../../shared/utils/get-title-by-asset-type";
import { useAppDispatch } from "../../../../store";
import { getTitleByStatus } from "../../shared/utils/get-title-by-status";
import { ColumnName } from "../../shared/enums/column-name";

export function AssetUsageRecordList(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const models = useSelector(getList);
  const isLoading = useSelector(getLoading);
  // const filter = useSelector(getFilter);
  // const {data:models, isLoading, isError, isUninitialized} = useAssetUsageRecordListQuery(filter);

  const listCount = useSelector(getListCount);
  const selectedIds = useSelector(getSelectedIds);
  const isAllSelected = useSelector(getIsAllSelected);
  const visibleColumns = useSelector(getVisibleColumns);
  const visibleColumnIds = useSelector(getVisibleColumnIds);
  const { limit, order, page, handleChangePage, handleChangeLimit, handleChangeOrder } =
    usePaginationContext();

  // if(isLoading || isError || isUninitialized){
  //   return <>isLoading || isError || isUninitialized</>;
  // }

  const handleAllSelectedClick = useCallback(() => {
    dispatch(isAllSelected ? removeSelectedAction(models) : addSelectedAction(models));
  }, [dispatch, isAllSelected, models]);

  const visibleColumnIdsHash = useMemo(
    () => new Set(visibleColumnIds),
    [visibleColumnIds]
  );
  const isVisible = useCallback(
    (columnName) => visibleColumnIdsHash.has(columnName),
    [visibleColumnIdsHash]
  );

  const thBorder = ({ palette }) => ({
    borderRight: `1px solid ${palette.text.disabled}`,
    paddingLeft: "4px",
    paddingRight: "4px",
  });

  return (
    <>
      <TableContainer sx={{ position: "relative" }}>
        <ContainerBackdrop open={isLoading} />
        <Table size={"small"} padding={"none"}>
          <EnhancedTableHead<AssetUsageRecord>
            columns={visibleColumns}
            rowCount={models.length}
            numSelected={selectedIds.length}
            order={order}
            onOrderChange={handleChangeOrder}
            onSelectAllClick={handleAllSelectedClick}
            additionalCols={3}
            // additionalContent->ColumnFilterComponent // HeadCell
          />
          <TableBody>
            {models.map((model: AssetUsageRecord) => (
              <TableRow
                key={model.id}
                sx={{ backgroundColor: getThemeColorByAssetType(model.assetType) }}
              >
                <TableCell padding="checkbox" sx={thBorder}>
                  <Checkbox
                    checked={selectedIds.includes(model.id)}
                    onClick={() => dispatch(toggleSelectedAction([model]))}
                  />
                </TableCell>

                <TableCell sx={thBorder}>{model.farmLandName}</TableCell>

                <TableCell sx={thBorder}>
                  {format(model.dateCreated, ISO8601_DATE_FORMAT)}
                </TableCell>

                <TableCell sx={thBorder}>{model.techOperationSubGroupName}</TableCell>

                {isVisible(ColumnName.PersonCreatedName) && (
                  <>
                    <TableCell sx={thBorder}>{model.personCreatedName}</TableCell>
                  </>
                )}
                {isVisible(ColumnName.PersonResponsibleName) && (
                  <>
                    <TableCell sx={thBorder}>{model.personResponsibleName}</TableCell>
                  </>
                )}
                <TableCell sx={thBorder}>
                  {getTitleByAssetType(model.assetType)}
                </TableCell>

                <TableCell sx={thBorder}>{model.assetName}</TableCell>

                <TableCell sx={thBorder}>{model.quantityTitle}</TableCell>

                <TableCell sx={thBorder}>{model.fieldSize}</TableCell>

                {isVisible(ColumnName.Norma) && (
                  <>
                    <TableCell sx={thBorder}>{model.norma}</TableCell>
                  </>
                )}

                {isVisible(ColumnName.CropTypeName) && (
                  <>
                    <TableCell sx={thBorder}>{model.cropTypeName}</TableCell>
                  </>
                )}
                <TableCell sx={thBorder}>{getTitleByStatus(model.status)}</TableCell>

                {isVisible(ColumnName.Comment) && (
                  <TableCell align={"center"} sx={thBorder}>
                    {model.comment && (
                      <Tooltip placement="top" title={model.comment} /*interactive*/>
                        <CommentIcon color="primary" />
                      </Tooltip>
                    )}
                  </TableCell>
                )}
                <TableCell sx={thBorder}>
                  <IconButton
                    onClick={() => dispatch(setDetails(model))}
                    color={"primary"}
                  >
                    <LiveHelpIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={listCount}
        rowsPerPage={limit}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeLimit}
        labelRowsPerPage="Строк на странице"
        ActionsComponent={TablePaginationStartPrevNextEnd}
      />
    </>
  );
}
