import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useDisableGlobalFilters } from "../../../../modules/filter";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { FarmLandDetails } from "../farm-land-details/farm-land-details";
import { FarmLandsArcgisidRedirect } from "../farm-lands-arcgisid-redirect/farm-lands-arcgisid-redirect";

const FarmLandMaster = lazy(() => import("../farm-land-master/farm-land-master"));

export const FarmLands = (): JSX.Element => {
  useDisableGlobalFilters();

  return (
    <CommonLayout title={"Карточка поля"} contentPadding={0}>
      <Routes>
        <Route index={true} element={<FarmLandMaster />} />
        <Route path={"arcgis/:id"} element={<FarmLandsArcgisidRedirect />} />
        <Route path={":id/*"} element={<FarmLandDetails />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </CommonLayout>
  );
};
