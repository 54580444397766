import { TechOperationAssetEntityType } from "./types/tech-operation-asset-entity";

export const INVENTORY_ITEMS_FIELDS = ["usesChemicals", "usesFertilisers", "usesSeeds"];

export const TECH_OPS_SUB_GROUP = (
  isExistingTechOperation: boolean,
  cropId?: string
) => ({
  usesChemicals: {
    fieldName: "chemicalsMAE",
    optionsLabel: "Тип вносимых СЗР",
    noOptionsText: "Нет доступных СЗР",
    assetEntityType: TechOperationAssetEntityType.CHEMICAL,
  },
  usesFertilisers: {
    fieldName: "fertilisersMAE",
    optionsLabel: "Тип вносимых удобрений",
    noOptionsText: "Нет вносимых удобрений",
    assetEntityType: TechOperationAssetEntityType.FERTILISER,
  },
  usesSeeds: {
    fieldName: "seedsMAE",
    optionsLabel: "Тип используемых семян",
    noOptionsText: "Нет доступных семян",
    assetEntityType: TechOperationAssetEntityType.SEED,
    cropId: cropId,
    isExistingTechOperation: isExistingTechOperation,
  },
});
