import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { AUTH_MODULE_NAME, authSliceReducer } from "./authentication/store/auth.slice";
import {
  APP_USERS_MODULE_NAME,
  appUsersReducer,
  fetchAppUsersAction,
} from "./modules/app-users";
import {
  CHEMICALS_MODULE_NAME,
  chemicalsReducer,
  fetchChemicalsAction,
} from "./modules/chemicals";
import { CROPS_MODULE_NAME, cropsReducer, fetchCropsAction } from "./modules/crop";
import {
  CROP_TYPES_MODULE_NAME,
  cropTypesReducer,
  fetchCropTypesAction,
} from "./modules/crop-types";
import {
  DISEASES_MODULE_NAME,
  diseasesReducer,
  fetchDiseasesAction,
} from "./modules/diseases";
import {
  EMPLOYEES_MODULE_NAME,
  employeesReducer,
  fetchEmployeesAction,
} from "./modules/employees";
import { ERRORS_MODULE_NAME, errorsReducer } from "./modules/errors/store";
import {
  CURRENT_FARM_LANDS_MODULE_NAME,
  currentFarmLandsReducer,
} from "./modules/farm-lands/current-farm-lands.slice";
import { FARMS_MODULE_NAME, farmsReducer } from "./modules/farms";
import {
  FERTILISERS_MODULE_NAME,
  fertilisersReducer,
  fetchFertilisersAction,
} from "./modules/fertilisers";
import {
  FILTERS_MODULE_NAME,
  fetchFiltersAction,
  filtersReducer,
} from "./modules/filter";
import {
  INFO_DATA_KEY,
  fetchCatalogInfoData,
  infoDataReducer,
} from "./modules/info-data";
import { setInfoData } from "./modules/info-data/info-data-actions";
import {
  NOTIFICATIONS_MODULE_NAME,
  addNotificationAction,
  notificationsReducer,
} from "./modules/notifications/store/notifications.slice";
import { PESTS_MODULE_NAME, fetchPestsAction, pestsReducer } from "./modules/pests";
import {
  PHENOPHASES_MODULE_NAME,
  fetchPhenophasesAction,
  phenophasesReducer,
} from "./modules/phenophases";
import { SEEDS_MODULE_NAME, fetchSeedsAction, seedsReducer } from "./modules/seeds";
import {
  TECH_ASSETS_MODULE_NAME,
  fetchTechAssetsAction,
  techAssetsReducer,
} from "./modules/tech-assets";
import { WEEDS_MODULE_NAME, fetchWeedsAction, weedsReducer } from "./modules/weeds";
import {
  CROP_ROTATION_MODULE_NAME,
  cropRotationReducer,
} from "./pages/fields/store/crop-rotation.slice";
import {
  FARM_LANDS_TECH_MODULE_NAME,
  farmLandsTechReducer,
} from "./pages/fields/store/farm-lands-tech.slice";
import {
  FARM_LANDS_WEATHER_MODULE_NAME,
  farmLandsWeatherReducer,
} from "./pages/fields/store/farm-lands-weather.slice";
import {
  FIELDS_MODULE_NAME,
  farmLandsReducer,
} from "./pages/fields/store/farm-lands.slice";
import {
  MAP_CONTROLS_MODULE_NAME,
  MAP_PAGE_MODULE_NAME,
  mapControlsReducer,
  mapReducer,
} from "./pages/map-page";
import {
  OBSERVATION_EDITING_MODULE_NAME,
  observationEditingReducer,
} from "./pages/observations/store/observation-editing.slice";
import {
  OBSERVATIONS_MODULE_NAME,
  observationsReducer,
} from "./pages/observations/store/observations.slice";
import {
  TECHOPERATIONS_MODULE_NAME,
  techOperationsReducer,
} from "./pages/tech-operations";
import {
  TECHOPERATION_EDITING_MODULE_NAME,
  fetchAssetTypesAction,
  techOperationEditingReducer,
} from "./pages/tech-operations/store/tech-operation-editing.slice";
import { NotificationType } from "./modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "./modules/notifications/shared/enums/notification-severity";
import {
  ASSET_USAGE_RECORDS_MODULE_NAME,
  assetUsageRecordsReducer,
} from "./pages/asset-usage-records/store/asset-usage-records.slice";
import { VERIFICATION_MODULE_NAME, verificationReducer } from "./modules/verification";
import { fetchAllSeedsIncludeDeletedAction } from "./modules/seeds/store/seeds.slice";

export const dynamicReducers = {
  [AUTH_MODULE_NAME]: authSliceReducer,
  [INFO_DATA_KEY]: infoDataReducer,
  [APP_USERS_MODULE_NAME]: appUsersReducer,
  [CHEMICALS_MODULE_NAME]: chemicalsReducer,
  [CROP_ROTATION_MODULE_NAME]: cropRotationReducer,
  [CROP_TYPES_MODULE_NAME]: cropTypesReducer,
  [DISEASES_MODULE_NAME]: diseasesReducer,
  [EMPLOYEES_MODULE_NAME]: employeesReducer,
  [FARMS_MODULE_NAME]: farmsReducer,
  [CURRENT_FARM_LANDS_MODULE_NAME]: currentFarmLandsReducer,
  [FIELDS_MODULE_NAME]: farmLandsReducer,
  [FARM_LANDS_TECH_MODULE_NAME]: farmLandsTechReducer,
  [FARM_LANDS_WEATHER_MODULE_NAME]: farmLandsWeatherReducer,
  [FERTILISERS_MODULE_NAME]: fertilisersReducer,
  [MAP_CONTROLS_MODULE_NAME]: mapControlsReducer,
  [MAP_PAGE_MODULE_NAME]: mapReducer,
  [OBSERVATIONS_MODULE_NAME]: observationsReducer,
  [OBSERVATION_EDITING_MODULE_NAME]: observationEditingReducer,
  [PESTS_MODULE_NAME]: pestsReducer,
  [PHENOPHASES_MODULE_NAME]: phenophasesReducer,
  [SEEDS_MODULE_NAME]: seedsReducer,
  [TECH_ASSETS_MODULE_NAME]: techAssetsReducer,
  [TECHOPERATIONS_MODULE_NAME]: techOperationsReducer,
  [TECHOPERATION_EDITING_MODULE_NAME]: techOperationEditingReducer,
  [CROPS_MODULE_NAME]: cropsReducer,
  [FILTERS_MODULE_NAME]: filtersReducer,
  [WEEDS_MODULE_NAME]: weedsReducer,
  [ERRORS_MODULE_NAME]: errorsReducer,
  [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
  [ASSET_USAGE_RECORDS_MODULE_NAME]: assetUsageRecordsReducer,
  [VERIFICATION_MODULE_NAME]: verificationReducer,
} as const;

// TODO: remove it later
export const rootReducer = combineReducers(dynamicReducers);

export const loadDictionariesAction = createAsyncThunk<Promise<void>, void>(
  `GLOBAL/loadDictionaries`,
  async (_, { dispatch }) => {
    try {
      // ASYNCHRONOUS_LOADING
      Promise.all([
        // don't make user wait, 10Mb will land on page later using getTechAssetsLoading
        dispatch(fetchTechAssetsAction()),
        dispatch(fetchAssetTypesAction()),
        dispatch(fetchAllSeedsIncludeDeletedAction()),
      ]);

      // SYNCHRONOUS_LOADING `Загружаются справочники...` in protected-route.tsx
      await Promise.all([
        dispatch(fetchCatalogInfoData()), // TODO: refactor it use redux.thunk
        dispatch(fetchCropTypesAction()).unwrap(),
        dispatch(fetchFiltersAction()).unwrap(),
        // ** Catalogs **
        //MOVED_TO_ASYNCHRONOUS_LOADING dispatch(fetchTechAssetsAction()).unwrap(),
        dispatch(fetchEmployeesAction()).unwrap(),
        dispatch(fetchAppUsersAction()).unwrap(),
        dispatch(fetchChemicalsAction()).unwrap(),
        dispatch(fetchFertilisersAction()).unwrap(),
        dispatch(fetchSeedsAction()).unwrap(),
        dispatch(fetchCropsAction()).unwrap(),
        dispatch(fetchDiseasesAction()).unwrap(),
        dispatch(fetchWeedsAction()).unwrap(),
        dispatch(fetchPestsAction()).unwrap(),
        dispatch(fetchPhenophasesAction()).unwrap(), // network request is still sent when commented out
      ]);
      dispatch(
        setInfoData({
          initialDictionariesLoaded: true,
        })
      );
    } catch (e) {
      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Error,
          error: e,
          message: "При загрузке словарей возникла ошибка",
        })
      );
    }
  }
);
