import { Box, Grid } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { SectionDivider } from "../section-divider/section-divider";

type IProps = PropsWithChildren<{
  filterContent?: React.JSX.Element;
  compositionActions?: React.JSX.Element;
  compositionContent?: React.JSX.Element;
}>;

export const AssetUsageRecordsLayout = (props: IProps): React.JSX.Element => {
  const {
    children,
    filterContent = null,
    compositionActions = null,
    compositionContent = null,
  } = props;

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Grid container={true} direction="row" padding={1} spacing={1}>
        <Grid item={true} xs={11} justifyContent={"center"}>
          <SectionDivider>Сводная таблица</SectionDivider>
        </Grid>

        <Grid item={true} xs={1} textAlign={"center"} justifyContent={"center"}>
          {compositionActions}
        </Grid>

        <Grid item={true} xs={12} sx={{ minHeight: 350, overflowY: "auto" }}>
          {compositionContent}
        </Grid>

        <Grid item={true} xs={12}>
          <SectionDivider>ТМЦ для списания</SectionDivider>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} direction="row" flexWrap="nowrap" spacing={1}>
            <Grid item={true} flexGrow={0}>
              {filterContent}
            </Grid>

            <Grid item={true} flexGrow={1}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
