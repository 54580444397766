import React from "react";
import { endOfDay, isAfter } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { HUMAN_DATE_FORMAT } from "../../../../../constant";
import { IFilterFormData } from "../../../shared/interfaces/filter-form-data";

export const FromField = (): React.JSX.Element => {
  const { control, watch } = useFormContext<IFilterFormData>();
  const to = watch("to");

  return (
    <Controller
      control={control}
      name={"from"}
      rules={{
        required: false,
        validate: (date: Date | null) => {
          if (!date) return true;

          if (isAfter(date, endOfDay(to))) {
            return `Дата "от" не может быть позднее даты "до"`;
          }

          return true;
        },
      }}
      render={({ fieldState: { error }, field }) => (
        <DatePicker
          {...field}
          label={"От"}
          format={HUMAN_DATE_FORMAT}
          maxDate={to}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : "",
              InputLabelProps: {
                required: false,
              },
              InputProps: {
                required: false,
              },
            },
          }}
        />
      )}
    />
  );
};
