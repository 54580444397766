import { makeStyles } from "@mui/styles";

export const catalogTabpanelStyles = makeStyles((theme) => ({
  root: { background: theme.palette.background.paper, flex: 1 },
  inputSearch: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  manualHeader: {
    height: 50,
    color: theme.palette.text.secondary,
    fontSize: 20,
    padding: theme.spacing(1.25, 1),
    borderBottom: "1px solid #d9d9d9",
  },
  manualBody: {},
  manualFooter: {
    bottom: 0,
    height: 70,
    fontWeight: 600,
    padding: theme.spacing(2, 1),
    borderTop: "1px solid #d9d9d9",
  },
}));
