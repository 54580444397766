import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IProps<T> {
  name: string;
  defaultValue?: T;
}

export function RHFInputHidden<T>(props: IProps<T>): JSX.Element {
  const rhfMethods = useFormContext();

  const renderInput = useCallback(({ field }) => <input type="hidden" {...field} />, []);

  return (
    <Controller name={props.name} defaultValue={props.defaultValue} control={rhfMethods.control} render={renderInput} />
  );
}
