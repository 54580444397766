import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { observationPhotoUrl } from "../../../../shared/utils/observation-photo-url";
import {
  getChosenPhotosList,
  getOldPhotosList,
} from "../../store/observation-editing.selector";
import {
  addChosenPhotosAction,
  clearPhotosListsAction,
  deleteChosenPhotoAction,
  markPhotoForDeleteAction,
} from "../../store/observation-editing.slice";
import { PhotoUploadPreview } from "../observation-photos-upload-preview/observation-photos-upload-preview";
import {
  IObservationPhoto,
  findIdenticalObservationPhoto,
} from "../../shared/models/observation-photo.model";
import { findIdenticalFile } from "../../shared/interfaces/observation-editing-state";

const classes = {
  dropzoneParagraph: "ObservationPhotosUploadStyles-dropzoneParagraph",
};
const ObservationPhotosUploadStyles = styled("div")({
  [`& .${classes.dropzoneParagraph}`]: {
    whiteSpace: "pre-line",
  },
});

export const ObservationPhotosUpload = (): JSX.Element => {
  const dispatch = useDispatch();
  const oldPhotos = useSelector(getOldPhotosList);
  const chosenPhotos = useSelector(getChosenPhotosList);
  const [currentChosenPhotosIdx, setCurrentChosenPhotosIdx] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(clearPhotosListsAction());
    };
  }, [dispatch]);

  const onPhotoChoose = useCallback(
    (data) => {
      dispatch(addChosenPhotosAction(data.slice(currentChosenPhotosIdx)));
      setCurrentChosenPhotosIdx(data.length);
    },
    [dispatch, currentChosenPhotosIdx]
  );

  const onOldPhotoDelete = useCallback(
    (id) => {
      dispatch(markPhotoForDeleteAction(id));
    },
    [dispatch]
  );

  const onChosenPhotoDelete = useCallback(
    (idx) => {
      dispatch(deleteChosenPhotoAction(idx));
    },
    [dispatch]
  );

  const getDropRejectMessage = useCallback(
    (file) => `Не удалось выбрать файл ${file.name}`,
    []
  );

  const oldPhotosAccumulator = useRef<Map<number, IObservationPhoto>>(new Map());
  const chosenPhotosAccumulator = useRef<Map<number, File>>(new Map());

  useEffect(() => {
    oldPhotosAccumulator.current = new Map(); // shrink map each render
    oldPhotosAccumulator.current = new Map(); // shrink map each render
  }); // no dependency => invoke each rerender

  return (
    <ObservationPhotosUploadStyles>
      {/*TODO: replace on native style realization (npm react-dropzone)*/}
      <DropzoneArea
        onChange={onPhotoChoose}
        filesLimit={9999}
        acceptedFiles={["image/jpeg"]}
        maxFileSize={99999999}
        dropzoneText={
          "Перетащите фотографии отчета сюда, либо нажмите для выбора файлов\r\n (разрешенные форматы .jpeg, .jpg)"
        }
        getDropRejectMessage={getDropRejectMessage}
        dropzoneParagraphClass={classes.dropzoneParagraph}
        showAlerts={["error"]}
        showPreviews={false}
        showPreviewsInDropzone={false}
      />

      {Boolean(oldPhotos.length || chosenPhotos.length) && (
        <>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Typography variant={"h5"}>Загруженные фотографии</Typography>
          </Box>

          <Grid container={true} spacing={4}>
            {oldPhotos.map((oldPhoto: IObservationPhoto, oldIdx: number) => {
              const foundAmongPrinted: number | undefined = findIdenticalObservationPhoto(
                oldPhoto,
                oldPhotosAccumulator.current
              );
              oldPhotosAccumulator.current.set(oldIdx, oldPhoto);
              return (
                <PhotoUploadPreview
                  key={oldPhoto.id}
                  isMarkedForDelete={oldPhoto.toDelete}
                  src={observationPhotoUrl(oldPhoto, "@h200")}
                  onDelete={() => {
                    onOldPhotoDelete(oldPhoto.id);
                    oldPhotosAccumulator.current.delete(oldIdx);
                  }}
                  warning={
                    foundAmongPrinted !== undefined ? "Удалите дубликат" : undefined
                  }
                />
              );
            })}

            {chosenPhotos.map((chosenPhoto: File, chosenIdx: number) => {
              const foundAmongPrinted: number | undefined = findIdenticalFile(
                chosenPhoto,
                chosenPhotosAccumulator.current
              );
              chosenPhotosAccumulator.current.set(chosenIdx, chosenPhoto);
              return (
                <PhotoUploadPreview
                  key={chosenIdx}
                  src={window.URL.createObjectURL(chosenPhoto)}
                  onDelete={() => {
                    onChosenPhotoDelete(chosenIdx);
                    chosenPhotosAccumulator.current.delete(chosenIdx);
                  }}
                  warning={
                    foundAmongPrinted !== undefined ? "Удалите дубликат" : undefined
                  }
                />
              );
            })}
          </Grid>
        </>
      )}
    </ObservationPhotosUploadStyles>
  );
};
