import { Box } from "@mui/material";
import React from "react";

export const NotFound = (): JSX.Element => (
  <Box
    sx={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      width: "100%",
      minHeight: `50vh`,
    }}
  >
    Страница не найдена
  </Box>
);
