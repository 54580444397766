import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useSelector } from "react-redux";

import { getList as getDiseases } from "../../../../modules/diseases/store";
import { getList as getPests } from "../../../../modules/pests/store";
import { getList as getWeeds } from "../../../../modules/weeds/store";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import {
  ObservationViolationType,
  ObservationViolationTypeHuman as violationTypes,
} from "../../../../shared/enums/observation-violation-type";
import { TLabel } from "../../../../shared/interface";
import { IViolationDto } from "../../shared/dtos/violation.dto";
import { IObservationFormData } from "../../shared/interfaces/observation-form-data";

const violationDefaultValue: IViolationDto = {
  id: "",
  isDeleted: false,
  observationId: "",
  stage: "",
  problemType: ObservationViolationType.Disease,
  name: "",
  problemId: "",
  rate: 0,
};

export const ObservationViolationsList = (): React.JSX.Element => {
  const { control, watch, setValue, getValues } = useFormContext<IObservationFormData>();
  const watchFields = watch();
  const allWeedViolations = useSelector(getWeeds);
  const allDiseaseViolations = useSelector(getDiseases);
  const allPestViolations = useSelector(getPests);

  const [violationsByTypes, setViolationsByTypes] = useState<
    Record<ObservationViolationType, TLabel[]>
  >({
    [ObservationViolationType.Weed]: [],
    [ObservationViolationType.Disease]: [],
    [ObservationViolationType.Pest]: [],
  });

  useEffect(() => {
    (async () => {
      setViolationsByTypes({
        [ObservationViolationType.Weed]: allWeedViolations.map(
          (item) => item.asViolationType
        ), // all
        [ObservationViolationType.Disease]: allDiseaseViolations.map(
          (item) => item.asViolationType
        ), // filter or all
        [ObservationViolationType.Pest]: allPestViolations.map(
          (item) => item.asViolationType
        ), // filter only
      });
    })();
  }, [
    allWeedViolations,
    allDiseaseViolations,
    allPestViolations,
    watchFields.cropTypeId,
  ]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "violations",
    keyName: "_uid",
  });

  const getItemById = useCallback((collection, id) => {
    return collection?.find((item) => item.id === id) || null;
  }, []);

  const isViolationAddingDisabled = useMemo(() => {
    let result = false;
    Object.keys(violationTypes).forEach((key) => {
      if (result || violationsByTypes[key]?.length) {
        result = true;
      }
    });

    return !result;
  }, [violationsByTypes]);

  const onViolationTypeChange = useCallback(
    (idx, value, setViolationProblemType) => {
      setViolationProblemType(value);
      setValue(`violations.${idx}.name`, "");
      setValue(`violations.${idx}.problemId`, "");
      setValue(`violations.${idx}.rate`, 0);
    },
    [setValue]
  );

  const onViolationChange = useCallback(
    (
      idx: number,
      value: TLabel | null,
      setViolationProblemId: ControllerRenderProps["onChange"]
    ) => {
      if (!value) {
        return;
      }
      const { id, title } = value;
      setViolationProblemId(id);
      setValue(`violations.${idx}.name`, title);
    },
    [setValue]
  );

  return (
    <>
      <ListItem>
        <Typography variant={"h5"}>Наличие вредных объектов</Typography>

        <Box
          sx={{
            ml: 2,
          }}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => append({ ...violationDefaultValue })}
            disabled={isViolationAddingDisabled}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Box>
      </ListItem>

      {isViolationAddingDisabled ||
        fields.map((violation, idx) => {
          const problemType = getValues(
            `violations.${idx}.problemType`
          ) as ObservationViolationType;

          return (
            <ListItem key={violation._uid}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.problemType`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={violation.problemType}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl
                        variant="standard"
                        fullWidth={true}
                        error={Boolean(error)}
                      >
                        <InputLabel id={`violations.${idx}.problemType`} required={true}>
                          Тип вредного объекта
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId={`violations.${idx}.problemType`}
                          value={value || ""}
                          onChange={(evt) =>
                            onViolationTypeChange(idx, evt.target.value, onChange)
                          }
                          required={true}
                        >
                          {Object.keys(violationTypes)
                            .filter((key) => violationsByTypes[key]?.length)
                            .map((key) => (
                              <MenuItem
                                key={`violations.${idx}.problemType-${key}`}
                                value={key}
                              >
                                {violationTypes[key]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.name`}
                    control={control}
                    defaultValue={violation.name}
                    render={({ field: { value } }) => (
                      <input type="hidden" value={value || ""} />
                    )}
                  />

                  <RHFAutocomplete<TLabel>
                    name={`violations.${idx}.problemId`}
                    rules={{ required: true }}
                    renderValue={(value) =>
                      getItemById(violationsByTypes[problemType], value)
                    }
                    renderOnChange={(value, onChange) =>
                      onViolationChange(idx, value, onChange)
                    }
                    AutocompleteProps={{
                      disabled: !problemType,
                      options: problemType ? violationsByTypes[problemType] : [],
                      noOptionsText: "Нет возможных нарушений",
                    }}
                    TextFieldProps={{
                      label: "Наименование вредного объекта",
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <Controller
                    control={control}
                    name={`violations.${idx}.rate`}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value <= 0 ? "Значение должно быть больше нуля" : true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="number"
                        label="Кол-во на 1м²"
                        variant="standard"
                        required={true}
                        fullWidth={true}
                        error={Boolean(error)}
                        helperText={error ? error.message : ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item={true}>
                  <IconButton onClick={() => remove(idx)} size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
    </>
  );
};
