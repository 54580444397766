import { Draft, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { IObservationEditingState } from "../shared/interfaces/observation-editing-state";
import { Observation } from "../shared/models/observation";
import { IObservationPhoto } from "../shared/models/observation-photo.model";
import { observationsService } from "../shared/services/observations.service";
import { getObservationEditingState } from "./observation-editing.selector";

const initialState: IObservationEditingState = {
  isLoading: false,
  oldPhotos: [],
  chosenPhotos: [],
  observation: new Observation(),
};
export const OBSERVATION_EDITING_MODULE_NAME = "ObservationEditing";
export const observationEditingSlice = createSlice({
  name: OBSERVATION_EDITING_MODULE_NAME,
  initialState,
  reducers: {
    setObservationData(state, action: PayloadAction<Observation>): void {
      state.observation = action.payload;
    },
    setOldPhotosAction(state, action: PayloadAction<IObservationPhoto[]>): void {
      state.oldPhotos = action.payload;
    },
    addOldPhotosAction(state, action: PayloadAction<IObservationPhoto[]>): void {
      state.oldPhotos = [...state.oldPhotos, ...action.payload];
    },
    markPhotoForDeleteAction(state, action: PayloadAction<string>): void {
      state.oldPhotos = state.oldPhotos.map((photo) => {
        if (photo.id !== action.payload) {
          return photo;
        }
        return {
          ...photo,
          toDelete: !photo.toDelete,
        };
      });
    },
    deleteOldPhotoAction(state, action: PayloadAction<string>): void {
      state.oldPhotos = state.oldPhotos.filter((photo) => photo.id !== action.payload);
    },
    setChosenPhotosAction(state, action: PayloadAction<File[]>): void {
      state.chosenPhotos = action.payload;
    },
    addChosenPhotosAction(state, action: PayloadAction<File[]>): void {
      state.chosenPhotos = [...state.chosenPhotos, ...action.payload];
    },
    deleteChosenPhotoAction(state, action: PayloadAction<number>): void {
      state.chosenPhotos = [...state.chosenPhotos.filter((_, idx) => idx !== action.payload)];
    },
    clearPhotosListsAction(state): void {
      state.oldPhotos = [];
      state.chosenPhotos = [];
    },
  },
  extraReducers(builder) {
    const enableLoading = (state: Draft<IObservationEditingState>): void => {
      state.isLoading = true;
    };
    const disableLoading = (state: Draft<IObservationEditingState>): void => {
      state.isLoading = false;
    };

    builder
      .addCase(fetchObservationByIdAction.pending, enableLoading)
      .addCase(fetchObservationByIdAction.fulfilled, disableLoading)
      .addCase(fetchObservationByIdAction.rejected, disableLoading)
      .addCase(addObservationAction.pending, enableLoading)
      .addCase(addObservationAction.fulfilled, disableLoading)
      .addCase(addObservationAction.rejected, disableLoading)
      .addCase(updateObservationAction.pending, enableLoading)
      .addCase(updateObservationAction.fulfilled, disableLoading)
      .addCase(updateObservationAction.rejected, disableLoading)
      .addCase(uploadChosenPhotos.pending, enableLoading)
      .addCase(uploadChosenPhotos.fulfilled, disableLoading)
      .addCase(uploadChosenPhotos.rejected, disableLoading);
  },
});

export const observationEditingReducer = observationEditingSlice.reducer;
export const {
  setObservationData,
  setOldPhotosAction,
  addOldPhotosAction,
  markPhotoForDeleteAction,
  deleteOldPhotoAction,
  setChosenPhotosAction,
  addChosenPhotosAction,
  deleteChosenPhotoAction,
  clearPhotosListsAction,
} = observationEditingSlice.actions;

export const fetchObservationByIdAction = createAsyncThunk<Observation, string>(
  `${OBSERVATION_EDITING_MODULE_NAME}`,
  async (observationId, { dispatch }) => {
    const dto = await observationsService.get(observationId);
    const model = new Observation(observationId);
    model.updateFromDto(dto);
    dispatch(setObservationData(model));
    dispatch(setOldPhotosAction(model.observationPhotos));
    return model;
  }
);

export const addObservationAction = createAsyncThunk<Observation, Observation>(
  `${OBSERVATION_EDITING_MODULE_NAME}/addObservation`,
  async (data, { dispatch }) => {
    data.resetCreatedOnClientAt();
    const dto = await observationsService.add(data.asDto);
    const newModel = new Observation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setObservationData(newModel));
    return newModel;
  }
);

export const updateObservationAction = createAsyncThunk<Observation, Observation>(
  `${OBSERVATION_EDITING_MODULE_NAME}/updateObservation`,
  async (data, { dispatch, getState }) => {
    const dto = await observationsService.update(data.asDto);
    const newModel = new Observation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setObservationData(newModel));
    const { oldPhotos } = getObservationEditingState(getState() as RootState);
    oldPhotos.forEach((photo) => {
      if (photo.toDelete) {
        dispatch(deleteExistingPhotoAction(photo.id));
      }
    });
    return newModel;
  }
);

export const deleteExistingPhotoAction = createAsyncThunk<void, string>(
  `${OBSERVATION_EDITING_MODULE_NAME}/deleteObservationPhoto`,
  async (id, { dispatch }) => {
    dispatch(deleteOldPhotoAction(id));
    return observationsService.deletePhoto(id);
  }
);

export const uploadChosenPhotos = createAsyncThunk<void, void>(
  `${OBSERVATION_EDITING_MODULE_NAME}/uploadObservationPhoto`,
  async (_, { dispatch, getState }) => {
    const observationId = getObservationEditingState(getState() as RootState).observation.id;
    const chosenPhotos = getObservationEditingState(getState() as RootState).chosenPhotos;
    if (!chosenPhotos.length) {
      return;
    }

    const result = await observationsService.uploadPhotos(observationId, chosenPhotos);

    dispatch(addOldPhotosAction(result));
    dispatch(setChosenPhotosAction([]));
  }
);
