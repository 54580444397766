import { useEffect } from "react";
import { getFilter } from "../../../modules/filter";
import { fetchGeoJsonAction } from "../store/map-page.slice";
import { deverror } from "../../../shared/utils/log";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { FilterName } from "../../../modules/filter/shared/enums/filter-name";
import {
  getGeoJsonAlreadyFetchedForFarmSeason,
  getIsLoadingGeoJson,
} from "../store/map-page.selector";

export function useLoadGeoJson(skipFetch = false) {
  const dispatch = useAppDispatch();

  const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));
  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));
  const isLoadingGeoJson = useSelector(getIsLoadingGeoJson);
  const geoJsonAlreadyFetchedForFarmSeason: string = useSelector(
    getGeoJsonAlreadyFetchedForFarmSeason
  );

  useEffect(() => {
    if (skipFetch) {
      return;
    }

    if (appFilterFarmId === null || appFilterSeasonId === null) {
      return;
    }

    // went to farmLand card, back, changed UPPER_FARM => clean the cache
    // dispatch(setFarmFeaturesAction(undefined));

    if (isLoadingGeoJson) {
      return;
    }

    const stringComparedByValue = `farmId:[${appFilterFarmId}]:seasonId[${appFilterSeasonId}]`;
    if (geoJsonAlreadyFetchedForFarmSeason === stringComparedByValue) {
      return; // resolves infinite backend request to /api/FarmLands/getFarmLandGeometriesBySeasonAndFarm
    }

    dispatch(
      fetchGeoJsonAction({
        farmId: appFilterFarmId,
        seasonId: appFilterSeasonId,
      })
    )
      .unwrap()
      .catch((ex) => {
        const url = `/api/FarmLands/getFarmLandGeometriesBySeasonAndFarm:`;
        deverror(`${fetchGeoJsonAction.typePrefix} LOAD_GEOJSON_FAILED ${url}`, ex);
      });

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [
    dispatch,
    appFilterFarmId,
    appFilterSeasonId,
    skipFetch,
    // INFINITE_LOOP isLoadingGeoJson,
    // INFINITE_LOOP geoJsonAlreadyFetchedForFarmSeason,
    // ArcgisFarmName,
    // Season,
    // arcgisToken,
  ]);

  // return { isLoadingGeoJson };
}
