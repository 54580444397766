import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { TechAssetWithInclude } from "../../../../modules/tech-assets/shared/models/tech-assets";
import {
  getListWithInclude as getTechAssetsListWithInclude,
  getTechAssetsLoading,
} from "../../../../modules/tech-assets/store";
import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";
import { RHFAutocompleteSimple } from "../../../../shared/components/react-hook-form-mui/autocomplete-simple";
import { TechAssetType } from "../../../../shared/enums/tech-asset-type";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOpsTestIds } from "../../shared/types/tech-operation-data-test";

export const TechOperationEditingTechAssets = ({
  isRequired = false,
  isExistingTechOperation,
  disabled,
}: {
  isRequired: boolean;
  isExistingTechOperation: boolean;
  disabled?: boolean;
}): JSX.Element => {
  const rhfMethods = useFormContext<ITechOperationFormData>();
  const watchFields = rhfMethods.watch();

  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));

  const isTechAssetsLoading = useSelector(getTechAssetsLoading);
  const techAssets: TechAssetWithInclude[] = useSelector(getTechAssetsListWithInclude);

  const [techAssetAllowAllFarms, setTechAssetAllowAllFarms] = useState(false);
  const [techAssetAllowAllGroups, setTechAssetAllowAllGroups] = useState(false);

  useEffect(() => {
    if (!watchFields.farmId || !appFilterSeasonId || !isExistingTechOperation) {
      return;
    }

    setTechAssetAllowAllFarms(true);
    setTechAssetAllowAllGroups(true);
  }, [watchFields.farmId, appFilterSeasonId, isExistingTechOperation]);

  const fromOtherFarms = useCallback(
    (item: TechAssetWithInclude): boolean => {
      if (item.farms === undefined) {
        return false; // TODO: test with breakpoint here; remove if never hit
      }

      //item.farms?.find((farm) => farm.id === watchFields.farmId)
      const ret = item.farms.has(watchFields.farmId);
      return ret;
    },
    [watchFields.farmId]
  );

  const fromAllSubGroups = useCallback(
    (item: TechAssetWithInclude): boolean => {
      if (item.techOperationSubGroups === undefined) {
        return false; // TODO: test with breakpoint here; remove if never hit
      }
      if (watchFields.techOperationSubGroupId === undefined) {
        return false; // TODO: test with breakpoint here; remove if never hit
      }

      // item.techOperationSubGroups?.find((group) => group.id === watchFields.techOperationSubGroupId)
      const ret = item.techOperationSubGroups.has(watchFields.techOperationSubGroupId);
      return ret;
    },
    [watchFields.techOperationSubGroupId]
  );

  const availableTechAssets: TechAssetWithInclude[] = useMemo(
    () => {
      if (techAssets === undefined || techAssets.length === 0) {
        return []; // TODO: test with breakpoint here; remove if never hit
      }

      const techOrBoth = [TechAssetType.TECH, TechAssetType.BOTH];
      const ret = techAssets.filter(
        (item) =>
          techOrBoth.includes(item.category) &&
          (techAssetAllowAllFarms || fromOtherFarms(item)) &&
          (techAssetAllowAllGroups || fromAllSubGroups(item))
      );
      return ret;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      techAssets,
      techAssetAllowAllFarms,
      techAssetAllowAllGroups,
      watchFields.farmId, // should depend as fromOtherFarms() depends on it
      watchFields.techOperationSubGroupId, // should depend as fromAllGroups() depends on it
      fromOtherFarms,
      fromAllSubGroups,
    ]
  );

  const availableTrailerAssets: TechAssetWithInclude[] = useMemo(
    () => {
      if (techAssets === undefined) {
        return []; // TODO: test with breakpoint here; remove if never hit
      }

      const trailerOrBoth = [TechAssetType.TRAILER, TechAssetType.BOTH];
      const ret = techAssets.filter(
        (item) =>
          trailerOrBoth.includes(item.category) &&
          (techAssetAllowAllFarms || fromOtherFarms(item)) &&
          (techAssetAllowAllGroups || fromAllSubGroups(item))
      );
      ret.unshift({
        id: "-1",
        name: "Без прицепной техники",
      } as unknown as TechAssetWithInclude);
      return ret;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      techAssets,
      techAssetAllowAllFarms,
      techAssetAllowAllGroups,
      watchFields.farmId, // should depend as fromOtherFarms() depends on it
      watchFields.techOperationSubGroupId, // should depend as fromAllGroups() depends on it
      fromOtherFarms,
      fromAllSubGroups,
    ]
  );

  if (!techAssets) {
    return <></>;
  }

  if (isTechAssetsLoading) {
    return (
      <Grid item>
        <SpinnerCenter
          minHeight="4vh"
          msg={"Загружается Самоходная и Прицепная техника..."}
        />
      </Grid>
    );
  }

  return (
    <Grid container={true} spacing={2} alignItems={"center"}>
      <Grid item={true} xs={4}>
        <RHFAutocompleteSimple<TechAssetWithInclude>
          name="techAssetId"
          dataTest={TechOpsTestIds.TechOpsEditTechAssetsSelfPropelledVehicles}
          defaultValue={""} // added by button, before it's selected/filled
          rules={{ required: isRequired }}
          optionGuidToObject={(optionGuid) => {
            const ret = availableTechAssets.find((x) => x.id === optionGuid);
            return ret;
          }}
          disabled={!watchFields.farmLandId || disabled}
          options={availableTechAssets}
          noOptionsText={"Самоходной техники не найдено"}
          textFieldProps={{
            required: isRequired,
            label: `Самоходная техника (${availableTechAssets.length})`,
          }}
          deduplicate={true} // undefined => will deduplicate for ["development"]
        />
      </Grid>

      <Grid item={true} xs={4}>
        <RHFAutocompleteSimple<TechAssetWithInclude>
          name="trailerAssetId"
          dataTest={TechOpsTestIds.TechOpsEditTechAssetsTrailedVehicles}
          optionGuidToObject={(optionGuid) => {
            const ret = availableTrailerAssets.find((x) => x.id === optionGuid);
            return ret;
          }}
          disabled={!watchFields.farmLandId || disabled}
          options={availableTrailerAssets}
          noOptionsText={"Прицепной техники не найдено"}
          textFieldProps={{
            label: `Прицепная техника (${availableTrailerAssets.length})`,
          }}
          deduplicate={true} // undefined => will deduplicate for ["development"]
        />
      </Grid>

      <Grid item={true}>
        <FormControlLabel
          data-test={TechOpsTestIds.TechOpsEditTechAssetsFromAllFarms}
          control={
            <Switch
              onChange={(evt) => setTechAssetAllowAllFarms(evt.target.checked)}
              checked={techAssetAllowAllFarms}
              disabled={disabled}
              color={"primary"}
              id={TechOpsTestIds.TechOpsEditTechAssetsFromAllFarms}
            />
          }
          label="Из всех хозяйств"
        />
      </Grid>

      <Grid item={true}>
        <FormControlLabel
          data-test={TechOpsTestIds.TechOpsEditTechAssetsAllVehicles}
          control={
            <Switch
              onChange={(evt) => setTechAssetAllowAllGroups(evt.target.checked)}
              checked={techAssetAllowAllGroups}
              disabled={disabled}
              color={"primary"}
              id={TechOpsTestIds.TechOpsEditTechAssetsAllVehicles}
            />
          }
          label="Вся техника"
        />
      </Grid>
    </Grid>
  );
};
