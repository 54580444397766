import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import * as applyFilter from "loopback-filters";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
} from "@mui/material";

import { ContainerBackdrop } from "../../../../shared/components/container-backdrop/container-backdrop";
import {
  getCompositions,
  getLoading,
  getSelected,
} from "../../store/asset-usage-records.selector";
import { usePagination } from "../../../../modules/filter/shared/utils/use-pagination";
import { EnhancedTableHead } from "../../../../components/enhanced-table-head/enhanced-table-head";
import { getThemeColorByAssetType } from "../../shared/utils/get-theme-color-by-asset-type";
import { getTitleByAssetType } from "../../shared/utils/get-title-by-asset-type";
import { AssetUsageComposition } from "../../shared/models/asset-usage-composition";
import { useAppDispatch } from "../../../../store";
import { removeSelectedAction } from "../../store/asset-usage-records.slice";
import { loopBackFilterAdapter } from "../../../../modules/filter/shared/utils/loopback-filter-adapter";
import { TablePaginationStartPrevNextEnd } from "../../../../shared/components/table-pagination-start-prev-next-end/table-pagination-start-prev-next-end";
import TablePagination from "@mui/material/TablePagination";

export function AssetUsageCompositions(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const models = useSelector(getCompositions);
  const selected = useSelector(getSelected);
  const isLoading = useSelector(getLoading);
  const {
    order,
    handleChangeOrder,
    limit,
    page,
    offset,
    handleChangePage,
    handleChangeLimit,
  } = usePagination(5);

  const modelsToShow = useMemo(() => {
    return applyFilter([...models], loopBackFilterAdapter({ limit, order, offset }, {}));
  }, [limit, order, offset, models]);

  const thBorder = ({ palette }) => ({
    borderRight: `1px solid ${palette.text.disabled}`,
    paddingLeft: "4px",
  });

  return (
    <>
      <TableContainer sx={{ position: "relative" }}>
        <ContainerBackdrop open={isLoading} />
        <Table size={"small"}>
          <EnhancedTableHead<AssetUsageComposition>
            columns={[
              { id: "personResponsibleId", label: "Мол или склад" },
              { id: "assetType", label: "Вид ТМЦ" },
              { id: "cropTypeNames", label: "Культура" },
              { id: "assetName", label: "Наименование" },
              { id: "quantity", label: "Количество ТМЦ" },
              { id: "fieldSize", label: "Площадь" },
              { id: "norma", label: "Норма" },
            ]}
            order={order}
            onOrderChange={handleChangeOrder}
            // TODO: fix table header when we don't need checkboxes
            rowCount={modelsToShow.length}
            numSelected={modelsToShow.length}
            onSelectAllClick={() => dispatch(removeSelectedAction(selected))}
          />

          <TableBody>
            {modelsToShow.map((model) => (
              <TableRow
                key={model.id}
                sx={{ backgroundColor: getThemeColorByAssetType(model.assetType) }}
              >
                <TableCell padding="checkbox" sx={thBorder}>
                  <Checkbox
                    checked={true}
                    onClick={() => dispatch(removeSelectedAction(model.items))}
                  />
                </TableCell>
                <TableCell sx={thBorder}>{model.personResponsibleName}</TableCell>
                <TableCell sx={thBorder}>
                  {getTitleByAssetType(model.assetType)}
                </TableCell>
                <TableCell sx={thBorder}>{model.cropTypeNames}</TableCell>
                <TableCell sx={thBorder}>{model.assetName}</TableCell>
                <TableCell sx={thBorder}>{model.quantityTitle}</TableCell>
                <TableCell sx={thBorder}>{model.fieldSize}</TableCell>
                <TableCell sx={thBorder}>{model.norma}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={models.length}
        rowsPerPage={limit}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeLimit}
        labelRowsPerPage="Строк на странице"
        ActionsComponent={TablePaginationStartPrevNextEnd}
      />
    </>
  );
}
