import { useCallback, useEffect, useRef, useState } from "react";

import { deverror, devlog, devwarn } from "../../../../shared/utils/log";

export type DeduplicateArgs<RESPONSE> = {
  dataRequestKey: string;
  returnPreviousResponseOnKeyChange?: boolean;
  backendRequest: () => Promise<RESPONSE>;
  onSuccess?: (list: RESPONSE) => void;
  onError?: (rejectedValueOrSerializedError) => void;
};

export type Deduplicator<RESPONSE> = {
  isLoadingRef: React.MutableRefObject<boolean>;
  isLoading: boolean;
  isNetworkPending: (dataRequestKey: string) => boolean;
  blockUntilLoaded: (dataRequestKey: string) => Promise<RESPONSE | undefined>;
  deduplicate: (handlers: DeduplicateArgs<RESPONSE>) => Promise<RESPONSE | undefined>;
  // currentCachedKey: string | null;
};

export function useDeduplicator<RESPONSE>(
  invoker: string,
  repeatOnSuccessWhenCached = false
): Deduplicator<RESPONSE> {
  const ident = `${invoker}:DEDUPLICATOR`;

  const [isLoading, setLoading] = useState(false);
  const isLoadingRef = useRef<boolean>(false);

  const cachedKeyRef = useRef<string | null>(null);
  const promiseNetworkPendingRef = useRef<Promise<RESPONSE> | null>(null);
  const cachedResponseRef = useRef<RESPONSE | null>(null);
  const servedFromCacheCountRef = useRef<number>(0);

  const hasResponseUnderKey = useCallback(
    (dataRequestKey: string): boolean => {
      const ret: boolean =
        dataRequestKey === cachedKeyRef.current && cachedResponseRef.current !== null;
      return ret;
    },
    [cachedKeyRef, cachedResponseRef]
  );

  const isNetworkPending = useCallback(
    (dataRequestKey: string): boolean => {
      // null when NOT awaiting backend's response
      const ret: boolean =
        dataRequestKey === cachedKeyRef.current && promiseNetworkPendingRef !== null;
      return ret;
    },
    [cachedKeyRef, promiseNetworkPendingRef]
  );

  const blockUntilLoaded = useCallback(
    async (dataRequestKey: string): Promise<RESPONSE | undefined> => {
      if (!isNetworkPending(dataRequestKey)) {
        return Promise.resolve(cachedResponseRef.current ?? undefined); // .resolve()d intentionally
      }

      // I need farmLands earlier than setFarmLands() invokes rerender
      if (promiseNetworkPendingRef.current !== null) {
        // happens when loadFarmLands() was invoked for 2nd time
        // while previous network request is still running
        // NOT_SET_YET return cachedResultRef.current ?? undefined;
        try {
          const resolvedWith: RESPONSE = await promiseNetworkPendingRef.current;
          return Promise.resolve(resolvedWith); // .resolve()d intentionally
        } catch (err) {
          devwarn(`${ident}_blockUntilLoaded(): WAITING_FAILED`, err);
          return undefined;
        }
      }
      // once fetched, subsequential loadFarmLands() will return cached farms list
      // I return ref value because such second call happens
      // BEFORE farmLands state variable gets filled as a result of setFarmLands()
      return Promise.resolve(cachedResponseRef.current ?? undefined); // .resolve()d intentionally
    },
    [isNetworkPending, promiseNetworkPendingRef, ident]
  );

  const deduplicate = useCallback(
    async (handlers: DeduplicateArgs<RESPONSE>): Promise<RESPONSE | undefined> => {
      const {
        dataRequestKey,
        backendRequest,
        onSuccess,
        onError,
        returnPreviousResponseOnKeyChange,
      } = handlers;

      if (isNetworkPending(dataRequestKey)) {
        // eslint-disable-next-line no-console
        devwarn(`${ident}_AWAITING_PREVIOUS_BACKEND_RESPONSE_UNDER_KEY:
          [${cachedKeyRef.current}] / [${dataRequestKey}]`);

        const shouldBeEqualToCachedResponse = await blockUntilLoaded(dataRequestKey);

        servedFromCacheCountRef.current++;
        // eslint-disable-next-line no-console
        devwarn(
          `${ident}_GOT_CACHED_BACKEND_RESPONSE_UNDER_KEY:
          [${cachedKeyRef.current}] / [${dataRequestKey}] servedFromCacheCount[${servedFromCacheCountRef.current}]`,
          [cachedResponseRef.current, shouldBeEqualToCachedResponse]
        );

        // setSeedsForCrop() has now finished, previous setSeedsForCrop() re-renders
        // simultaneous backed request for the same data
        if (cachedResponseRef.current !== null) {
          if (repeatOnSuccessWhenCached && onSuccess) {
            try {
              onSuccess(cachedResponseRef.current);
            } catch (err) {
              deverror(`${ident} FAILED_isNetworkPending_onSuccess() `, err);
            }
          }
          return Promise.resolve(cachedResponseRef.current); // .resolve()d intentionally
        }
        return Promise.resolve(undefined); // .resolve()d intentionally
      }

      if (hasResponseUnderKey(dataRequestKey)) {
        servedFromCacheCountRef.current++;
        // eslint-disable-next-line no-console
        devwarn(`${ident}_HAS_PREVIOUS_BACKEND_RESPONSE_UNDER_KEY:
          [${cachedKeyRef.current}] / [${dataRequestKey}] servedFromCacheCount[${servedFromCacheCountRef.current}]`);

        if (cachedResponseRef.current !== null) {
          if (repeatOnSuccessWhenCached && onSuccess) {
            try {
              onSuccess(cachedResponseRef.current);
            } catch (err) {
              deverror(`${ident} FAILED_hasResponseUnderKey_onSuccess() `, err);
            }
          }
          return Promise.resolve(cachedResponseRef.current); // .resolve()d intentionally
        }
        return Promise.resolve(undefined); // .resolve()d intentionally
      }

      if (cachedKeyRef.current !== null && cachedKeyRef.current !== dataRequestKey) {
        if (returnPreviousResponseOnKeyChange) {
          deverror(`${ident}_CHANGING_CACHE_KEY_WITHOUT_REQUEST:
            [${cachedKeyRef.current}] => [${dataRequestKey}] servedFromCacheCount[${servedFromCacheCountRef.current}]`);
          cachedKeyRef.current = dataRequestKey;

          const waitOnMe: Promise<RESPONSE | undefined> =
            blockUntilLoaded(dataRequestKey);
          return waitOnMe;
        }

        servedFromCacheCountRef.current = 0;
        // eslint-disable-next-line no-console
        deverror(`${ident}_CHANGED_CACHE_KEY:
          [${cachedKeyRef.current}] => [${dataRequestKey}] servedFromCacheCount[${servedFromCacheCountRef.current}]`);
      }

      devlog(`${ident}_STARTED_REQUEST_UNDER_KEY:
        [${cachedKeyRef.current}] => [${dataRequestKey}]`);

      setLoading(true);
      isLoadingRef.current = true;
      cachedKeyRef.current = dataRequestKey;

      const promise: Promise<RESPONSE> = backendRequest();

      promiseNetworkPendingRef.current = promise;
      promise
        .then((list: RESPONSE) => {
          cachedResponseRef.current = list;

          devlog(
            `${ident}_FINISHED_REQUEST_UNDER_KEY:
            [${cachedKeyRef.current}], cachedResponse:`,
            cachedResponseRef.current
          );

          if (onSuccess) {
            try {
              onSuccess(list);
            } catch (err) {
              deverror(`${ident} FAILED_onSuccess() `, err);
            }
          }
          return list; // read by promiseRunningRef.current.unwrap() in blockUntilLoaded()
        })
        .catch((rejectedValueOrSerializedError) => {
          // eslint-disable-next-line no-console
          devwarn(
            `${ident}_CAUGHT_EXCEPTION__CLEANING_KEY_AND_CACHE:
            [${cachedKeyRef.current}] => [${dataRequestKey}]`,
            rejectedValueOrSerializedError
          );
          cachedKeyRef.current = null;
          cachedResponseRef.current = null;

          try {
            onError?.(rejectedValueOrSerializedError);
          } catch (err) {
            deverror(`${ident} FAILED_onError() `, [err, rejectedValueOrSerializedError]);
          }
          return rejectedValueOrSerializedError; // nobody receives it
        })
        .finally(() => {
          setLoading(false); // triggers <CircularProgress /> re-render
          isLoadingRef.current = false;
          promiseNetworkPendingRef.current = null;
        });

      // cachedResultRef promisified => blocked only when "await deduplicate()""
      const waitOnMe: Promise<RESPONSE | undefined> = blockUntilLoaded(dataRequestKey);
      return waitOnMe;
    },
    [
      isNetworkPending,
      hasResponseUnderKey, // aaa
      repeatOnSuccessWhenCached,
      blockUntilLoaded,
      setLoading,
      isLoadingRef,
      promiseNetworkPendingRef,
      cachedKeyRef,
      ident,
    ]
  );

  useEffect(() => {
    function unregisterOnUnmount_cleanupFunction() {
      devlog(`UNMOUNTED ${ident}`);
    }
    return unregisterOnUnmount_cleanupFunction;
    // eslint-disable-next-line
  }, []);

  return {
    isLoadingRef,
    isLoading,
    isNetworkPending,
    blockUntilLoaded,
    deduplicate,
    // currentCachedKey: cachedKeyRef.current,
  };
}
