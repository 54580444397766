import { IFilterFormData } from "../interfaces/filter-form-data";
import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";
import { TAssetUsageFilter } from "../interfaces/asset-usage-filter";

export function stateFilterToFilterFormData(state: TAssetUsageFilter): IFilterFormData {
  const {
    toDate = new Date(),
    fromDate = new Date(),
    assetCategories = [],
    materiallyAccountableEmployees = [],
    statusIds = [],
  } = state;

  return {
    to: new Date(toDate),
    from: new Date(fromDate),
    assetCategories,
    materiallyAccountableEmployees,
    isActive: statusIds.includes(AssetUsageRecordStatus.Created),
  };
}
