import React from "react";

import Grid2 from "@mui/material/Unstable_Grid2";

import { TechOpEditingAssetsTabsBox } from "./tech-operation-editing-assets-tabs-box";
import { TechopVisibleFieldsEdit } from "./tech-operation-visible-fields-edit";
import { TechOperationAssetsGroupType } from "../../shared/types";

export const TechOperationAssetsGroup = ({
  optionsLabel,
  noOptionsText,
  cropId,
  isExistingTechOperation,
  assetEntityType,
  disabled,
  allStocks,
  tabs,
  setTabs,
  destructAndSetFields,
  controlledFields,
}: TechOperationAssetsGroupType): React.JSX.Element => {
  if (!controlledFields) {
    return <>NO FIELDS</>;
  }

  return (
    <Grid2 container={true} xs={12}>
      <TechOpEditingAssetsTabsBox
        disabled={disabled}
        tabs={tabs}
        setTabs={setTabs}
        assetEntityType={assetEntityType}
        controlledFields={controlledFields}
        destructAndSetFields={destructAndSetFields}
      />
      <TechopVisibleFieldsEdit
        controlledFields={controlledFields}
        destructAndSetFields={destructAndSetFields}
        disabled={disabled}
        tabs={tabs}
        setTabs={setTabs}
        optionsLabel={optionsLabel}
        noOptionsText={noOptionsText}
        cropId={cropId}
        isExistingTechOperation={isExistingTechOperation}
        assetEntityType={assetEntityType}
        allStocks={allStocks}
      />
    </Grid2>
  );
};
