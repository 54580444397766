import { Button, CircularProgress, Tooltip, Zoom } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";
import React, { PropsWithChildren, useState } from "react";

export function IconTooltipClick({
  isLoading,
  onDoubleClick,
  iconSize = 28,
  paddingY,
  children,
}: PropsWithChildren<{
  isLoading: boolean;
  onDoubleClick?: () => void;
  paddingY?: number;
  iconSize?: number;
}>): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const toggleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      TransitionComponent={Zoom}
      placement="bottom-end"
      title={<React.Fragment>{children}</React.Fragment>}
    >
      <Button
        onClick={toggleTooltipOpen}
        onDoubleClick={onDoubleClick}
        sx={{ py: paddingY }}
      >
        {isLoading ? (
          <CircularProgress size={iconSize} />
        ) : (
          <HelpIcon
            color="primary"
            fontSize="large"
            sx={{ height: iconSize, width: iconSize }}
          />
        )}
      </Button>
    </Tooltip>
  );
}
