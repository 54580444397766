import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFarmsState } from "../shared";
import { MODULE_NAME } from "./farms.slice";
import { RootState } from "../../../store-types"

export const getFarmsState = (rootState: RootState): IFarmsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getFarmsState, (state: IFarmsState) => state.isLoading);
export const getList = createDraftSafeSelector(getFarmsState, (state: IFarmsState) => state.list);

export const getSortedList = createDraftSafeSelector(getFarmsState, (state: IFarmsState) => {
  const sortedList = state.list.concat();
  sortedList.sort((a, b) => (a.name > b.name ? 1 : -1));
  return sortedList;
});
