import { Box } from "@mui/material";
import { Feature, GeometryObject } from "geojson";
import { GeoJSON, Polyline } from "leaflet";
import React, { useCallback, useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CircularProgressComponent } from "../../../../components/progress";
import { GeoJsonWithUpdates } from "../../../../shared/components/geo-json-with-updates/geo-json-with-updates";
import { useAppDispatch } from "../../../../store";
import { TArcgisMapCropPropsDto } from "../../shared/dtos/arcgis-map-crop-props.dto";
import { useCropStyles } from "../../shared/utils/use-crop-styles";
import { getSearchedNames } from "../../store/map-controls.selector";
import {
  getBounds,
  getGeoJson,
  getHighlightedFarmLandId,
  getSelectedCropNames,
  getIsLoadingGeoJson,
} from "../../store/map-page.selector";
import { setBoundsAction, setHighlightedFarmLandId } from "../../store/map-page.slice";
import { useLoadGeoJson } from "../../hooks/use-load-geojson";

export const LayerPreloader = (): JSX.Element => {
  // const geoJson: GeoJSON<TArcgisMapCropPropsDto> = useSelector(getGeoJson);
  // const noData = !geoJson || !geoJson.getLayers().length;

  const isLoadingGeoJson = useSelector(getIsLoadingGeoJson);
  const isShown = isLoadingGeoJson;

  return (
    <Box
      sx={{
        position: "absolute",
        left: "-56px",
        width: "104%",
        height: "100%",
      }}
    >
      <CircularProgressComponent show={isShown} />
    </Box>
  );
};

type FarmLandsLayerProps = {
  setFeature: React.Dispatch<React.SetStateAction<TArcgisMapCropPropsDto | null>>;
};

export const FarmLandsLayer = (props: FarmLandsLayerProps): JSX.Element => {
  const { setFeature } = props;
  const map = useMap();
  const navigate = useNavigate();
  const { style } = useCropStyles();
  const dispatch = useAppDispatch();

  useLoadGeoJson();

  const geoJson: GeoJSON<TArcgisMapCropPropsDto> = useSelector(getGeoJson);
  const bounds = useSelector(getBounds);
  const selectedCropNames = useSelector(getSelectedCropNames);
  const searchedNames = useSelector(getSearchedNames);

  const highlightedFarmLandId = useSelector(getHighlightedFarmLandId);

  const geoJsonObject = useMemo(() => geoJson.toGeoJSON(), [geoJson]);

  const onEachFeature = useCallback(
    (feature: Feature<GeometryObject, TArcgisMapCropPropsDto>, layer) => {
      const { properties } = feature;

      layer.on({
        mouseover: () => {
          setFeature(properties);
        },
        mouseout: () => {
          setFeature(null);
        },
        click: () => {
          if (properties.farmLandId) {
            // lowercased URLS work okay: https://ekocrop-test.ekoniva-apk.org
            // /api/FarmLands/68ca7a65-8702-4027-b04b-cc3dad1adb60
            const backwardCompatible = properties.farmLandId.toLocaleUpperCase();
            navigate(`/fields/${backwardCompatible}`);
          } else {
            // REMOVE_AFTER_SWITCH_TO_GeoJsonService.getMapData()
            navigate(`/fields/arcgis/${properties.GDB_ARCHIVE_OID}`);
          }
        },
      });
    },
    [setFeature, navigate]
  );

  const filter = useCallback(
    (feature: Feature<GeometryObject, TArcgisMapCropPropsDto>) => {
      // filter by crops
      if (
        selectedCropNames.length &&
        !selectedCropNames.includes(feature.properties.crop)
      ) {
        return false;
      }

      // filter by name
      if (searchedNames.length && !searchedNames.includes(feature.properties.name)) {
        return false;
      }

      return true;
    },
    [selectedCropNames, searchedNames]
  );

  useEffect(() => {
    if (!highlightedFarmLandId) {
      return;
    }
    const lowerCaseHighlightedFarmLandId = highlightedFarmLandId.toLocaleLowerCase();
    const layers = geoJson.getLayers() as Polyline<
      GeometryObject,
      TArcgisMapCropPropsDto
    >[];
    const layer = layers.find(
      (item) =>
        // item.feature?.properties.GDB_ARCHIVE_OID.toString() === highlightedFarmLandId
        item.feature?.properties.farmLandId === lowerCaseHighlightedFarmLandId
    );

    if (layer) {
      dispatch(setBoundsAction(layer.getBounds()));
      dispatch(setHighlightedFarmLandId(null));
    }
  }, [dispatch, map, geoJson, highlightedFarmLandId]);

  useEffect(() => {
    map.fitBounds(bounds);
    map.attributionControl.setPrefix("");
  }, [dispatch, map, bounds]);

  return (
    <>
      <LayerPreloader />
      <GeoJsonWithUpdates
        data={geoJsonObject}
        onEachFeature={onEachFeature}
        filter={filter}
        style={style}
      />
    </>
  );
};
