import { Tab, Tabs } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const CatalogsSubmenu = (): JSX.Element => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location.pathname.split("/")[2] || "techassets");
  const handleChange = useCallback((event, newValue) => setTabValue(newValue), [setTabValue]);

  return (
    <Tabs
      orientation={"vertical"}
      value={tabValue}
      onChange={handleChange}
      aria-label="catalogs submenu"
      // tab styles like menu TODO: create new menu variant and change on it
      className={"is-ecocrop"}
    >
      <Tab component={Link} to={"techassets"} value="techassets" label="Техника" />
      <Tab component={Link} to={"employees"} value="employees" label="Персонал" />
      <Tab component={Link} to={"appusers"} value="appusers" label="Пользователи" />
      <Tab component={Link} to={"chemicals"} value="chemicals" label="СЗР" />
      <Tab component={Link} to={"fertilisers"} value="fertilisers" label="Удобрения" />
      <Tab component={Link} to={"seeds"} value="seeds" label="Семена" />
      <Tab component={Link} to={"crops"} value="crops" label="Культуры" />
      <Tab component={Link} to={"diseases"} value="diseases" label="Болезни" />
      <Tab component={Link} to={"weeds"} value="weeds" label="Сорняки" />
      <Tab component={Link} to={"pests"} value="pests" label="Вредители" />
      <Tab component={Link} to={"phenophases"} value="phenophases" label="Фенофазы" />
    </Tabs>
  );
};
