import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { TechOperationAssetEntityType } from "src/pages/tech-operations/shared/types/tech-operation-asset-entity";
import { RootState } from "src/store-types";
import { Seed } from "src/modules/seeds/shared/models/seed";
import { Chemical } from "src/modules/chemicals/shared/models/chemical";
import { Fertiliser } from "src/modules/fertilisers/shared/models/fertiliser";
import { IAssetStocks, TEditItems } from "../types";

export const getRootState = (state: RootState) => state;

export const getAssetStocks = (
  allStocks: IAssetStocks[] | undefined,
  type: TechOperationAssetEntityType
) =>
  createDraftSafeSelector(getRootState, (state) => {
    const sampleToFilter: Set<TEditItems> = new Set();
    // TODO: Unify below
    switch (type) {
      case TechOperationAssetEntityType.SEED:
        allStocks?.forEach((stock) => {
          state.seeds.list.forEach((seed) => {
            if (stock.assetId.toLowerCase() === seed.id.toLowerCase()) {
              sampleToFilter.add(seed as Seed);
            }
          });
        });
        break;
      case TechOperationAssetEntityType.CHEMICAL:
        allStocks?.forEach((stock) => {
          state.chemicals.list.forEach((chem) => {
            if (stock.assetId.toLowerCase() === chem.id.toLowerCase()) {
              sampleToFilter.add(chem as Chemical);
            }
          });
        });
        break;
      case TechOperationAssetEntityType.FERTILISER:
        allStocks?.forEach((stock) => {
          state.fertilisers.list.forEach((fert) => {
            if (stock.assetId.toLowerCase() === fert.id.toLowerCase()) {
              sampleToFilter.add(fert as Fertiliser);
            }
          });
        });
        break;
      default:
        return [];
    }
    const result: TEditItems[] = Array.from(sampleToFilter);
    return result;
  });
