import { Backdrop, BackdropProps, CircularProgress, styled } from "@mui/material";
import React from "react";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.background.paper,
  position: "absolute",
  overflow: "hidden",
  zIndex: 999,
}));

export const ContainerBackdrop = (props: BackdropProps): JSX.Element => (
  <CustomBackdrop {...props}>
    <CircularProgress color="inherit" />
  </CustomBackdrop>
);
