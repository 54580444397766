import { trueForDev } from "../../../shared/utils/log";
import { WithIdGuid } from "./autocomplete-simple";

export function removeDuplicates<T extends WithIdGuid>(
  entity: string,
  itemsSorted: T[],
  deduplicateField?: (item: T) => string,
  log?: boolean
): T[] {
  const dupeKeys = new Map<string, number>();
  const dupeItems = new Map<T, number>();

  const map = new Map<string, T>();
  for (const item of itemsSorted) {
    const id: string = deduplicateField?.(item) || item.name || item.id;
    if (map.has(id)) {
      if (dupeKeys.has(id)) {
        const encounteredTimes: number = dupeKeys[id];
        dupeKeys.set(id, encounteredTimes + 1);
        dupeItems.set(item, encounteredTimes + 1);
      } else {
        dupeKeys.set(id, 2);
        dupeItems.set(item, 2);
      }
    }
    map.set(id, item); // squashing multiple options to same key
  }

  const dupesRemoved = dupeKeys.size;
  if (dupesRemoved > 0) {
    if (trueForDev(log)) {
      // const dupeKeysAsString = Array.from(dupeKeys.entries())
      //   .map((tuple) => `${tuple[0]}:${tuple[1]}`)
      //   .join(", ");
      // eslint-disable-next-line no-console
      // console.log(
      //   `BACKENDERS_SHOULD_REMOVE_DUPLICATES
      //    entity[${entity}] [${dupesRemoved}]
      //    dupeKey:Count[]=[${dupeKeysAsString}]`,
      //   dupeItems
      // );
    }

    const itemsUnsorted = Array.from(map.values()); // possibly needs sorting once again
    return itemsUnsorted;
  }

  return itemsSorted; // same pointer as passed (if array was sorted => untouched)
}
